import React from 'react';
import { Link } from "react-router-dom";
import ReactFormInputValidation from "react-form-input-validation";
import { TextInput, FormButton, DataTableList, Alert, Anchor } from "../common";
import ResetSupplierPasswordForm from "./ResetSupplierPasswordForm";
import * as constants from '../../constants/constants';

//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  manageSuppliersWatcher, 
  changeSupplierStatusWatcher, 
  deleteSupplierWatcher, 
  resetSupplierPwdWatcher } from '../../redux/actionCreators/suppliersActions';
import { loginAsUserWatcher } from '../../redux/actionCreators/authActions';
import { translationStrings } from '../../utils/locales';
import * as utils from "../../utils/utils";

class ManageSupplier extends React.Component<any, any>{
  dt: any;
  form: any;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          "field": "id",
          "header": translationStrings.common_datatable_sr_no,
          "sortable": true,
          "width": "6%"
        },
        {
          "field": "first_name",
          "header": translationStrings.common_lbl_first_name,
          "sortable": true,
          "width": "20%"
        },
        {
          "field": "last_name",
          "header": translationStrings.common_lbl_last_name,
          "sortable": true,
          "width": "17%"
        },
        {
          "field": "email",
          "header": translationStrings.common_lbl_email,
          "sortable": true,
          "width": "20%"
        },
        {
          "field": "mobile_number",
          "header": translationStrings.common_lbl_contact_number,
          "sortable": true,
          "width": "10%"
        },
        {
          "field": "company_name",
          "header": translationStrings.common_lbl_company_name,
          "sortable": true,
          "width": "15%"
        },
        {
          "field": "actions",
          "header": translationStrings.common_datatable_actions,
          "width": "12%",
          "textAlign": "center"
        }
      ],
      event: {
        "event": {
          "first": 0,
          "rows": constants.ROWS_PER_PAGE,
          "page": 0,
          "pageCount": 1,
          "sortOrder": 1,
          "sortField": null,
          "filters": {},
          "globalFilter": ""
        }
      },
      totalRecords: null,
      filterRecords: null,
      isLoaded: false,
      defaultLoadingMessage: translationStrings.default_loading_msg,
      alertVisible: false,
      alertId: null,
      alertStatus: null,
      filterPlaceholder: translationStrings.datatable_search_placeholder,
      fields: {
        password: "",
        password_confirmation: "",
        attrName: {
          password: "New Password",
          password_confirmation: "Confirm New Password"
        },
        errors: {}
      },
      newPasswordType: 'password',
      confirmPasswordType: 'password',
    };
    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
      password: "required|min:6|max:20",
      password_confirmation: "required|min:6|max:20|matchPassword:password"
    });

    ReactFormInputValidation.register('matchPassword', function (value, requirement, attribute) {

      return value === 'test';
    }, 'The :attribute does not match with confirm password.');

    let formFields: any;
    ReactFormInputValidation.register('matchPassword', function (value, requirement, attribute) {
      return value === formFields[requirement];
    }, translationStrings.common_lbl_password_match_error_msg);

    this.form.handleChangeEvent = (e) => {
      const target: any = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({ fields: { ...this.state.fields, [name]: value } });
      formFields = this.state.fields;
      formFields[name] = value;
    }
    this.form.onformsubmit = (fields) => {
      const userData = {
        password: fields.password,
        confirm_password: fields.password_confirmation
      };
      props.resetSupplierPwdWatcher(this.state.alertId, userData);
      this.setState({ alertVisible: false, alertId: null, alertStatus: null });
      this.setState({
        fields: {
          password: "",
          password_confirmation: "",
          attrName: {
            password: translationStrings.common_lbl_new_password,
            password_confirmation: translationStrings.common_lbl_confirm_new_password
          },
          errors: {}
        }
      });
    }
    this.form.handleNewPasswordIconClick = (e) => {
      e.preventDefault();

      if (this.state.newPasswordType === constants.PASSWORD) {
        this.setState({ newPasswordType: constants.TEXT });
      } else if (this.state.newPasswordType === constants.TEXT) {
        this.setState({ newPasswordType: constants.PASSWORD });
      }
    }
    this.form.handleConfirmPasswordIconClick = (e) => {
      e.preventDefault();

      if (this.state.confirmPasswordType === constants.PASSWORD) {
        this.setState({ confirmPasswordType: constants.TEXT });
      } else if (this.state.confirmPasswordType === constants.TEXT) {
        this.setState({ confirmPasswordType: constants.PASSWORD });
      }
    }
    this.rowClassName = this.rowClassName.bind(this);
  }
  rowClassName(rowData) {
    let isActive = rowData.isActive;
    return { 'disableRow': (!isActive) };
  }

  componentDidMount() {
    document.title = translationStrings.suppliers_list_heading;
    if (this.props.suppliers.data && this.props.suppliers.record) {
      this.loadTable(this.state.event, this.props.suppliers.data.data.input.event);
    } else {
      this.loadTable(this.state.event);
    }
  }

  loadTable(event, hasEvent: any = null) {
    if (hasEvent) {
      event = { "event": hasEvent };
      this.setState({ event: { "event": hasEvent } });
    }

    utils.printVal(event, 'event');

    var response = new Promise((resolve, reject) => {
      this.props.manageSuppliersWatcher(event, resolve, reject);
    }).catch(e => {
      this.setState({ isLoaded: false });
      this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
    });
    response.then(() => {
      if (this.props.suppliers.data.data) {
        let i = 0;
        this.setState({ data: [] });
        this.setState({ totalRecords: this.props.suppliers.data.data.recordsTotal });
        this.setState({ filterRecords: this.props.suppliers.data.data.recordsFiltered });
        this.props.suppliers.data.data.data.forEach((value) => {
          let dataArr = {};
          dataArr['id'] = value.id;
          dataArr['first_name'] = value.first_name;
          dataArr['last_name'] = value.last_name;
          dataArr['email'] = value.email;
          dataArr['mobile_number'] = value.mobile_number;
          dataArr['company_name'] = value.company_name;
          //dataArr['isActive'] = value.active;
          {
            localStorage && localStorage.id != value.id && (
              dataArr['actions'] = <div className="action-text-align">
                {utils.getPermissionByModule('suppliers', 'UPDATE') &&
                  <>
                  <Link to={value.id + '/edit'} 
                  title={translationStrings.btn_title_edit}><i className="fa fa-lg fa-edit" />
                  </Link>&nbsp;&nbsp;
                  </>
                }
                {utils.getPermissionByModule('suppliers', 'DELETE') &&
                <><Anchor class=""
                  title={translationStrings.btn_title_delete}
                  label={<i className="fa fa-lg fa-trash" />}
                  handleClick={(e) => this.handleAlertClick(e, value.id, constants.DELETE)}
                />&nbsp;&nbsp;</>
                }
                {utils.getPermissionByModule('suppliers', 'CHANGE_STATUS') &&
                  <>
                  <Anchor class="" 
                  label={<span style={{ color: value.is_active ? 'red' : 'green' }}><i className="fa fa-lg fa-bookmark" /></span>} 
                  handleClick={(e) => this.handleAlertClick(e, value.id, value.is_active)} 
                  title={value.is_active ? translationStrings.btn_title_deactivate : translationStrings.btn_title_activate} 
                  />&nbsp;&nbsp;
                  </>
                }
                {/*utils.getPermissionByModule('suppliers', 'RESET_PASSWORD') &&
                  <>
                  <Anchor class="" 
                  label={<i className="fa fa-lg fa-lock" />} 
                  handleClick={(e) => this.handleAlertClick(e, value.id, constants.RESET_PASSWORD)} 
                  title={translationStrings.btn_title_reset_pwd} 
                  />&nbsp;&nbsp;
                  </>
                 */}

                {/*<Anchor class="" label={<i className="fa fa-lg fa-sign-in"/>} handleClick={(e) => this.onLoginAs(e, value.id)} title={translationStrings.lbl_login_as_user}/>*/}
              </div>
            )
          }

          this.state.data.push(dataArr);
          i++;
        });
        this.setState({ isLoaded: true });
      } else {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      }
    });
  }


  renderHeader() {
    return (
      <div className="row">
        <div className="col-sm-7"></div>
        <div className="col-sm-5">
          <div className="p-datatable-globalfilter-container text-right">
            <TextInput name="searchFilter" 
            class="form-control" 
            type={translationStrings.btn_title_search} 
            handleChange={this.filterEvent} 
            handleInput={this.filterEvent}
            placeholder={this.state.filterPlaceholder} 
            value={this.state.event.event.globalFilter} />
          </div>
        </div>
      </div>
    );
  }

  pageEvent = (e: any) => {
    this.state.event.event.first = e.first;
    this.state.event.event.rows = e.rows;
    this.state.event.event.page = e.page;
    this.state.event.event.pageCount = e.pageCount;
    this.loadTable(this.state.event);
  };

  sortEvent = (e: any) => {
    this.state.event.event.sortField = e.sortField;
    this.state.event.event.sortOrder = e.sortOrder;
    this.loadTable(this.state.event);
  }

  filterEvent = (e: any) => {
    this.state.event.event.globalFilter = e.target.value
    this.loadTable(this.state.event);
  }

  onLoginAs = (e: any, id: number) => {
    e.preventDefault();
    var response = new Promise((resolve, reject) => {
      this.props.loginAsUserWatcher(id, resolve, reject);
    }).catch(e => {

    });
    response.then(() => {

    });
  }

  handleAlertClick = (e: any, id: number, status: any) => {
    e.preventDefault();
    let currentStatus: any = status;
    if (currentStatus != constants.DELETE && currentStatus != constants.RESET_PASSWORD) {
      currentStatus = currentStatus ? constants.DEACTIVATE : constants.ACTIVATE;
      this.setState({ alertVisible: true, alertId: id, alertStatus: currentStatus });
    } else if (currentStatus == constants.RESET_PASSWORD) {
      this.setState({
        fields: {
          password: "",
          password_confirmation: "",
          attrName: {
            password: translationStrings.common_lbl_new_password,
            password_confirmation: translationStrings.common_lbl_confirm_new_password
          },
          errors: {}
        },
        alertVisible: true,
        alertId: id,
        alertStatus: currentStatus,
        errors: {}
      });

      this.form.errors = {};
    } else {
      this.setState({ alertVisible: true, alertId: id, alertStatus: currentStatus });
    }

  }

  handleAlertConfirmClick = (e: any, id: number, status: any) => {
    e.preventDefault();
    let objId = id;
    let changeStatus: string = null;
    changeStatus = this.state.alertStatus;
    this.setState({ alertVisible: false, alertId: null, alertStatus: null });
    var response = new Promise((resolve, reject) => {
      if (changeStatus == constants.RESET_PASSWORD) {
        //this.form.handleSubmit();
        if (this.form.valid()) {
          this.form.onformsubmit(id, this.state.fields);
        }
      }
      else if (changeStatus == constants.DELETE) {
        this.props.deleteSupplierWatcher(objId, resolve, reject);
      } else {
        this.props.changeSupplierStatusWatcher(objId, changeStatus, resolve, reject);
      }
    }).catch(e => {
      this.setState({ isLoaded: false });
    });
    response.then(() => {
      this.loadTable(this.state.event);
    });
  }

  handleCancelClick = (e) => {
    this.setState({ alertVisible: false, alertId: null, alertStatus: null });
  }

  renderAlertFooter = (name) => {
    return (
      <div>
        <FormButton 
        type="button" 
        class="btn btn-theme" 
        label={this.state.alertStatus && this.state.alertStatus != constants.RESET_PASSWORD ? translationStrings.btn_title_yes : translationStrings.btn_submit} 
        handleClick={(e) => this.handleAlertConfirmClick(e, this.state.alertId, this.state.alertStatus)} 
        />

        <FormButton 
        type="button" 
        class="btn btn-theme p-button-secondary cancelButton" 
        label={this.state.alertStatus && this.state.alertStatus != constants.RESET_PASSWORD ? translationStrings.btn_title_no : translationStrings.btn_cancle} 
        handleClick={() => this.setState({ alertVisible: false, alertId: null, alertStatus: null })} 
        />
      </div>
    );
  }

  addNewSupplier = () => {
    history.push(constants.URLS.GET_SUPPLIER_ADD);
  }

  render() {
    const header = this.renderHeader();
    var status = '';
    var popup_heading = '';
    var confim_msg = '';
    if (this.state.alertStatus && this.state.alertStatus == constants.DELETE) {
      popup_heading = translationStrings.confirmation_popup_heading_delete_user;
      confim_msg = translationStrings.confirmation_msg_for_delete_user;
    } else if (this.state.alertStatus && this.state.alertStatus == constants.DEACTIVATE) {
      popup_heading = translationStrings.confirmation_popup_heading_deactivate_user;
      confim_msg = translationStrings.confirmation_msg_for_deactivate_user;
    } else if (this.state.alertStatus && this.state.alertStatus == constants.ACTIVATE) {
      popup_heading = translationStrings.confirmation_popup_heading_activate_user;
      confim_msg = translationStrings.confirmation_msg_for_activate_user;
    } else if (this.state.alertStatus && this.state.alertStatus == constants.RESET_PASSWORD) {
      popup_heading = translationStrings.confirmation_popup_heading_reset_user_password;
    }

    return (
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
                {utils.getPermissionByModule('suppliers', 'CREATE') &&
                  <FormButton type="button" class="btn btn-theme" label={translationStrings.btn_add} handleClick={this.addNewSupplier} />
                }
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
                {this.state && this.state.isLoaded &&
                  <DataTableList
                    dtRef={(el) => this.dt = el}
                    header={header}
                    value={this.state.data}
                    columns={this.state.columns}
                    sortField={this.state.event.event.sortField}
                    sortOrder={this.state.event.event.sortOrder}
                    first={this.state.event.event.first}
                    rows={this.state.event.event.rows}
                    page={this.state.event.event.page}
                    pageCount={this.state.event.event.pageCount}
                    totalRecords={this.state.totalRecords}
                    filterRecords={this.state.filterRecords}
                    globalFilter={this.state.event.event.globalFilter}
                    pageEvent={this.pageEvent}
                    sortEvent={this.sortEvent}
                    rowClassName={this.rowClassName}
                  />
                }
                {this.state && !this.state.isLoaded &&
                  <div className="text-center">{this.state.defaultLoadingMessage}</div>
                }
              </div>
            </div>
          </section>
        </section>
        <Alert header={popup_heading} 
        visible={this.state.alertVisible} 
        hideHandler={(e) => this.setState({ alertVisible: false, alertId: null, alertStatus: null })}
        content={this.state.alertStatus && this.state.alertStatus != constants.RESET_PASSWORD ?
            confim_msg : this.state.alertStatus && this.state.alertStatus == constants.RESET_PASSWORD ?
        <ResetSupplierPasswordForm {...this.state} 
        formHandler={this.form} 
        handleCancelClick={this.handleCancelClick} 
        handleNewPasswordIconClick={this.form.handleNewPasswordIconClick} 
        handleConfirmPasswordIconClick={this.form.handleConfirmPasswordIconClick} 
        /> : ''
          } 
        handleFooter={this.state.alertStatus && this.state.alertStatus != constants.RESET_PASSWORD ? this.renderAlertFooter('displayBasic') : ''} 
        />
      </main>
    )
  }
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
  user: state.user,
  suppliers: state.suppliers,
  requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    manageSuppliersWatcher,
    changeSupplierStatusWatcher,
    deleteSupplierWatcher,
    resetSupplierPwdWatcher,
    loginAsUserWatcher
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManageSupplier)