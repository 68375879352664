import * as actions from '../types';

const initialState = {
  data: null,
  record: null,
  addData: null,
  error: null,
  cars:null
};

const aboutUs = (state = initialState, action) => {
  switch (action.type) {
    
    case actions.FILL_ABOUT_US:
      return {
        ...state,
        record: { ...action.payload }
      };
  
    case actions.SET_ERRORS:
      return {
        ...state,
        error: action.payload
      };

    case actions.CLEAR_ERRORS:
      return { 
        ...state,
        error: null
      };

    default:
      return state;
  }
};

export default aboutUs;