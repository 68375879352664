import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';

export interface InputTextSchema {
	placeholder?: string;
	name: string;
	type?: string;
	handleChange?: any;
	handleBlur?: any;
	handleInput?: any;
	class?: string;
	value?: string;
	attrName?: string;
	display?: string;
	disabled?: boolean;
	pattern?: any;
	min?:any;
	max?:any;
	readOnly?:any;
	
}

export const TextInput = (props: InputTextSchema) =>  {
  return (
    <InputText style={{"display":props.display ? props.display : ''}} type={props.type} name={props.name} className={props.class} placeholder={props.placeholder} value={props.value} onInput={props.handleInput} onBlur={props.handleBlur} onChange={props.handleChange} data-attribute-name={props.attrName} disabled={props.disabled? props.disabled : false} readOnly={props.readOnly? props.readOnly : false} autoComplete="off" pattern={props.pattern} max={props.max} min={props.min} />
  );
}