import {AxiosError} from "axios";
import _ from "lodash";

// React Notification
import { showNotification, printVal } from "./utils";
import * as constants from "../constants/constants";
import { translationStrings} from './locales';

declare var window: any;

// Handle Server Errors

export const handleServerError = (error : AxiosError) => {
  if(error.response !== undefined){
    switch (error.response.status) {
      case 400:
        Error400(error);
        break;

      case 401:
        Error401();
        break;

      case 403:
        Error403(error);
        break;

      case 404:
        //window.location.href = '/404'
        break;

      case 419:
        Error419();
        break;

      case 422:
        Error422(error);
        break;

      default:
        showNotification('danger', translationStrings.error, translationStrings.some_error_occured);
    }
  } else {
    printVal(error)
    showNotification('danger', translationStrings.error, translationStrings.some_error_occured);
  }
};

// Handle Validation Errors
export const handleCustomError = (error) => {
  printVal(error);
  if(error !== undefined){
    switch (true) {  
      case (error.data.status!=constants.SUCCESS):
        ErrorCustom(error);
        break;
      default:
        showNotification('danger', translationStrings.error, translationStrings.some_error_occured);    
    }
  } else {
    printVal(error)
    showNotification('danger', translationStrings.error, translationStrings.some_error_occured); 
  }
}

export const Error400 = (error: AxiosError) => {
  let $errors = error.response.data ;
  if(_.isString($errors)){
    showNotification('danger', translationStrings.error, $errors);
  } else{
    showNotification('danger', translationStrings.error, $errors.errors, true);
  }
}

export const Error401 = () => {
  localStorage.clear();
  window.location.href = '/login';
  showNotification('danger', translationStrings.error, translationStrings.session_expired);
};

export const Error403 = (error: AxiosError) => {
  let $errors = error.response.data.message;
  if(_.isString($errors)){
    showNotification('danger', translationStrings.error, $errors);
  } else{
    showNotification('danger', translationStrings.error, $errors.errors, true);
  }
};

export const Error404 = () => {
  showNotification('danger', translationStrings.error, translationStrings.page_not_found);
};

export const Error419 = () => {
  showNotification('danger', translationStrings.error, translationStrings.invalid_request);
};

export const Error422 = (error: AxiosError) => {
  let $errors = error.response.data ;
  if(_.isString($errors)){
    showNotification('danger', translationStrings.error, $errors);
  } else{
    if($errors.errors){
      showNotification('danger', translationStrings.error, $errors.errors, true);
    } else if($errors.message){
      showNotification('danger', translationStrings.error, $errors.message, true);
    }
  }

};

export const Error422Custom = ($errors : any) => {
  $.each( $errors.errors, function( key:any, value ) {
    $.each( value, function( key2:number, error:string ) {
      error = error.replace(/document.\d+/g, 'document');
      showNotification('danger', translationStrings.error, error);
    });
  });
};

export const ErrorCustom = (error) => {
  let $errors = error.data.message;
  if(_.isString($errors)){
    showNotification('danger', translationStrings.error, $errors, false);
  } else{
    showNotification('danger', translationStrings.error, $errors.errors, true);
  } 
}