import React, {Component} from 'react';
import {Checkbox} from 'primereact/checkbox';

export interface CheckboxSchema {
	class?: string;
	name: string;
	value: any;
	checked?: boolean;
	handleChange?: any;
	attrName?: string;
	handleClick?:any;
	id?:any;
}

export const InputCheckbox = (props: CheckboxSchema) =>  {
  	return ( 	
		<Checkbox onChange={props.handleChange} checked={props.checked} name={props.name} className={props.class} value={props.value} id={props.id}></Checkbox>
  	);
}