import * as actions from '../types';

const initialState = {
  user: null,
  error: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {

    case actions.FILL_USER_DATA:
      return {
        ...state,
        user: { ...action.payload }
      };
    case actions.FILL_USER_PROFILE:
      return {
        ...state,
        user: { ...action.payload }
      };

    case actions.CLEAR_SESSION:
      return { ...initialState };

    case actions.AUTH_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case actions.CHANGE_USER_PROFILE:
      return {
        ...state,
        user: { ...action.payload }
      };
    default:
      return state;
  }
};

export default auth;