import * as actions from '../types';

// Worker triggering actionCreators
export function manageFeaturesWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_FEATURES_WATCHER, payload: payload, resolve, reject};
}
export function changeFeaturesStatusWatcher(payload, status, resolve, reject) {
  return { type: actions.CHANGE_FEATURES_STATUS_WATCHER, payload: payload, status: status, resolve, reject};
}
export function getFeaturesWatcher(id, resolve, reject) {
  return { type: actions.GET_FEATURES_WATCHER, id: id, resolve, reject};
}
export function getAddFeaturesWatcher(resolve, reject) {
  return { type: actions.GET_ADD_FEATURES_WATCHER, resolve, reject}; 
}
export function addFeaturesWatcher(payload, resolve, reject) {
  return { type: actions.ADD_FEATURES_WATCHER, payload: payload, resolve, reject };   
}
export function updateFeaturesWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_FEATURES_WATCHER, id: id, payload: payload, resolve, reject };    
}
export function deleteFeaturesWatcher(id, resolve, reject) {
  return { type: actions.DELETE_FEATURES_WATCHER, id: id, resolve, reject };  
}

// Redux state changing actionCreators
export function fillFeaturesDataTable(payload) {
  return { type: actions.FILL_FEATURES_DATATABLE, payload: payload };
}
export function fillAddFeatures(payload) {
  return { type: actions.FILL_ADD_FEATURES, payload: payload };
}
export function fillFeatures(payload) {
  return { type: actions.FILL_FEATURES, payload: payload};
}
export function setError(error) {
  return { type: actions.SET_FEATURES_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_FEATURES_ERRORS, payload: null };
}