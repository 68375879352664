import React from 'react';
import { Link } from "react-router-dom";

//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class UnderConstruction extends React.Component<any,any>{
	render(){
        return(
        	<main className="sectionWrapper">
                <section className="container">
                    <div className="sectionHeader">
                        <div className="row">
                        	<div className="underConsrtuctionImageWrapper"></div>
                        </div>
                    </div>
                </section>
            </main>
        )
     }
}
const mapStateToProps = (state: any) => ({
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UnderConstruction)