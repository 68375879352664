import * as React from 'react';
import { TextInput, TextArea, PasswordInput, DropDown, FormButton, Label, Anchor, InputCheckbox } from "../common";
import TextEditor from '../common/form/TextEditor';
import { translationStrings} from '../../utils/locales';
import { convertLabel } from '../../utils/utils';

export default class LatestNewsForm extends React.Component<any, any>{
    render(){
        const {fields, formHandler, handleCancelClick, handleEditorChange, fileError} = this.props;
        return (
          <form onSubmit={formHandler.handleSubmit} encType="multipart/form-data">
              <div className="row">
                <div className="form-group col-sm-6">
                  <img src={fields.news_image ? fields.news_image: fields.default_image} width={100}/>
                  <Label forName="news_image" label={translationStrings.common_lbl_news_image}/>
                  <input type="file" name="news_image" onChange={formHandler.handleChangeEvent} data-attribute-name={fields.attrName.news_image}/>  
                  {fileError &&
                    <label className="error">
                      {fileError}
                    </label>
                  }
                  <Label forName="news_title" label={translationStrings.common_lbl_news_title} require="inline"/>
                  <TextInput class="form-control" name="news_title" type="text" placeholder={translationStrings.common_lbl_news_title} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.news_title} attrName={fields.attrName.news_title}/>
                  <label className="error">
                    {formHandler.errors.news_title ? formHandler.errors.news_title : ""}
                  </label>
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="news_content" label={translationStrings.common_lbl_news_content} require="inline"/>
                  <TextEditor id='news_content' name='news_content' value={fields.news_content} handleChange={(e) => handleEditorChange(e, 'news_content')} attrName={fields.attrName.news_content}/>
                  <label className="error">
                    {formHandler.errors.news_content ? formHandler.errors.news_content : ""}
                  </label>
                </div>
              </div>
              <div className="form-group text-right">
                  <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme"/>
                  <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick}/>
              </div>
          </form>
        )
    }
}