import * as React from 'react';
import { PasswordInput, TextInput, FormButton, Label, Anchor } from "../common";
import { translationStrings} from '../../utils/locales';

export default class ResetSupplierPasswordForm extends React.Component<any, any>{
  render(){
    const {
      fields, 
      formHandler, 
      handleCancelClick, 
      handleNewPasswordIconClick, 
      handleConfirmPasswordIconClick} = this.props;
    return (
      <form className="resetPasswordContainer" onSubmit={formHandler.handleSubmit}>
        <div className="form-group">
          <Label forName="password" label={translationStrings.common_lbl_new_password} require="inline"/>
          <TextInput 
          class="form-control" 
          name="password" 
          type={this.props.newPasswordType} 
          placeholder={translationStrings.common_lbl_new_password} 
          handleChange={formHandler.handleChangeEvent} 
          handleBlur={formHandler.handleBlurEvent} 
          value={fields.password} 
          attrName={fields.attrName.password}
          />
          <Anchor 
          class="eye-icon" 
          label={(this.props.newPasswordType=='password')?<i className="fa fa-eye icon-theme"/>:<i className="fa fa-eye-slash icon-theme"/>} 
          handleClick={(e) => formHandler.handleNewPasswordIconClick(e)} 
          title={(this.props.newPasswordType=='password')?translationStrings.common_lbl_show:translationStrings.common_lbl_hide}
          />
          <label className="error">
            {formHandler.errors.password ? formHandler.errors.password : ""}
          </label>
        </div>
        <div className="form-group">
          <Label forName="password_confirmation" label={translationStrings.common_lbl_confirm_new_password} require="inline"/>
          <TextInput 
          class="form-control" 
          name="password_confirmation" 
          type={this.props.confirmPasswordType} 
          placeholder={translationStrings.common_lbl_confirm_new_password} 
          handleChange={formHandler.handleChangeEvent} 
          handleBlur={formHandler.handleBlurEvent} 
          value={fields.password_confirmation} 
          attrName={fields.attrName.password_confirmation}
          />
          <Anchor 
          class="eye-icon" 
          label={(this.props.confirmPasswordType=='password')?<i className="fa fa-eye icon-theme"/>:<i className="fa fa-eye-slash icon-theme"/>} 
          handleClick={(e) => formHandler.handleConfirmPasswordIconClick(e)} 
          title={(this.props.confirmPasswordType=='password')?translationStrings.common_lbl_show:translationStrings.common_lbl_hide}
          />
          <label className="error">
            {formHandler.errors.password_confirmation ? formHandler.errors.password_confirmation : ""}
          </label>
        </div>
        <div className="form-group text-right">
            <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme"/>
            <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick}/>
        </div>
      </form>
    )
  }
}