import * as actions from '../types';


export function getAboutUsWatcher(id, resolve, reject) {
  return { type: actions.GET_ABOUT_US_WATCHER, id: id, resolve, reject};
}

export function updateAboutUsWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_ABOUT_US_WATCHER, id: id, payload: payload, resolve, reject };    
}

export function fillAboutUs(payload) {
  return { type: actions.FILL_ABOUT_US, payload: payload};
}

export function setError(error) {
  return { type: actions.SET_ABOUT_US_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_ABOUT_US_ERRORS, payload: null };
}