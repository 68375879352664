/* STATUS */
export const SUCCESS = 'SUCCESS';
/* DATATABLE */
export const ROWS_PER_PAGE = 10;
export const ROWS_PER_PAGE_OPTIONS = [10,25,50,100];
export const TAX_PERCENTAGE = 5;

export const EDIT_TYPE='edit';
export const TEXT_ALIGN_CSS='left';
export const EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS';
export const PART_NUMBER_ALREADY_EXISTS = 'PART_NUMBER_ALREADY_EXISTS';
export const SETTING = 'setting';
export const PASSWORD = 'password';
export const TEXT = 'text';

export const ACTIVATE_STATUS = 'ACTIVATE';
export const DEACTIVATE_STATUS = 'DEACTIVATE';
export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';
/* ROLES */
export const ROLES = [
	{'id': 'MASTER_ADMIN', 'label': 'Master Admin'},
	{'id': 'ADMIN_USER', 'label': 'Admin User'}
];
/* USER TYPES */
export const MASTER_ADMIN = 'MASTER_ADMIN';
export const ADMIN_USER = 'ADMIN_USER';

export const ADMIN = 'Admin';
export const SUPPORT = 'Support';

/* USER ACTIONS */
export const ACTIVATE = 'ACTIVATE';
export const DEACTIVATE = 'DEACTIVATE';
export const DELETE = 'DELETE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPLOAD_FILE = 'UPLOAD_FILE';

export const EXPORT = 'EXPORT';

export const HOME_PAGE_URLS = {'MASTER_ADMIN': '/', 'ADMIN_USER': '/autoparts/supplier-stock/list'};

export const URLS = {
	
	'AUTOPARTS_EDIT_PROFILE': '/autoParts/profile',
	'CHANGE_PASSWORD': '/user/change-password',
	'LOGIN': '/login',
	'NOT_AUTHORISED': '/not-authorised',
	'FORGOT_PASSWORD': '/forgot-password',
	'RESET_PASSWORD': '/reset-password/:id/:token',
	'UNDERCONSTRUCTION':'/underConstruction',
	
	'API_URL_FOR_PROFILE': '/profile',
	'API_URL_FOR_LOGOUT':'/logout',
	'API_URL_FOR_FORGOT_PASSWORD':'forgotPwd',
	'API_URL_FOR_CHECK_TOKEN_VALIDTION':'/valResetPwdReqData/',
	'API_URL_FOR_RESET_PASSWORD':'/resetPwd',
	'API_URL_FOR_CHANGE_PASSWORD':'/change-password',
	'API_URL_FOR_LOGIN_AS':'/user/loginAsUser/',
	'API_URL_FOR_AUTOPARTS_COMMON_URL':'autoParts/',
	'STATUS':'/status/',
	'USER_EMAIL':'/user/email/',
	'CHECK':'/check/-1',
	'CHECK_USER':'/check/',
	'ADD':'add',
	'VIEW':'view',
	'USER':'user/',
	'SUPPLIER_LIST': '/autoparts/supplier/list',
	'GET_SUPPLIER_ADD': '/autoparts/supplier/add',
	'SUPPLIER_ADD': '/supplier',
	'SUPPLIER_EDIT': '/autoparts/supplier/:id/edit',
	'CUSTOMER_LIST': '/autoparts/customer/list',
	'GET_CUSTOMER_ADD': '/autoparts/customer/add',
	'CUSTOMER_ADD': '/customer',
	'CUSTOMER_EDIT': '/autoparts/customer/:id/edit',
	'SUPPLIER_STOCK_LIST': '/autoparts/supplier-stock/list',
	'GET_SUPPLIER_STOCK_ADD': '/autoparts/supplier-stock/add',
	'SUPPLIER_STOCK_ADD': '/supplier-stock',
	'SUPPLIER_STOCK_EDIT': '/autoparts/supplier-stock/:id/edit',
	'ORDER_LIST': '/autoparts/order/list',
	'ACCOUNT_DEPOSIT': '/autoparts/account-deposit',
	'GET_ORDER_ADD': '/autoparts/order/add',
	'ORDER_ADD': '/order',
	'ORDER_EDIT': '/autoparts/order/:id/edit',
	'ADMIN_USER_LIST': '/autoparts/adminUser/list',
	'GET_ADMIN_USER_ADD': '/autoparts/adminUser/add',
	'ADMIN_USER_ADD': '/admin-user',
	'ADMIN_USER_EDIT': '/autoparts/adminUser/:id/edit',
	'LATEST_NEWS_LIST': '/autoparts/latestNews/list',
	'GET_LATEST_NEWS_ADD': '/autoparts/latestNews/add',
	'LATEST_NEWS_ADD': '/latest-news',
	'LATEST_NEWS_EDIT': '/autoparts/latestNews/:id/edit',
	'ADMIN_USER_EMAIL':'/email/',
	'PART_NUMBER': '/part-number/',
	'API_URL_FOR_ADMIN_USER_LIST':'admin-user/list',
	'API_URL_FOR_CUSTOMER_LIST': 'customer/list',
	'API_URL_FOR_SUPPLIER_LIST': 'supplier/list',
	'API_URL_FOR_SUPPLIER_STOCK_LIST': 'supplier-stock/list',
	'API_URL_FOR_ORDER_LIST': 'order/list',
	'API_URL_FOR_ADMIN_RESET_PASSWORD_URL': '/change-password/',
	'API_URL_FOR_SUPPLIER_STOCK_SUPPLIER_LIST': '/supplier-stock/create',
	'API_URL_FOR_SUPPLIER_STOCK_UPLOAD_URL': '/import-supplier-stock',
	'API_URL_FOR_CUSTOMER_ADD': 'customer/create',
	'API_URL_FOR_ACCOUNT_DEPOSIT_LIST': 'account-deposit/list',
	'ACCOUNT_DEPOSIT_LIST': '/autoparts/accountDeposit/list',
	'GET_ACCOUNT_DEPOSIT_ADD': '/autoparts/accountDeposit/add',
	'ACCOUNT_DEPOSIT_ADD': '/account-deposit',
	'ACCOUNT_DEPOSIT_EDIT': '/autoparts/accountDeposit/:id/edit',
	'ACCOUNT_STATEMENT': '/autoparts/account-statement/list',
	'API_URL_FOR_ACCOUNT_STATEMENT_LIST': 'account-statement/list',
	'API_URL_FOR_LATEST_NEWS_LIST':'latest-news/list',
	'ABOUT_US_EDIT': '/autoparts/aboutUs/1/edit',
	'ABOUT_US_ADD': '/about-us',
	'TESTIMONIALS_LIST': '/autoparts/testimonials/list',
	'GET_TESTIMONIALS_ADD': '/autoparts/testimonials/add',
	'TESTIMONIALS_ADD': '/testimonials',
	'TESTIMONIALS_EDIT': '/autoparts/testimonials/:id/edit',
	'API_URL_FOR_TESTIMONIALS_LIST':'testimonials/list',
	'FEATURES_LIST': '/autoparts/features/list',
	'GET_FEATURES_ADD': '/autoparts/features/add',
	'FEATURES_ADD': '/features',
	'FEATURES_EDIT': '/autoparts/features/:id/edit',
	'API_URL_FOR_FEATURES_LIST':'features/list',
	'HOME_EDIT': '/autoparts/home/1/edit',
	'HOME_ADD': '/home',
};
