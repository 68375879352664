import React from 'react';
import { Link } from "react-router-dom"
import ReactFormInputValidation from "react-form-input-validation";
import LatestNewsForm from "./LatestNewsForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLatestNewsWatcher, getAddLatestNewsWatcher, addLatestNewsWatcher, updateLatestNewsWatcher } from '../../redux/actionCreators/latestNewsActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings} from '../../utils/locales';

class LatestNews extends React.Component<any,any>{
  form: any;
  constructor(props) {
    super(props);
    this.state = {
        fileError: null,
        fields:{
            id: "",
            news_image: "",
            default_image: "",
            news_title: "",
            news_content: "",
            attrName:{
              news_image: translationStrings.common_lbl_news_image,
              news_title: translationStrings.common_lbl_news_title,
              news_content: translationStrings.common_lbl_news_content
            },
            errors: {}
        },
        isLoaded: false,
        defaultLoadingMessage: translationStrings.default_loading_msg
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
        news_title: "required|max:255",
        news_content: "required"
    });
    this.form.handleChangeEvent = (e) => {
      if(e.target.name=='news_image'){
        const scope = this;
        var reader = new FileReader();
        reader.onload = ()=>{
            scope.setState({ fields: { ...scope.state.fields, ['news_image']: reader.result} });
        }
        reader.readAsDataURL(e.target.files[0]);
      } else {
        this.setState({ fields: { ...this.state.fields, [e.target.name]: e.target.value} });
      }
    }
    this.form.onformsubmit = (fields: any) => {
      const data = {
        news_image: fields.news_image,
        news_title: fields.news_title,
        news_content: fields.news_content
      }
      if(!this.state.fields.id){
          props.addLatestNewsWatcher(data);
      } else {
          props.updateLatestNewsWatcher(this.state.fields.id,data);
      }
    }
  }

  componentDidMount() {
    if(this.props.match.params.id){
      document.title = translationStrings.edit_latest_news_page_title;
      var response = new Promise((resolve, reject) => {
         this.props.getLatestNewsWatcher(this.props.match.params.id, resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        if(this.props.latestNews.record){
          let {data} = this.props.latestNews.record;
          this.state.fields.id = data.id;
          this.state.fields.default_image = checkStringNullValue(data.news_image);
          this.state.fields.news_title = checkStringNullValue(data.news_title);
          this.state.fields.news_content = checkStringNullValue(data.news_content);
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
    } else {
      document.title = translationStrings.add_latest_news_page_title;
      //this.setState({isLoaded: true});
      var response = new Promise((resolve, reject) => {
        this.props.getAddLatestNewsWatcher(resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        this.setState({isLoaded: true});
      });
    }
  }

  handleCancelClick = () => {
    history.goBack();
  }

  handleEditorChange = (e, name) => {
    this.setState({ fields: { ...this.state.fields, [name]: e } });
  }

  render(){
    return(
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
              { this.state && this.state.isLoaded &&
                <LatestNewsForm {...this.state} formHandler={this.form} handleCancelClick={this.handleCancelClick} handleEditorChange={this.handleEditorChange} fileError={this.state.fileError}/>
              }
              { this.state  && !this.state.isLoaded &&
                <div className="text-center">{this.state.defaultLoadingMessage}</div>
              }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  latestNews: state.latestNews,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators({ getLatestNewsWatcher, getAddLatestNewsWatcher, addLatestNewsWatcher, updateLatestNewsWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LatestNews)