import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from "react-router-dom"

import { history } from '../../redux/store';
import ProfileForm from "./ProfileForm";
import * as constants from "../../constants/constants";
//redux stuff
import { connect } from 'react-redux';
//import { loginUser } from "../../redux/actions/userActions"
import { bindActionCreators } from 'redux';
import { getUserProfileWatcher, updateProfileWatcher } from '../../redux/actionCreators/authActions';
import ReactFormInputValidation from "react-form-input-validation";
import { translationStrings} from '../../utils/locales';

class Profile extends React.Component<any,any>{
    form: any;
    constructor(props) {
        super(props);
        this.state = {
            fields:{
                id: "",
                first_name: "",
                last_name: "",
                email: "",
                mobile_number: "",
                company_name: "",
                company_tax_registration_number: "",
                office_phone_number: "",
                address: "",
                attrName:{
                    first_name: translationStrings.common_lbl_first_name,
                    last_name: translationStrings.common_lbl_last_name,
                    email: translationStrings.common_lbl_email,
                    mobile_number: translationStrings.common_lbl_contact_number,
                    company_name: translationStrings.common_lbl_company_name,
                    company_tax_registration_number: translationStrings.common_lbl_company_tax_reg_number,
                    office_phone_number: translationStrings.common_lbl_office_contact_number,
                    address: translationStrings.common_lbl_address
                },
                errors: {}
            },
            isLoaded: false,
            defaultLoadingMessage: translationStrings.default_loading_msg,
            title:null
        };
        this.form = new ReactFormInputValidation(this);
        ReactFormInputValidation.useLang(localStorage.localeI18nCode);
        this.form.useRules({
            first_name: "required|max:255",
            last_name: "required|max:255",
            email: "required|max:255",
            mobile_number: "required|max:20"
        });

        /*ReactFormInputValidation.register('matchPassword', function(value, requirement, attribute){
            
            return value === 'test';
        }, 'The :attribute does not match with confirm password.');*/

        this.form.onformsubmit = (fields) => {
            const userData = {
                first_name: fields.first_name,
                last_name: fields.last_name,
                email: fields.email,
                mobile_number: fields.mobile_number,
                company_name: fields.company_name,
                company_tax_registration_number: fields.company_tax_registration_number,
                office_phone_number: fields.office_phone_number,
                address: fields.address
            };
            props.updateProfileWatcher(this.state.fields.id,userData);
                        
            if(fields.localeId==2){
                this.setState({title: 'Rediger profil'});    
            }else{
                this.setState({title: 'Edit Profile'});    
            }
            

        }
    }

    componentDidMount() {
        document.title = translationStrings.edit_profile_page_title;
        this.setState({title: translationStrings.edit_profile_page_title});
        var response = new Promise((resolve, reject) => {
            this.props.getUserProfileWatcher(resolve,reject);    
        }).catch(e => {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        });
        response.then(() => {
            if(this.props.user.user){
                this.state.fields.id = this.props.user.user.data.id;
                this.state.fields.first_name = this.props.user.user.data.user.admin_dtls.first_name;
                this.state.fields.last_name = this.props.user.user.data.user.admin_dtls.first_name;
                this.state.fields.email = this.props.user.user.data.user.email;
                this.state.fields.mobile_number = this.props.user.user.data.user.admin_dtls.mobile_number;
                this.state.fields.company_name = this.props.user.user.data.user.admin_dtls.company_name;
                this.state.fields.company_tax_registration_number = this.props.user.user.data.user.admin_dtls.company_tax_registration_number;
                this.state.fields.office_phone_number = this.props.user.user.data.user.admin_dtls.office_phone_number;
                this.state.fields.address = this.props.user.user.data.user.admin_dtls.address;
                this.setState({isLoaded: true});
            } else {
                this.setState({isLoaded: false});
                this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
            }
        });
    }

    handleClick = (e: any) => {
        e.preventDefault();
        history.push(constants.URLS.LOGIN);
    }

    render(){
        return(
            <main className="sectionWrapper">
                <section className="container">
                    <div className="sectionHeader">
                        <div className="row">
                          <div className="col-sm-6">
                            <h3>{this.state.title}</h3>
                          </div>
                          <div className="col-sm-6 text-right">
                          </div>
                        </div>
                    </div>
                    <section className="detailsContainer">
                        <div className="row">
                            <div className="col-sm-12">
                            { this.state && this.state.isLoaded &&
                                <ProfileForm {...this.state} formHandler={this.form} handleClick={this.handleClick}/>
                            }
                            { this.state  && !this.state.isLoaded &&
                                <div className="text-center">{this.state.defaultLoadingMessage}</div>
                            }
                            </div>
                        </div>
                    </section>
                </section>
            </main>
        )
    }
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
    user: state.auth,
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators({ getUserProfileWatcher, updateProfileWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Profile)