import * as React from 'react';
import { TextInput, TextArea, PasswordInput, DropDown, FormButton, Label, Anchor, InputCheckbox } from "../common";
import TextEditor from '../common/form/TextEditor';
import { translationStrings} from '../../utils/locales';
import { convertLabel } from '../../utils/utils';

export default class TestimonialsForm extends React.Component<any, any>{
    render(){
        const {fields, formHandler, handleCancelClick, handleEditorChange, fileError} = this.props;
        return (
          <form onSubmit={formHandler.handleSubmit} encType="multipart/form-data">
              <div className="row">
                <div className="form-group col-sm-6">
                  <img src={fields.avatar ? fields.avatar: fields.default_avatar} width={100}/>
                  <Label forName="avatar" label={translationStrings.common_lbl_avatar}/>
                  <input type="file" name="avatar" onChange={formHandler.handleChangeEvent} data-attribute-name={fields.attrName.avatar}/>  
                  {fileError &&
                    <label className="error">
                      {fileError}
                    </label>
                  }
                  <Label forName="posted_by" label={translationStrings.common_lbl_posted_by} require="inline"/>
                  <TextInput class="form-control" name="posted_by" type="text" placeholder={translationStrings.common_lbl_posted_by} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.posted_by} attrName={fields.attrName.posted_by}/>
                  <label className="error">
                    {formHandler.errors.posted_by ? formHandler.errors.posted_by : ""}
                  </label>
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="designation" label={translationStrings.common_lbl_designation} require="inline"/>
                  <TextInput class="form-control" name="designation" type="text" placeholder={translationStrings.common_lbl_designation} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.designation} attrName={fields.attrName.designation}/>
                  <label className="error">
                    {formHandler.errors.designation ? formHandler.errors.designation : ""}
                  </label>
                  <Label forName="testimonial_text" label={translationStrings.common_lbl_testimonial_text} require="inline"/>
                  <TextEditor id='testimonial_text' name='testimonial_text' value={fields.testimonial_text} handleChange={(e) => handleEditorChange(e, 'testimonial_text')} attrName={fields.attrName.testimonial_text}/>
                  <label className="error">
                    {formHandler.errors.testimonial_text ? formHandler.errors.testimonial_text : ""}
                  </label>
                </div>
              </div>
              <div className="form-group text-right">
                  <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme"/>
                  <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick}/>
              </div>
          </form>
        )
    }
}