import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { fillLatestNewsDataTable, fillAddLatestNews, fillLatestNews, setError, clearError } from '../actionCreators/latestNewsActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { URLS } from '../../constants/constants';
import * as constants from '../../constants/constants';
import { translationStrings} from '../../utils/locales';
/**
* Manage LatestNews Operation using saga
*/

// Manage LatestNews API
function manageLatestNewsApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_LATEST_NEWS_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageLatestNewsActionEffect is triggered
export function* manageLatestNewsActionEffect(manageLatestNewsAction) {
  let { payload, resolve, reject } = manageLatestNewsAction;
  try {
    yield put(loadUI());
    let data = yield call(manageLatestNewsApi, payload);
    yield put(fillLatestNewsDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_LATEST_NEWS_USERS_WATCHER action
export function* manageLatestNewsActionWatcher() {
  yield takeLatest(actions.MANAGE_LATEST_NEWS_WATCHER, manageLatestNewsActionEffect);
}

/**
* Change LatestNews Status Operation using saga
*/
// Change LatestNews Status API
function changeLatestNewsStatusApi(id, status) {
  return axios.request({
    method: 'put',
    url: '/change-status/latestNews/'+id
  });
}

// Saga function that handles the side effect when the changeLatestNewsStatusActionEffect is triggered
export function* changeLatestNewsStatusActionEffect(changeLatestNewsStatusAction) {
  let { payload, status, resolve, reject } = changeLatestNewsStatusAction;
  try {
    yield put(loadUI());
    let { data } = yield call(changeLatestNewsStatusApi, payload, status);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to CHANGE_LATEST_NEWS_USER_STATUS_WATCHER action
export function* changeLatestNewsStatusActionWatcher() {
  yield takeLatest(actions.CHANGE_LATEST_NEWS_STATUS_WATCHER, changeLatestNewsStatusActionEffect);
}

/**
* Fetch LatestNews Operation using saga
*/
// Fetch LatestNews Basic API
function fetchLatestNewsApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.LATEST_NEWS_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchLatestNewsActionWatcher is triggered
export function* fetchLatestNewsActionEffect(fetchLatestNewsAction) {
  let { id, resolve, reject } = fetchLatestNewsAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchLatestNewsApi, id);
    yield put(fillLatestNews(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_LATEST_NEWS_WATCHER action
export function* fetchLatestNewsActionWatcher() {
  yield takeLatest(actions.GET_LATEST_NEWS_WATCHER, fetchLatestNewsActionEffect);
}


/**
* Fetch Add LatestNews Operation using saga
*/
// Fetch Add LatestNews API
function fetchAddLatestNewsApi() {
  return axios.request({
    method: 'get',
    url: URLS.GET_LATEST_NEWS_ADD
  });
}

// Saga function that handles the side effect when the fetchAddLatestNewsActionWatcher is triggered
export function* fetchAddLatestNewsActionEffect(fetchAddLatestNewsAction) {
  let { resolve, reject } = fetchAddLatestNewsAction;
  try {
    yield put(loadUI());
    //let data = yield call(fetchAddLatestNewsApi);
    //yield put(fillAddLatestNews(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ADD_LATEST_NEWS_WATCHER action
export function* fetchAddLatestNewsActionWatcher() {
  yield takeLatest(actions.GET_ADD_LATEST_NEWS_WATCHER, fetchAddLatestNewsActionEffect);
}

/**
* Add LatestNews Operation using saga
*/
// Add LatestNews API
function addLatestNewsApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.LATEST_NEWS_ADD,
    data: params
  });
}

// Saga function that handles the side effect when the addLatestNewsActionWatcher is triggered
export function* addLatestNewsActionEffect(addLatestNewsAction) {
  let { payload, resolve, reject } = addLatestNewsAction;
  try {
    yield put(loadUI());
    let { data } = yield call(addLatestNewsApi, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(constants.URLS.LATEST_NEWS_LIST));
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve(data);
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to ADD_LATEST_NEWS_WATCHER action
export function* addLatestNewsActionWatcher() {
  yield takeLatest(actions.ADD_LATEST_NEWS_WATCHER, addLatestNewsActionEffect);
}


/**
* Update LatestNews Operation using saga
*/
// Update LatestNews API
function updateLatestNewsApi(id, params) {
  
  // let updateUrl: any;
  // if(editType=='basic'){
    // updateUrl: URLS.LATEST_NEWS_ADD+'/'+id
  // } else {
  //   updateUrl: URLS.LATEST_NEWS_ADD+'/'+id
  // }
  return axios.request({
    method: 'put',
    url: URLS.LATEST_NEWS_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateLatestNewsActionWatcher is triggered
export function* updateLatestNewsActionEffect(updateLatestNewsAction) {
  let { id, payload, resolve, reject } = updateLatestNewsAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateLatestNewsApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.LATEST_NEWS_LIST));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_LATEST_NEWS_WATCHER action
export function* updateLatestNewsActionWatcher() {
  yield takeLatest(actions.UPDATE_LATEST_NEWS_WATCHER, updateLatestNewsActionEffect);
}


/**
* Delete LatestNews Operation using saga
*/
// Delete LatestNews API
function deleteLatestNewsApi(id) {
  return axios.request({
    method: 'delete',
    url: URLS.LATEST_NEWS_ADD+'/'+id,
  });
}

// Saga function that handles the side effect when the deleteLatestNewsActionWatcher is triggered
export function* deleteLatestNewsActionEffect(deleteLatestNewsAction) {
  let { id, resolve, reject } = deleteLatestNewsAction;
  try {
    yield put(loadUI());
    let { data } = yield call(deleteLatestNewsApi, id);
    yield put(clearUIError());
    yield put(clearError());
    //yield put(push('/latestNews/list'));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to DELETE_LATEST_NEWS_WATCHER action
export function* deleteLatestNewsActionWatcher() {
  yield takeLatest(actions.DELETE_LATEST_NEWS_WATCHER, deleteLatestNewsActionEffect);
}

