import * as React from 'react';
import { TextInput, TextArea, PasswordInput, DropDown, FormButton, Label, Anchor, InputCheckbox } from "../common";
import TextEditor from '../common/form/TextEditor';
import { translationStrings} from '../../utils/locales';
import { convertLabel } from '../../utils/utils';

export default class HomeForm extends React.Component<any, any>{
    render(){
        const {fields, formHandler, handleCancelClick, handleEditorChange} = this.props;
        return (
          <form onSubmit={formHandler.handleSubmit}>
              <div className="row">
                <div className="form-group col-sm-6">
                  <Label forName="header_title" label={translationStrings.common_lbl_header_title} require="inline"/>
                  <TextEditor id='header_title' name='header_title' value={fields.header_title} handleChange={(e) => handleEditorChange(e, 'header_title')} attrName={fields.attrName.header_title}/>
                  <label className="error">
                    {formHandler.errors.header_title ? formHandler.errors.header_title : ""}
                  </label>
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="header_content" label={translationStrings.common_lbl_header_content} require="inline"/>
                  <TextEditor id='header_content' name='header_content' value={fields.header_content} handleChange={(e) => handleEditorChange(e, 'header_content')} attrName={fields.attrName.header_content}/>
                  <label className="error">
                    {formHandler.errors.header_content ? formHandler.errors.header_content : ""}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-6">
                  <Label forName="highlight_text" label={translationStrings.common_lbl_highlight_text} require="inline"/>
                  <TextEditor id='highlight_text' name='highlight_text' value={fields.highlight_text} handleChange={(e) => handleEditorChange(e, 'highlight_text')} attrName={fields.attrName.highlight_text}/>
                  <label className="error">
                    {formHandler.errors.highlight_text ? formHandler.errors.highlight_text : ""}
                  </label>
                </div>
              </div>
              <div className="form-group text-right">
                  <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme"/>
                  <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick}/>
              </div>
          </form>
        )
    }
}