import React from 'react';
import { Link } from "react-router-dom";
import ReactFormInputValidation from "react-form-input-validation";
import { TextInput, FormButton, DataTableList, Alert, Anchor } from "../common";
import * as constants from '../../constants/constants';

//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { manageAccountStatementWatcher } from '../../redux/actionCreators/accountStatementActions';
import { loginAsUserWatcher } from '../../redux/actionCreators/authActions';
import { translationStrings} from '../../utils/locales';
import * as utils from "../../utils/utils";

class AccountStatement extends React.Component<any,any>{
    dt: any;
    form: any;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
              {
                "field": "id",
                "header": translationStrings.common_datatable_sr_no,
                "sortable": true,
                "width": "6%"
              },
              {
                "field": "payment_reference",
                "header": translationStrings.common_lbl_payment_reference,
                "sortable": false,
                "width": "20%"
              },
              {
                "field": "entry_date",
                "header": translationStrings.common_lbl_date,
                "sortable": true,
                "width": "17%"
              },
              {
                "field": "description",
                "header": translationStrings.common_lbl_description,
                "sortable": false,
                "width": "20%"
              },
              {
                "field": "invoice_amount",
                "header": translationStrings.common_lbl_invoice_amount,
                "sortable": false,
                "width": "10%"
              },
              {
                "field": "payment_amount",
                "header": translationStrings.common_lbl_payment_amount,
                "sortable": false,
                "width": "10%"
              },
              {
                "field": "account_balance",
                "header": translationStrings.common_lbl_account_balance,
                "sortable": false,
                "width": "10%"
              }
            ],
            event:{
              "event":{
                "first": 0,
                "rows": constants.ROWS_PER_PAGE,
                "page": 0,
                "pageCount": 1,
                "sortOrder": 1,
                "sortField": null,
                "filters": {},
                "globalFilter":""
              }
            },
            totalRecords: null,
            filterRecords: null,
            isLoaded: false,
            defaultLoadingMessage: translationStrings.default_loading_msg,
            alertVisible: false,
            alertId: null,
            alertStatus: null,
            filterPlaceholder : translationStrings.datatable_search_placeholder
        };
        this.rowClassName = this.rowClassName.bind(this);
    }
    rowClassName(rowData) {
        let isActive = rowData.isActive;
        return {'disableRow' : (!isActive)};
    }

    componentDidMount() {
        document.title = translationStrings.account_statement_list_heading;
        if(this.props.accountStatement.data && this.props.accountStatement.record){
          this.loadTable(this.state.event, this.props.accountStatement.data.data.input.event);
        } else {
          this.loadTable(this.state.event);
        }
    }

    loadTable(event, hasEvent:any = null) {
      if(hasEvent){
        event = {"event":hasEvent};
        this.setState({event: {"event":hasEvent}});
      }

      utils.printVal(event, 'event');

      let search = window.location.search;
      let params = new URLSearchParams(search);
      let searchParam = params.get('id');
      var response = new Promise((resolve, reject) => {
         this.props.manageAccountStatementWatcher(event, searchParam, resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage:translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
          if(this.props.accountStatement.data.data.data.length > 0){
            let i =0;
            let accountBalance = 0;
            this.setState({data:[]});
            this.state.data['accountBalance'] = this.props.accountStatement.data.data.accountBalance;
            this.state.data['customerDtls'] = this.props.accountStatement.data.data.customerDtls;
            this.setState({totalRecords: this.props.accountStatement.data.data.recordsTotal});
            this.setState({filterRecords: this.props.accountStatement.data.data.recordsFiltered});
            let statementData = [];
            this.props.accountStatement.data.data.data.forEach((value) => {
                let dataArr = {};
                if(i <= this.props.accountStatement.data.data.data.length-1 && value.type=='CREDIT' && value.amount > 0){
                    accountBalance += value.amount; 
                } else if(i <= this.props.accountStatement.data.data.data.length-1 && value.type=='DEBIT') {
                    accountBalance -= Math.abs(value.amount);
                }
                dataArr['id'] = i+1;
                if(value.type=='DEBIT'){
                  dataArr['payment_reference'] = 'I-'+ value.debit_reference_id;
                }
                if(value.type=='CREDIT' && value.credit_reference_id){
                  dataArr['payment_reference'] = 'D-' + value.credit_reference_id;
                }
                if(value.type=='CREDIT' && value.credit_note_reference_id){
                   dataArr['payment_reference'] = 'D-' + value.credit_note_reference_id; 
                }
                dataArr['entry_date'] = utils.dateFormatter.format(new Date(value.entry_date));
                dataArr['description'] = value.description;
                if(value.type=='DEBIT'){
                   dataArr['invoice_amount'] = utils.currencyFormatter.format(value.amount);
                   dataArr['payment_amount'] = '-';
                }
                if(value.type=='CREDIT'){
                   dataArr['invoice_amount'] = '-';
                   dataArr['payment_amount'] = utils.currencyFormatter.format(value.amount);
                }
                //dataArr['account_balance'] = utils.currencyFormatter.format(accountBalance);
                dataArr['account_balance'] = utils.currencyFormatter.format(value.account_balance);
                //dataArr['isActive'] = value.active;  
                statementData.push(dataArr);
                i++;
            });
            this.state.data['statementData'] = statementData;
            this.setState({isLoaded: true});
          } else {
            this.setState({isLoaded: false});
            this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
          }
      });
    }

    renderHeader() {
        return (
            <div className="row">
            <div className="col-sm-7"></div>
            <div className="col-sm-5">
                <div className="p-datatable-globalfilter-container text-right">
                    <TextInput name="searchFilter" class="form-control" type={translationStrings.btn_title_search} handleChange={this.filterEvent} handleInput={this.filterEvent}
                    placeholder={this.state.filterPlaceholder} value={this.state.event.event.globalFilter} />
                </div>
            </div>
            </div>
        );
    }

    pageEvent = (e: any) => {
      this.state.event.event.first = e.first;
      this.state.event.event.rows = e.rows;
      this.state.event.event.page = e.page;
      this.state.event.event.pageCount = e.pageCount;
      this.loadTable(this.state.event);
    };

    sortEvent = (e: any) => {
      this.state.event.event.sortField = e.sortField;
      this.state.event.event.sortOrder = e.sortOrder;
      this.loadTable(this.state.event);
    }

    filterEvent = (e:any) => {
      this.state.event.event.globalFilter = e.target.value
      this.loadTable(this.state.event);
    }

    onLoginAs =(e:any,id:number)=>{
      e.preventDefault();     
      var response = new Promise((resolve, reject) => {
          this.props.loginAsUserWatcher(id,resolve,reject);
      }).catch(e => {
        
      });
      response.then(() => {
        
      });
    }

    render(){
        const header = this.renderHeader();

        return(
            <main className="sectionWrapper">
                <section className="container">
                    <div className="sectionHeader">
                        <div className="row">
                          <div className="col-sm-6">
                            <h3>{document.title}</h3>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 text-right">
                            { this.state && this.state.isLoaded &&
                              <>
                              <p><strong>Customer Id:</strong> {this.state.data.customerDtls ? this.state.data.customerDtls.user_id: ''} </p>
                              <p><strong>Customer Name:</strong> {this.state.data.customerDtls ? this.state.data.customerDtls.first_name : ''} {this.state.data.customerDtls ? this.state.data.customerDtls.last_name : ''}</p>
                              <p><strong>Account Balance:</strong> {utils.currencyFormatter.format(this.state.data ? this.state.data.accountBalance: '')}</p>
                              </>
                            }
                          </div>
                        </div>
                    </div>
                    <section className="detailsContainer">
                      <div className="row">
                        <div className="col-sm-12">
                        { this.state && this.state.isLoaded &&
                            <DataTableList 
                            dtRef={(el) => this.dt = el} 
                            header={header} 
                            value={this.state.data.statementData} 
                            columns={this.state.columns}  
                            sortField={this.state.event.event.sortField} 
                            sortOrder={this.state.event.event.sortOrder} 
                            first={this.state.event.event.first}
                            rows={this.state.event.event.rows}
                            page={this.state.event.event.page}
                            pageCount={this.state.event.event.pageCount} 
                            totalRecords={this.state.totalRecords} 
                            filterRecords={this.state.filterRecords}
                            globalFilter={this.state.event.event.globalFilter}
                            pageEvent={this.pageEvent}
                            sortEvent={this.sortEvent}
                            rowClassName={this.rowClassName}
                            />
                        }
                        { this.state  && !this.state.isLoaded &&
                          <div className="text-center">{this.state.defaultLoadingMessage}</div>
                        }
                        </div>
                      </div>
                    </section>
                </section>
            </main>
        )
    }
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
    user: state.user,
    accountStatement: state.accountStatement,
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators({ manageAccountStatementWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AccountStatement)