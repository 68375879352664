import * as React from 'react';
import { TextInput, PasswordInput, FormButton, Label, Anchor } from "../common";

export default class ResetPasswordForm extends React.Component<any, any>{
  render(){
    const {fields, formHandler, handleClick, handleNewPasswordIconClick, handleConfirmPasswordIconClick} = this.props;
    return (
      <form onSubmit={formHandler.handleSubmit}>
          <p>Reset Password</p>
          <div className="form-group">
              <Label forName="password" label="New Password" require="inline"/>
              <TextInput class="form-control" name="password" type={this.props.newPasswordType} placeholder="New Password" handleChange={formHandler.handleChangeEvent} handleBlur={formHandler.handleBlurEvent} value={fields.password} attrName={fields.attrName.password}/>
              <Anchor class="eye-icon"  label={(this.props.newPasswordType=='password')?<i className="fa fa-eye icon-theme"/>:<i className="fa fa-eye-slash icon-theme"/>} handleClick={(e) => formHandler.handleNewPasswordIconClick(e)} title={(this.props.newPasswordType=='password')?'Show':'Hide'}/>    
              <label className="error">
                {formHandler.errors.password ? formHandler.errors.password : ""}
              </label>
          </div>
          <div className="form-group">
              <Label forName="password_confirmation" label="Confirm New Password" require="inline"/>
              <TextInput class="form-control" name="password_confirmation" type={this.props.confirmPasswordType} placeholder="Confirm New Password" handleChange={formHandler.handleChangeEvent} handleBlur={formHandler.handleBlurEvent} value={fields.password_confirmation} attrName={fields.attrName.password_confirmation}/>
              <Anchor class="eye-icon"  label={(this.props.confirmPasswordType=='password')?<i className="fa fa-eye icon-theme"/>:<i className="fa fa-eye-slash icon-theme"/>} handleClick={(e) => formHandler.handleConfirmPasswordIconClick(e)} title={(this.props.confirmPasswordType=='password')?'Show':'Hide'}/>
              <label className="error">
                {formHandler.errors.password_confirmation ? formHandler.errors.password_confirmation : ""}
              </label>
          </div>
          <div className="form-group">
              <FormButton type="submit" label="Submit" class="btn btn-theme full-width-button"/>
          </div>
          <Anchor class="forgetLink" label="Go to Login" handleClick={handleClick}/>
      </form>
    )
  }
}