// UI Handler Watcher Actions
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_UI = 'LOADING_UI';

//  Auth Watcher Actions
export const LOG_IN_WATCHER = 'LOG_IN_WATCHER';
export const LOG_OUT_WATCHER = 'LOG_OUT_WATCHER';
export const FORGET_PWD_WATCHER = 'FORGET_PWD_WATCHER';
export const CHECK_VALID_TOKEN_WATCHER = 'CHECK_VALID_TOKEN_WATCHER';
export const RESET_PWD_WATCHER = 'RESET_PWD_WATCHER';
export const CHANGE_PWD_WATCHER = 'CHANGE_PWD_WATCHER';
export const GET_USER_PROFILE_WATCHER = 'GET_USER_PROFILE_WATCHER';
export const UPDATE_USER_PROFILE_WATCHER = 'UPDATE_USER_PROFILE_WATCHER';
export const LOG_IN_AS_USER_WATCHER = 'LOG_IN_AS_USER_WATCHER';

// Auth Reducer Actions
export const FILL_USER_PROFILE = 'FILL_USER_PROFILE';
export const FILL_USER_DATA = 'FILL_USER_DATA';
export const CHANGE_USER_PROFILE = 'CHANGE_USER_PROFILE';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_SESSION = 'CLEAR_SESSION';

// Manage Admin Users Watcher Actions
export const MANAGE_ADMIN_USERS_WATCHER = 'MANAGE_ADMIN_USERS_WATCHER';
export const CHANGE_ADMIN_USER_STATUS_WATCHER = 'CHANGE_ADMIN_USER_STATUS_WATCHER';
export const GET_ADMIN_USER_WATCHER = 'GET_ADMIN_USER_WATCHER';
export const GET_ADD_ADMIN_USER_WATCHER = 'GET_ADD_ADMIN_USER_WATCHER';
export const ADD_ADMIN_USER_WATCHER = 'ADD_ADMIN_USER_WATCHER';
export const UPDATE_ADMIN_USER_WATCHER = 'UPDATE_ADMIN_USER_WATCHER';
export const DELETE_ADMIN_USER_WATCHER = 'DELETE_ADMIN_USER_WATCHER';
export const RESET_ADMIN_USER_PWD_WATCHER = 'RESET_ADMIN_USER_PWD_WATCHER';

// Admin Users Reducer Actions
export const FILL_ADMIN_USERS_DATATABLE = 'FILL_ADMIN_USERS_DATATABLE';
export const FILL_ADD_ADMIN_USER = 'FILL_ADD_ADMIN_USER';
export const FILL_ADMIN_USER = 'FILL_ADMIN_USER';
export const SET_ADMIN_USERS_ERRORS = 'SET_ADMIN_USERS_ERRORS';
export const CLEAR_ADMIN_USERS_ERRORS = 'CLEAR_ADMIN_USERS_ERRORS';

// Admin Users Duplicate Check Watcher Actions
export const CHECK_ADMIN_USER_EMAIL_WATCHER = 'CHECK_ADMIN_USER_EMAIL_WATCHER';
export const CHECK_ADMIN_USER_EMAIL_IN_EDIT_WATCHER = 'CHECK_ADMIN_USER_EMAIL_IN_EDIT_WATCHER';

// Manage Customers Watcher Actions
export const MANAGE_CUSTOMERS_WATCHER = 'MANAGE_CUSTOMERS_WATCHER';
export const CHANGE_CUSTOMER_STATUS_WATCHER = 'CHANGE_CUSTOMER_STATUS_WATCHER';
export const GET_CUSTOMER_WATCHER = 'GET_CUSTOMER_WATCHER';
export const GET_ADD_CUSTOMER_WATCHER = 'GET_ADD_CUSTOMER_WATCHER';
export const ADD_CUSTOMER_WATCHER = 'ADD_CUSTOMER_WATCHER';
export const UPDATE_CUSTOMER_WATCHER = 'UPDATE_CUSTOMER_WATCHER';
export const DELETE_CUSTOMER_WATCHER = 'DELETE_CUSTOMER_WATCHER';
export const RESET_CUSTOMER_PWD_WATCHER = 'RESET_CUSTOMER_PWD_WATCHER';

// Customer Reducer Actions
export const FILL_CUSTOMERS_DATATABLE = 'FILL_CUSTOMERS_DATATABLE';
export const FILL_ADD_CUSTOMER = 'FILL_ADD_CUSTOMER';
export const FILL_CUSTOMER = 'FILL_CUSTOMER';
export const SET_CUSTOMERS_ERRORS = 'SET_CUSTOMERS_ERRORS';
export const CLEAR_CUSTOMERS_ERRORS = 'CLEAR_CUSTOMERS_ERRORS';

// Customers Duplicate Check Watcher Actions
export const CHECK_CUSTOMER_EMAIL_WATCHER = 'CHECK_CUSTOMER_EMAIL_WATCHER';
export const CHECK_CUSTOMER_EMAIL_IN_EDIT_WATCHER = 'CHECK_CUSTOMER_EMAIL_IN_EDIT_WATCHER';

// Manage Suppliers Watcher Actions
export const MANAGE_SUPPLIERS_WATCHER = 'MANAGE_SUPPLIERS_WATCHER';
export const CHANGE_SUPPLIER_STATUS_WATCHER = 'CHANGE_SUPPLIER_STATUS_WATCHER';
export const GET_SUPPLIER_WATCHER = 'GET_SUPPLIER_WATCHER';
export const GET_ADD_SUPPLIER_WATCHER = 'GET_ADD_SUPPLIER_WATCHER';
export const ADD_SUPPLIER_WATCHER = 'ADD_SUPPLIER_WATCHER';
export const UPDATE_SUPPLIER_WATCHER = 'UPDATE_SUPPLIER_WATCHER';
export const DELETE_SUPPLIER_WATCHER = 'DELETE_SUPPLIER_WATCHER';
export const RESET_SUPPLIER_PWD_WATCHER = 'RESET_SUPPLIER_PWD_WATCHER';

// Suppliers Reducer Actions
export const FILL_SUPPLIERS_DATATABLE = 'FILL_SUPPLIERS_DATATABLE';
export const FILL_ADD_SUPPLIER = 'FILL_ADD_SUPPLIER';
export const FILL_SUPPLIER = 'FILL_SUPPLIER';
export const SET_SUPPLIERS_ERRORS = 'SET_SUPPLIERS_ERRORS';
export const CLEAR_SUPPLIERS_ERRORS = 'CLEAR_SUPPLIERS_ERRORS';

// Suppliers Duplicate Check Watcher Actions
export const CHECK_SUPPLIER_EMAIL_WATCHER = 'CHECK_SUPPLIER_EMAIL_WATCHER';
export const CHECK_SUPPLIER_EMAIL_IN_EDIT_WATCHER = 'CHECK_SUPPLIER_EMAIL_IN_EDIT_WATCHER';

// Manage Suppliers Stock Watcher Actions
export const MANAGE_SUPPLIER_STOCK_WATCHER = 'MANAGE_SUPPLIER_STOCK_WATCHER';
export const CHANGE_SUPPLIER_STOCK_STATUS_WATCHER = 'CHANGE_SUPPLIER_STOCK_STATUS_WATCHER';
export const GET_SUPPLIER_STOCK_WATCHER = 'GET_SUPPLIER_STOCK_WATCHER';
export const GET_ADD_SUPPLIER_STOCK_WATCHER = 'GET_ADD_SUPPLIER_STOCK_WATCHER';
export const ADD_SUPPLIER_STOCK_WATCHER = 'ADD_SUPPLIER_STOCK_WATCHER';
export const UPDATE_SUPPLIER_STOCK_WATCHER = 'UPDATE_SUPPLIER_STOCK_WATCHER';
export const DELETE_SUPPLIER_STOCK_WATCHER = 'DELETE_SUPPLIER_STOCK_WATCHER';
export const UPLOAD_SUPPLIER_STOCK_WATCHER = 'UPLOAD_SUPPLIER_STOCK_WATCHER';

// Supplier Stock Reducer Actions
export const FILL_SUPPLIER_STOCK_DATATABLE = 'FILL_SUPPLIER_STOCK_DATATABLE';
export const FILL_ADD_SUPPLIER_STOCK = 'FILL_ADD_SUPPLIER_STOCK';
export const FILL_SUPPLIER_STOCK = 'FILL_SUPPLIER_STOCK';
export const SET_SUPPLIER_STOCK_ERRORS = 'SET_SUPPLIER_STOCK_ERRORS';
export const CLEAR_SUPPLIER_STOCK_ERRORS = 'CLEAR_SUPPLIER_STOCK_ERRORS';

// Supplier Stock Duplicate Check Watcher Actions
export const CHECK_SUPPLIER_STOCK_PART_NUMBER_WATCHER = 'CHECK_SUPPLIER_STOCK_PART_NUMBER_WATCHER';
export const CHECK_SUPPLIER_STOCK_PART_NUMBER_IN_EDIT_WATCHER = 'CHECK_SUPPLIER_STOCK_PART_NUMBER_IN_EDIT_WATCHER';

// Manage Order Watcher Actions
export const MANAGE_ORDER_WATCHER = 'MANAGE_ORDER_WATCHER';
export const CHANGE_ORDER_STATUS_WATCHER = 'CHANGE_ORDER_STATUS_WATCHER';
export const GET_ORDER_WATCHER = 'GET_ORDER_WATCHER';
export const GET_ADD_ORDER_WATCHER = 'GET_ADD_ORDER_WATCHER';
export const ADD_ORDER_WATCHER = 'ADD_ORDER_WATCHER';
export const UPDATE_ORDER_WATCHER = 'UPDATE_ORDER_WATCHER';
export const DELETE_ORDER_WATCHER = 'DELETE_ORDER_WATCHER';

// Order Reducer Actions
export const FILL_ORDER_DATATABLE = 'FILL_ORDER_DATATABLE';
export const FILL_ADD_ORDER = 'FILL_ADD_ORDER';
export const FILL_ORDER = 'FILL_ORDER';
export const SET_ORDER_ERRORS = 'SET_ORDER_ERRORS';
export const CLEAR_ORDER_ERRORS = 'CLEAR_ORDER_ERRORS';

// Manage Account Deposit Watcher Actions
export const MANAGE_ACCOUNT_DEPOSIT_WATCHER = 'MANAGE_ACCOUNT_DEPOSIT_WATCHER';
export const CHANGE_ACCOUNT_DEPOSIT_STATUS_WATCHER = 'CHANGE_ACCOUNT_DEPOSIT_STATUS_WATCHER';
export const GET_ACCOUNT_DEPOSIT_WATCHER = 'GET_ACCOUNT_DEPOSIT_WATCHER';
export const GET_ADD_ACCOUNT_DEPOSIT_WATCHER = 'GET_ADD_ACCOUNT_DEPOSIT_WATCHER';
export const ADD_ACCOUNT_DEPOSIT_WATCHER = 'ADD_ACCOUNT_DEPOSIT_WATCHER';
export const UPDATE_ACCOUNT_DEPOSIT_WATCHER = 'UPDATE_ACCOUNT_DEPOSIT_WATCHER';
export const DELETE_ACCOUNT_DEPOSIT_WATCHER = 'DELETE_ACCOUNT_DEPOSIT_WATCHER';

// Admin Users Reducer Actions
export const FILL_ACCOUNT_DEPOSIT_DATATABLE = 'FILL_ACCOUNT_DEPOSIT_DATATABLE';
export const FILL_ADD_ACCOUNT_DEPOSIT = 'FILL_ADD_ACCOUNT_DEPOSIT';
export const FILL_ACCOUNT_DEPOSIT = 'FILL_ACCOUNT_DEPOSIT';
export const SET_ACCOUNT_DEPOSIT_ERRORS = 'SET_ACCOUNT_DEPOSIT_ERRORS';
export const CLEAR_ACCOUNT_DEPOSIT_ERRORS = 'CLEAR_ACCOUNT_DEPOSIT_ERRORS';

export const MANAGE_ACCOUNT_STATEMENT_WATCHER = 'MANAGE_ACCOUNT_STATEMENT_WATCHER';
export const FILL_ACCOUNT_STATEMENT_DATATABLE = 'FILL_ACCOUNT_STATEMENT_DATATABLE';

// Manage Latest News Watcher Actions
export const MANAGE_LATEST_NEWS_WATCHER = 'MANAGE_LATEST_NEWS_WATCHER';
export const CHANGE_LATEST_NEWS_STATUS_WATCHER = 'CHANGE_LATEST_NEWS_STATUS_WATCHER';
export const GET_LATEST_NEWS_WATCHER = 'GET_LATEST_NEWS_WATCHER';
export const GET_ADD_LATEST_NEWS_WATCHER = 'GET_ADD_LATEST_NEWS_WATCHER';
export const ADD_LATEST_NEWS_WATCHER = 'ADD_LATEST_NEWS_WATCHER';
export const UPDATE_LATEST_NEWS_WATCHER = 'UPDATE_LATEST_NEWS_WATCHER';
export const DELETE_LATEST_NEWS_WATCHER = 'DELETE_LATEST_NEWS_WATCHER';

// Latest News Reducer Actions
export const FILL_LATEST_NEWS_DATATABLE = 'FILL_LATEST_NEWS_DATATABLE';
export const FILL_ADD_LATEST_NEWS = 'FILL_ADD_LATEST_NEWS';
export const FILL_LATEST_NEWS = 'FILL_LATEST_NEWS';
export const SET_LATEST_NEWS_ERRORS = 'SET_LATEST_NEWS_ERRORS';
export const CLEAR_LATEST_NEWS_ERRORS = 'CLEAR_LATEST_NEWS_ERRORS';

// Manage About Us Watcher Actions
export const GET_ABOUT_US_WATCHER = 'GET_ABOUT_US_WATCHER';
export const UPDATE_ABOUT_US_WATCHER = 'UPDATE_ABOUT_US_WATCHER';

export const FILL_ABOUT_US = 'FILL_ABOUT_US';
export const SET_ABOUT_US_ERRORS = 'SET_ABOUT_US_ERRORS';
export const CLEAR_ABOUT_US_ERRORS = 'CLEAR_ABOUT_US_ERRORS';

// Manage Testimonials Watcher Actions
export const MANAGE_TESTIMONIALS_WATCHER = 'MANAGE_TESTIMONIALS_WATCHER';
export const CHANGE_TESTIMONIALS_STATUS_WATCHER = 'CHANGE_TESTIMONIALS_STATUS_WATCHER';
export const GET_TESTIMONIALS_WATCHER = 'GET_TESTIMONIALS_WATCHER';
export const GET_ADD_TESTIMONIALS_WATCHER = 'GET_ADD_TESTIMONIALS_WATCHER';
export const ADD_TESTIMONIALS_WATCHER = 'ADD_TESTIMONIALS_WATCHER';
export const UPDATE_TESTIMONIALS_WATCHER = 'UPDATE_TESTIMONIALS_WATCHER';
export const DELETE_TESTIMONIALS_WATCHER = 'DELETE_TESTIMONIALS_WATCHER';

// Latest News Reducer Actions
export const FILL_TESTIMONIALS_DATATABLE = 'FILL_TESTIMONIALS_DATATABLE';
export const FILL_ADD_TESTIMONIALS = 'FILL_ADD_TESTIMONIALS';
export const FILL_TESTIMONIALS = 'FILL_TESTIMONIALS';
export const SET_TESTIMONIALS_ERRORS = 'SET_TESTIMONIALS_ERRORS';
export const CLEAR_TESTIMONIALS_ERRORS = 'CLEAR_TESTIMONIALS_ERRORS';

// Manage Features Watcher Actions
export const MANAGE_FEATURES_WATCHER = 'MANAGE_FEATURES_WATCHER';
export const CHANGE_FEATURES_STATUS_WATCHER = 'CHANGE_FEATURES_STATUS_WATCHER';
export const GET_FEATURES_WATCHER = 'GET_FEATURES_WATCHER';
export const GET_ADD_FEATURES_WATCHER = 'GET_ADD_FEATURES_WATCHER';
export const ADD_FEATURES_WATCHER = 'ADD_FEATURES_WATCHER';
export const UPDATE_FEATURES_WATCHER = 'UPDATE_FEATURES_WATCHER';
export const DELETE_FEATURES_WATCHER = 'DELETE_FEATURES_WATCHER';

// Features Actions
export const FILL_FEATURES_DATATABLE = 'FILL_FEATURES_DATATABLE';
export const FILL_ADD_FEATURES = 'FILL_ADD_FEATURES';
export const FILL_FEATURES = 'FILL_FEATURES';
export const SET_FEATURES_ERRORS = 'SET_FEATURES_ERRORS';
export const CLEAR_FEATURES_ERRORS = 'CLEAR_FEATURES_ERRORS';

// Manage Home Watcher Actions
export const GET_HOME_WATCHER = 'GET_HOME_WATCHER';
export const UPDATE_HOME_WATCHER = 'UPDATE_HOME_WATCHER';

export const FILL_HOME = 'FILL_HOME';
export const SET_HOME_ERRORS = 'SET_HOME_ERRORS';
export const CLEAR_HOME_ERRORS = 'CLEAR_HOME_ERRORS';