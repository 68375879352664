import React from 'react';
import { Link } from "react-router-dom"
import ReactFormInputValidation from "react-form-input-validation";
import HomeForm from "./HomeForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHomeWatcher, updateHomeWatcher } from '../../redux/actionCreators/homeActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings} from '../../utils/locales';

class Home extends React.Component<any,any>{
  form: any;
  constructor(props) {
    super(props);
    this.state = {
        fields:{
            id: "",
            header_title: "",
            header_content: "",
            highlight_text: "",
            attrName:{
              header_title: translationStrings.common_lbl_header_title,
              header_content: translationStrings.common_lbl_header_content,
              highlight_text: translationStrings.common_lbl_highlight_text
            },
            errors: {}
        },
        isLoaded: false,
        defaultLoadingMessage: translationStrings.default_loading_msg
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
        header_title: "required|max:255",
        header_content: "required|max:255",
        highlight_text: "required"
    });
    this.form.onformsubmit = (fields: any) => {
        const userData = {
          header_title: fields.header_title,
          header_content: fields.header_content,
          highlight_text: fields.highlight_text
        };
        props.updateHomeWatcher(this.state.fields.id,userData);
    }
  }

  componentDidMount() {
      document.title = translationStrings.edit_home_page_title;
      var response = new Promise((resolve, reject) => {
         this.props.getHomeWatcher(1, resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        if(this.props.home.record){
          let {data} = this.props.home.record;
          this.state.fields.id = data.id;
          this.state.fields.header_title = checkStringNullValue(data.header_title);
          this.state.fields.header_content = checkStringNullValue(data.header_content);
          this.state.fields.highlight_text = checkStringNullValue(data.highlight_text);
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
  }

  handleCancelClick = () => {
    history.goBack();
  }

  handleEditorChange = (e, name) => {
    this.setState({ fields: { ...this.state.fields, [name]: e } });
  }

  render(){
    return(
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
              { this.state && this.state.isLoaded &&
                <HomeForm {...this.state} formHandler={this.form} handleCancelClick={this.handleCancelClick} handleEditorChange={this.handleEditorChange}/>
              }
              { this.state  && !this.state.isLoaded &&
                <div className="text-center">{this.state.defaultLoadingMessage}</div>
              }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  home: state.home,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators({ getHomeWatcher, updateHomeWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Home)