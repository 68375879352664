import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '../../utils/axios';
//import * as alert from '../../utils/alert';
import * as actions from '../types';
import * as constants from '../../constants/constants';
import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { authError, fillUserProfile, clearSession,changeUserProfile,fillUserData } from '../actionCreators/authActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { translationStrings} from '../../utils/locales';
/**
 * Login Operation using saga
 */
// Login API
function loginApi(authParams) {
    return axios.request({
      method: 'post',
      url: constants.URLS.LOGIN,
      data: authParams
    });
}
// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* loginActionEffect(loginAction) {
  let { payload, resolve, reject } = loginAction;

  try {
    yield put(loadUI());
    let { data } = yield call(loginApi, payload);

    Object.keys(data.data).forEach(key => {
      localStorage.setItem(key, data.data[key]);
    });
    
    yield put(clearUIError());
    yield put(fillUserData(data));
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(authError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* loginActionWatcher() {
  yield takeLatest(actions.LOG_IN_WATCHER, loginActionEffect);
}

/**
 * Logout Operation using saga
 */

// Login API
function logoutApi() {
    return axios.request({
      method: 'get',
      url: constants.URLS.API_URL_FOR_LOGOUT 
    });
}

// Saga function that handles the side effect when the logoutActionWatcher is triggered
export function* logoutActionEffect() {
  try {
    yield put(loadUI());
    let { data } = yield call(logoutApi);

    localStorage.clear();
    yield put(clearUIError());
    yield put(clearSession());
    yield put(push(constants.URLS.LOGIN));
  } catch (e) {
    yield put(setUIError(e));
    yield put(authError(e));
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_OUT_WATCHER action
export function* logoutActionWatcher() {
  yield takeLatest(actions.LOG_OUT_WATCHER, logoutActionEffect);
}

/**
 * Forget Password Operation using saga
 */
// Forget Password API
function forgetPwdApi(params) {
    return axios.request({
      method: 'post',
      url: constants.URLS.API_URL_FOR_FORGOT_PASSWORD,
      data: params
    });
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* forgetPwdActionEffect(forgetPwdAction) {
  let { payload, resolve, reject } = forgetPwdAction;

  try {
    yield put(loadUI());
    let { data } = yield call(forgetPwdApi, payload);
    yield put(clearUIError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(authError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* forgetPwdActionWatcher() {
  yield takeLatest(actions.FORGET_PWD_WATCHER, forgetPwdActionEffect);
}

/**
 * Fetch Profile Operation using saga
 */
// Fetch Self API
function checkValidTokenApi(id, token) {
    return axios.request({
      method: 'get',
      url: constants.URLS.API_URL_FOR_CHECK_TOKEN_VALIDTION+id+'/'+token
    });
}
// Saga function that handles the side effect when the fetchSelfActionWatcher is triggered
export function* checkTokenActionEffect(checkTokenAction) {
  let { id, token, resolve, reject } = checkTokenAction;
  try {
    let user = yield call(checkValidTokenApi, id, token);
    if (resolve) resolve();
  } catch (e) {
    yield put(authError(e));
    if (reject) reject();
  }
}
// Saga function that is initiated in the beginning to be able to listen to GET_USER_PROFILE_WATCHER action
export function* checkValidTokenActionWatcher() {
  yield takeLatest(actions.CHECK_VALID_TOKEN_WATCHER, checkTokenActionEffect);
}

/**
 * Reset Password Operation using saga
 */
// Reset Password API
function resetPwdApi(params) {
    return axios.request({
      method: 'post',
      url: constants.URLS.API_URL_FOR_RESET_PASSWORD,
      data: params
    });
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* resetPwdActionEffect(resetPwdAction) {
  let { payload, resolve, reject } = resetPwdAction;

  try {
    yield put(loadUI());
    let { data } = yield call(resetPwdApi, payload);
    yield put(clearUIError());
    yield put(push(constants.URLS.LOGIN));
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(authError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* resetPwdActionWatcher() {
  yield takeLatest(actions.RESET_PWD_WATCHER, resetPwdActionEffect);
}

/**
 * Change Password Operation using saga
 */
// Change Password API
function changePwdApi(params, id) {
    return axios.request({
      method: 'put',
      url: constants.URLS.API_URL_FOR_CHANGE_PASSWORD+'/'+id, 
      data: params
    });
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* changePwdActionEffect(changePwdAction) {
  let { payload, id, resolve, reject } = changePwdAction;

  try {
    yield put(loadUI());
    let { data } = yield call(changePwdApi, payload, id);
    yield put(clearUIError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(authError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* changePwdActionWatcher() {
  yield takeLatest(actions.CHANGE_PWD_WATCHER, changePwdActionEffect);
}

/**
 * Fetch Profile Operation using saga
 */
// Fetch Self API
function fetchProfileApi() {
    return axios.request({
      method: 'get',
      url: constants.URLS.API_URL_FOR_PROFILE 
    });
}
// Saga function that handles the side effect when the fetchSelfActionWatcher is triggered
export function* fetchProfileActionEffect(fetchProfileAction) {
  let { resolve, reject } = fetchProfileAction;
  try {
    yield put(loadUI());
    let user = yield call(fetchProfileApi);
    yield put(fillUserProfile(user));
    yield put(clearUIError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(authError(e));
    if (reject) reject();
  }
}
// Saga function that is initiated in the beginning to be able to listen to GET_USER_PROFILE_WATCHER action
export function* fetchProfileActionWatcher() {
  yield takeLatest(actions.GET_USER_PROFILE_WATCHER, fetchProfileActionEffect);
}

/**
 * Update Profile Operation using saga
 */
// Update Profile API
function updateProfileApi(id, params) {
    return axios.request({
      method: 'put',
      url: constants.URLS.API_URL_FOR_PROFILE,
      data: params
    });
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* updateProfileActionEffect(updateProfileAction) {
  let { id, payload, resolve, reject } = updateProfileAction;

  try {
    yield put(loadUI());
    let { data } = yield call(updateProfileApi, id, payload);
    
    // return false;
    Object.keys(data).forEach(key => {
      if(key!='countries' && key!='locales' && key!='resellers' && key!='message'){
        localStorage.setItem(key, data[key]);  
      }
    });
    yield put(fillUserProfile(data));
    yield put(clearUIError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(authError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* updateProfileActionWatcher() {
  yield takeLatest(actions.UPDATE_USER_PROFILE_WATCHER, updateProfileActionEffect);
}


function loginAsUserApi(id, params) {
    return axios.request({
      method: 'post',
      url: constants.URLS.API_URL_FOR_LOGIN_AS+id, 
      data: params
    });
}
// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* loginAsUserActionEffect(loginAsUserAction) {
  let { id, payload, resolve, reject } = loginAsUserAction;
  try {
    yield put(loadUI());
    let { data } = yield call(loginAsUserApi, id, payload);
    // yield put(changeUserProfile(user));
    var oldSessionData = localStorage;
    localStorage.setItem('oldSessionData', JSON.stringify(oldSessionData));
    Object.keys(data).forEach(key => {
      localStorage.setItem(key, data[key]);
    });
    
    yield put(clearUIError());
    if(localStorage.firstTimeLogin=='true'){
      yield put(push(constants.URLS.CHANGE_PASSWORD));
    } else if(localStorage.role==constants.MASTER_ADMIN) {
      yield put(push(constants.HOME_PAGE_URLS.MASTER_ADMIN));
    } else if(localStorage.role==constants.ADMIN_USER) {
       yield put(push(constants.HOME_PAGE_URLS.ADMIN_USER));
    }

    yield put(clearUIError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(authError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* loginAsUserActionWatcher() {
  yield takeLatest(actions.LOG_IN_AS_USER_WATCHER, loginAsUserActionEffect);
}