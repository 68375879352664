import React from 'react';
import { Link } from "react-router-dom";
import ReactFormInputValidation from "react-form-input-validation";
import { TextInput, FormButton, DataTableList, Alert, Anchor } from "../common";
import * as constants from '../../constants/constants';

//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { manageAccountDepositWatcher, changeAccountDepositStatusWatcher, deleteAccountDepositWatcher } from '../../redux/actionCreators/accountDepositActions';
import { loginAsUserWatcher } from '../../redux/actionCreators/authActions';
import { translationStrings} from '../../utils/locales';
import * as utils from "../../utils/utils";

class ManageAccountDeposit extends React.Component<any,any>{
    dt: any;
    form: any;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
              {
                "field": "id",
                "header": translationStrings.common_datatable_sr_no,
                "sortable": true,
                "width": "6%"
              },
              {
                "field": "first_name",
                "header": translationStrings.common_lbl_name,
                "sortable": true,
                "width": "20%"
              },
              {
                "field": "payment_method",
                "header": translationStrings.common_lbl_payment_method,
                "sortable": true,
                "width": "17%"
              },
              {
                "field": "payment_date",
                "header": translationStrings.common_lbl_payment_date,
                "sortable": true,
                "width": "20%"
              },
              {
                "field": "payment_amount",
                "header": translationStrings.common_lbl_payment_amount,
                "sortable": true,
                "width": "10%"
              },
              {
                "field": "actions",
                "header": translationStrings.common_datatable_actions,
                "width": "12%",
                "textAlign": "center"
              }
            ],
            event:{
              "event":{
                "first": 0,
                "rows": constants.ROWS_PER_PAGE,
                "page": 0,
                "pageCount": 1,
                "sortOrder": 1,
                "sortField": null,
                "filters": {},
                "globalFilter":""
              }
            },
            totalRecords: null,
            filterRecords: null,
            isLoaded: false,
            defaultLoadingMessage: translationStrings.default_loading_msg,
            alertVisible: false,
            alertId: null,
            alertStatus: null,
            filterPlaceholder : translationStrings.datatable_search_placeholder
        };
        this.rowClassName = this.rowClassName.bind(this);
    }
    rowClassName(rowData) {
        let isActive = rowData.isActive;
        return {'disableRow' : (!isActive)};
    }

    componentDidMount() {
        document.title = translationStrings.account_deposit_list_heading;
        if(this.props.accountDeposit.data && this.props.accountDeposit.record){
          this.loadTable(this.state.event, this.props.accountDeposit.data.data.input.event);
        } else {
          this.loadTable(this.state.event);
        }
    }

    loadTable(event, hasEvent:any = null) {
      if(hasEvent){
        event = {"event":hasEvent};
        this.setState({event: {"event":hasEvent}});
      }

      utils.printVal(event, 'event');

      var response = new Promise((resolve, reject) => {
         this.props.manageAccountDepositWatcher(event, resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage:translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
          if(this.props.accountDeposit.data.data){
            let i =0;
            this.setState({data:[]});
            this.setState({totalRecords: this.props.accountDeposit.data.data.recordsTotal});
            this.setState({filterRecords: this.props.accountDeposit.data.data.recordsFiltered});
            this.props.accountDeposit.data.data.data.forEach((value) => {
                let dataArr = {};
                dataArr['id'] = value.id;
                dataArr['first_name'] = value.customer_name ? value.customer_name : value.first_name+ ' ' +value.last_name;
                dataArr['payment_method'] = value.payment_method;
                dataArr['payment_date'] = value.payment_date;
                dataArr['payment_amount'] = utils.currencyFormatter.format(value.payment_amount);
                //dataArr['isActive'] = value.active;

                dataArr['actions'] = <div className="action-text-align">
                  {utils.getPermissionByModule('account_deposit', 'UPDATE') &&
                    <><Link to={value.id+'/edit'} title={translationStrings.btn_title_edit}><i className="fa fa-lg fa-edit"/></Link>&nbsp;&nbsp;</>
                  }
                  {utils.getPermissionByModule('account_deposit', 'DELETE') &&
                    <><Anchor class="" title={translationStrings.btn_title_delete} label={<i className="fa fa-lg fa-trash"/>} handleClick={(e) => this.handleAlertClick(e, value.id, constants.DELETE)}/>&nbsp;&nbsp;</>
                  }
                  {utils.getPermissionByModule('account_deposit', 'CHANGE_STATUS') &&
                    <><Anchor class="" label={<span style={{color: value.is_active ? 'red' : 'green'}}><i className="fa fa-lg fa-bookmark"/></span>} handleClick={(e) => this.handleAlertClick(e, value.id, value.is_active)} title={value.is_active ? translationStrings.btn_title_deactivate : translationStrings.btn_title_activate}/>&nbsp;&nbsp;</>
                  }
                  {/*<Anchor class="" label={<i className="fa fa-lg fa-sign-in"/>} handleClick={(e) => this.onLoginAs(e, value.id)} title={translationStrings.lbl_login_as_user}/>*/}
                  </div>


                this.state.data.push(dataArr);
                i++;
            });
            this.setState({isLoaded: true});
          } else {
            this.setState({isLoaded: false});
            this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
          }
      });
    }

    renderHeader() {
        return (
            <div className="row">
            <div className="col-sm-7"></div>
            <div className="col-sm-5">
                <div className="p-datatable-globalfilter-container text-right">
                    <TextInput name="searchFilter" class="form-control" type={translationStrings.btn_title_search} handleChange={this.filterEvent} handleInput={this.filterEvent}
                    placeholder={this.state.filterPlaceholder} value={this.state.event.event.globalFilter} />
                </div>
            </div>
            </div>
        );
    }

    pageEvent = (e: any) => {
      this.state.event.event.first = e.first;
      this.state.event.event.rows = e.rows;
      this.state.event.event.page = e.page;
      this.state.event.event.pageCount = e.pageCount;
      this.loadTable(this.state.event);
    };

    sortEvent = (e: any) => {
      this.state.event.event.sortField = e.sortField;
      this.state.event.event.sortOrder = e.sortOrder;
      this.loadTable(this.state.event);
    }

    filterEvent = (e:any) => {
      this.state.event.event.globalFilter = e.target.value
      this.loadTable(this.state.event);
    }

    onLoginAs =(e:any,id:number)=>{
      e.preventDefault();
      var response = new Promise((resolve, reject) => {
          this.props.loginAsUserWatcher(id,resolve,reject);
      }).catch(e => {

      });
      response.then(() => {

      });
    }

    handleAlertClick = (e:any, id:number, status:any) => {
      e.preventDefault();
      let currentStatus: any = status;
      if(currentStatus!=constants.DELETE && currentStatus!=constants.RESET_PASSWORD) {
        currentStatus = currentStatus ? constants.DEACTIVATE : constants.ACTIVATE;
        this.setState({alertVisible: true, alertId: id, alertStatus: currentStatus});
      }else if(currentStatus==constants.RESET_PASSWORD){
        this.setState({
            fields: {
              password: "",
              password_confirmation: "",
              attrName:{
                password: translationStrings.common_lbl_new_password,
                password_confirmation: translationStrings.common_lbl_confirm_new_password
              },
              errors: {}
            },
            alertVisible: true,
            alertId: id,
            alertStatus: currentStatus,
            errors: {}
        });

        this.form.errors = {};
      }else{
        this.setState({alertVisible: true, alertId: id, alertStatus: currentStatus});
      }

    }

    handleAlertConfirmClick = (e:any, id:number, status:any) => {
      e.preventDefault();
      let objId = id;
      let changeStatus: string = null;
      changeStatus = this.state.alertStatus;
      this.setState({alertVisible: false, alertId: null, alertStatus: null});
      var response = new Promise((resolve, reject) => {
        if(changeStatus==constants.RESET_PASSWORD){
          //this.form.handleSubmit();
          if(this.form.valid()){
            this.form.onformsubmit(id, this.state.fields);
          }
        }
        else if(changeStatus==constants.DELETE){
          this.props.deleteAccountDepositWatcher(objId,resolve,reject);
        } else {
          this.props.changeAccountDepositStatusWatcher(objId,changeStatus,resolve,reject);
        }
      }).catch(e => {
        this.setState({isLoaded: false});
      });
      response.then(() => {
        this.loadTable(this.state.event);
      });
    }

    handleCancelClick = (e) => {
      this.setState({alertVisible: false, alertId: null, alertStatus: null});
    }

    renderAlertFooter = (name) => {
      return (
          <div>
              <FormButton type="button" class="btn btn-theme" label={this.state.alertStatus && this.state.alertStatus!=constants.RESET_PASSWORD ? translationStrings.btn_title_yes : translationStrings.btn_submit} handleClick={(e) => this.handleAlertConfirmClick(e, this.state.alertId, this.state.alertStatus)} />

              <FormButton type="button" class="btn btn-theme p-button-secondary cancelButton" label={this.state.alertStatus && this.state.alertStatus!=constants.RESET_PASSWORD ? translationStrings.btn_title_no : translationStrings.btn_cancle} handleClick={() => this.setState({alertVisible: false, alertId: null, alertStatus: null})} />
          </div>
      );
    }

    addNewPayment = () => {
      history.push(constants.URLS.GET_ACCOUNT_DEPOSIT_ADD);
    }

    render(){
        const header = this.renderHeader();
        var status = '';
        var popup_heading = '';
        var confim_msg = '';
        if(this.state.alertStatus && this.state.alertStatus==constants.DELETE){
          popup_heading = translationStrings.confirmation_popup_heading_delete_payment;
          confim_msg = translationStrings.confirmation_msg_for_delete_payment;
        }else if(this.state.alertStatus && this.state.alertStatus==constants.DEACTIVATE){
          popup_heading = translationStrings.confirmation_popup_heading_deactivate_payment;
          confim_msg = translationStrings.confirmation_msg_for_deactivate_payment;
        }else if(this.state.alertStatus && this.state.alertStatus==constants.ACTIVATE){
          popup_heading = translationStrings.confirmation_popup_heading_activate_payment;
          confim_msg = translationStrings.confirmation_msg_for_activate_payment;
        }

        return(
            <main className="sectionWrapper">
                <section className="container">
                    <div className="sectionHeader">
                        <div className="row">
                          <div className="col-sm-6">
                            <h3>{document.title}</h3>
                          </div>
                          <div className="col-sm-6 text-right">
                            {utils.getPermissionByModule('account_deposit', 'CREATE') &&
                              <FormButton type="button" class="btn btn-theme" label={translationStrings.btn_add} handleClick={this.addNewPayment} />
                            }
                          </div>
                        </div>
                    </div>
                    <section className="detailsContainer">
                      <div className="row">
                        <div className="col-sm-12">
                        { this.state && this.state.isLoaded &&
                            <DataTableList
                            dtRef={(el) => this.dt = el}
                            header={header}
                            value={this.state.data}
                            columns={this.state.columns}
                            sortField={this.state.event.event.sortField}
                            sortOrder={this.state.event.event.sortOrder}
                            first={this.state.event.event.first}
                            rows={this.state.event.event.rows}
                            page={this.state.event.event.page}
                            pageCount={this.state.event.event.pageCount}
                            totalRecords={this.state.totalRecords}
                            filterRecords={this.state.filterRecords}
                            globalFilter={this.state.event.event.globalFilter}
                            pageEvent={this.pageEvent}
                            sortEvent={this.sortEvent}
                            rowClassName={this.rowClassName}
                            />
                        }
                        { this.state  && !this.state.isLoaded &&
                          <div className="text-center">{this.state.defaultLoadingMessage}</div>
                        }
                        </div>
                      </div>
                    </section>
                </section>
                <Alert header={popup_heading} visible={this.state.alertVisible} hideHandler={(e) => this.setState({alertVisible: false, alertId: null, alertStatus: null})}
                content={this.state.alertStatus && this.state.alertStatus!=constants.RESET_PASSWORD ?
                  confim_msg : ''
                } handleFooter={this.state.alertStatus && this.state.alertStatus!=constants.RESET_PASSWORD  ? this.renderAlertFooter('displayBasic') : ''}/>
            </main>
        )
    }
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
    user: state.user,
    accountDeposit: state.accountDeposit,
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators({ manageAccountDepositWatcher, changeAccountDepositStatusWatcher, deleteAccountDepositWatcher, loginAsUserWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManageAccountDeposit)