import * as actions from '../types';

// Worker triggering actionCreators
export function manageSupplierStockWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_SUPPLIER_STOCK_WATCHER, payload: payload, resolve, reject};
}
export function changeSupplierStockStatusWatcher(payload, status, resolve, reject) {
  return { type: actions.CHANGE_SUPPLIER_STOCK_STATUS_WATCHER, payload: payload, status: status, resolve, reject};
}
export function getSupplierStockWatcher(id, resolve, reject) {
  return { type: actions.GET_SUPPLIER_STOCK_WATCHER, id: id, resolve, reject};
}
export function getAddSupplierStockWatcher(resolve, reject) {
  return { type: actions.GET_ADD_SUPPLIER_STOCK_WATCHER, resolve, reject}; 
}
export function addSupplierStockWatcher(payload, resolve, reject) {
  return { type: actions.ADD_SUPPLIER_STOCK_WATCHER, payload: payload, resolve, reject };   
}
export function updateSupplierStockWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_SUPPLIER_STOCK_WATCHER, id: id, payload: payload, resolve, reject };    
}
export function deleteSupplierStockWatcher(id, resolve, reject) {
  return { type: actions.DELETE_SUPPLIER_STOCK_WATCHER, id: id, resolve, reject };  
}

export function checkSupplierStockPartNumberWatcher(id, supplierId, make, resolve,reject){
  return { type: actions.CHECK_SUPPLIER_STOCK_PART_NUMBER_WATCHER, id: id, supplierId: supplierId, make:make, resolve, reject};
}

export function checkSupplierStockPartNumberInEditWatcher(partNumber, stockId, supplierId, make, resolve,reject){
  return { type: actions.CHECK_SUPPLIER_STOCK_PART_NUMBER_IN_EDIT_WATCHER, partNumber: partNumber, stockId: stockId, supplierId: supplierId, make:make, resolve, reject};
}

export function uploadSupplierStockWatcher(payload, resolve, reject) {
  return { type: actions.UPLOAD_SUPPLIER_STOCK_WATCHER, payload: payload, resolve, reject };
}

// Redux state changing actionCreators
export function fillSupplierStockDataTable(payload) {
  return { type: actions.FILL_SUPPLIER_STOCK_DATATABLE, payload: payload };
}
export function fillAddSupplierStock(payload) {
  return { type: actions.FILL_ADD_SUPPLIER_STOCK, payload: payload };
}
export function fillSupplierStock(payload) {
  return { type: actions.FILL_SUPPLIER_STOCK, payload: payload};
}
export function setError(error) {
  return { type: actions.SET_SUPPLIER_STOCK_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_SUPPLIER_STOCK_ERRORS, payload: null };
}