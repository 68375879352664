import React from "react";
import { connect } from "react-redux";
let Spinner = ({ loading=false }) => (
  loading ?
      <div className="overlay">
          <div className="spinner">
          </div>
      </div>:null
);

const mapStateToProps = (state: any) => ({loading: state.requestUIHandler.loading})
Spinner = connect(mapStateToProps,null)(Spinner)
export default Spinner;
