import React from 'react';
import { Link } from "react-router-dom"
import ReactFormInputValidation from "react-form-input-validation";
import TestimonialsForm from "./TestimonialsForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTestimonialsWatcher, getAddTestimonialsWatcher, addTestimonialsWatcher, updateTestimonialsWatcher } from '../../redux/actionCreators/testimonialsActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings} from '../../utils/locales';

class Testimonials extends React.Component<any,any>{
  form: any;
  constructor(props) {
    super(props);
    this.state = {
        fileError: null,
        fields:{
            id: "",
            posted_by: "",
            designation: "",
            testimonial_text: "",
            avatar: "",
            default_avatar: "",
            attrName:{
              posted_by: translationStrings.common_lbl_posted_by,
              designation: translationStrings.common_lbl_designation,
              testimonial_text: translationStrings.common_lbl_testimonial_text,
              avatar: translationStrings.common_lbl_avatar,
            },
            errors: {}
        },
        isLoaded: false,
        defaultLoadingMessage: translationStrings.default_loading_msg
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
        posted_by: "required|max:255",
        designation: "required|max:255",
        testimonial_text: "required"
    });
    this.form.handleChangeEvent = (e) => {
      if(e.target.name=='avatar'){
        const scope = this;
        var reader = new FileReader();
        reader.onload = ()=>{
            scope.setState({ fields: { ...scope.state.fields, ['avatar']: reader.result} });
        }
        reader.readAsDataURL(e.target.files[0]);
      } else {
        this.setState({ fields: { ...this.state.fields, [e.target.name]: e.target.value} });
      }
    }
    this.form.onformsubmit = (fields: any) => {
      const data = {
        avatar: fields.avatar,
        posted_by: fields.posted_by,
        designation: fields.designation,
        testimonial_text: fields.testimonial_text
      }
      if(!this.state.fields.id){
          props.addTestimonialsWatcher(data);
      } else {
          props.updateTestimonialsWatcher(this.state.fields.id,data);
      }
    }
  }

  componentDidMount() {
    if(this.props.match.params.id){
      document.title = translationStrings.edit_testimonials_page_title;
      var response = new Promise((resolve, reject) => {
         this.props.getTestimonialsWatcher(this.props.match.params.id, resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        if(this.props.testimonials.record){
          let {data} = this.props.testimonials.record;
          this.state.fields.id = data.id;
          this.state.fields.default_avatar = checkStringNullValue(data.avatar);
          this.state.fields.posted_by = checkStringNullValue(data.posted_by);
          this.state.fields.designation = checkStringNullValue(data.designation);
          this.state.fields.testimonial_text = checkStringNullValue(data.testimonial_text);
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
    } else {
      document.title = translationStrings.add_testimonials_page_title;
      //this.setState({isLoaded: true});
      var response = new Promise((resolve, reject) => {
        this.props.getAddTestimonialsWatcher(resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        this.setState({isLoaded: true});
      });
    }
  }

  handleCancelClick = () => {
    history.goBack();
  }

  handleEditorChange = (e, name) => {
    this.setState({ fields: { ...this.state.fields, [name]: e } });
  }

  render(){
    return(
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
              { this.state && this.state.isLoaded &&
                <TestimonialsForm {...this.state} formHandler={this.form} handleCancelClick={this.handleCancelClick} handleEditorChange={this.handleEditorChange} fileError={this.state.fileError}/>
              }
              { this.state  && !this.state.isLoaded &&
                <div className="text-center">{this.state.defaultLoadingMessage}</div>
              }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  testimonials: state.testimonials,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators({ getTestimonialsWatcher, getAddTestimonialsWatcher, addTestimonialsWatcher, updateTestimonialsWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Testimonials)