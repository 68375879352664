import { all } from 'redux-saga/effects';
import {
  loginActionWatcher,
  logoutActionWatcher,
  forgetPwdActionWatcher,
  checkValidTokenActionWatcher,
  resetPwdActionWatcher,
  changePwdActionWatcher,
  fetchProfileActionWatcher,
  updateProfileActionWatcher,
  loginAsUserActionWatcher
} from '../sagas/auth';

import {
  manageAdminUsersActionWatcher,
  changeAdminUserStatusActionWatcher,
  fetchAddAdminUserActionWatcher,
  addAdminUserActionWatcher,
  fetchAdminUserActionWatcher,
  updateAdminUserActionWatcher,
  deleteAdminUserActionWatcher,
  checkAdminUserEmailWatcher,
  checkAdminUserEmailInEditWatcher,
  resetAdminUserPwdActionWatcher
} from '../sagas/adminUsers';

import {
  manageCustomersActionWatcher,
  changeCustomerStatusActionWatcher,
  fetchCustomerActionWatcher,
  fetchAddCustomerActionWatcher,
  addCustomerActionWatcher,
  updateCustomerActionWatcher,
  deleteCustomerActionWatcher,
  checkCustomerEmailWatcher,
  checkCustomerEmailInEditWatcher,
  resetCustomerPwdActionWatcher
} from '../sagas/customers';

import {
  manageSuppliersActionWatcher,
  changeSupplierStatusActionWatcher,
  fetchSupplierActionWatcher,
  fetchAddSupplierActionWatcher,
  addSupplierActionWatcher,
  updateSupplierActionWatcher,
  deleteSupplierActionWatcher,
  checkSupplierEmailWatcher,
  checkSupplierEmailInEditWatcher,
  resetSupplierPwdActionWatcher
} from '../sagas/suppliers'

import {
  manageSupplierStockActionWatcher,
  changeSupplierStockStatusActionWatcher,
  fetchSupplierStockActionWatcher,
  fetchAddSupplierStockActionWatcher,
  addSupplierStockActionWatcher,
  updateSupplierStockActionWatcher,
  deleteSupplierStockActionWatcher,
  checkSupplierStockPartNumberWatcher,
  checkSupplierStockPartNumberInEditWatcher,
  uploadSupplierStockActionWatcher
} from '../sagas/supplierStock'

import {
  manageOrderActionWatcher,
  changeOrderStatusActionWatcher,
  fetchOrderActionWatcher,
  fetchAddOrderActionWatcher,
  addOrderActionWatcher,
  updateOrderActionWatcher,
  deleteOrderActionWatcher
} from '../sagas/order'

import {
  manageAccountDepositActionWatcher,
  changeAccountDepositStatusActionWatcher,
  fetchAddAccountDepositActionWatcher,
  addAccountDepositActionWatcher,
  fetchAccountDepositActionWatcher,
  updateAccountDepositActionWatcher,
  deleteAccountDepositActionWatcher
} from '../sagas/accountDeposit';

import {
  manageAccountStatementActionWatcher
} from '../sagas/accountStatement';

import {
  manageLatestNewsActionWatcher,
  changeLatestNewsStatusActionWatcher,
  fetchAddLatestNewsActionWatcher,
  addLatestNewsActionWatcher,
  fetchLatestNewsActionWatcher,
  updateLatestNewsActionWatcher,
  deleteLatestNewsActionWatcher
} from '../sagas/latestNews';

import {
  fetchAboutUsActionWatcher,
  updateAboutUsActionWatcher
} from '../sagas/aboutUs';

import {
  manageTestimonialsActionWatcher,
  changeTestimonialsStatusActionWatcher,
  fetchAddTestimonialsActionWatcher,
  addTestimonialsActionWatcher,
  fetchTestimonialsActionWatcher,
  updateTestimonialsActionWatcher,
  deleteTestimonialsActionWatcher
} from '../sagas/testimonials';

import {
  manageFeaturesActionWatcher,
  changeFeaturesStatusActionWatcher,
  fetchAddFeaturesActionWatcher,
  addFeaturesActionWatcher,
  fetchFeaturesActionWatcher,
  updateFeaturesActionWatcher,
  deleteFeaturesActionWatcher
} from '../sagas/features';

import {
  fetchHomeActionWatcher,
  updateHomeActionWatcher
} from '../sagas/home';

export default function* rootSaga() {
  yield all([
    loginActionWatcher(),
    loginAsUserActionWatcher(),
    logoutActionWatcher(),
    forgetPwdActionWatcher(),
    checkValidTokenActionWatcher(),
    resetPwdActionWatcher(),
    changePwdActionWatcher(),
    fetchProfileActionWatcher(),
    updateProfileActionWatcher(),
    manageAdminUsersActionWatcher(),
    changeAdminUserStatusActionWatcher(),
    fetchAddAdminUserActionWatcher(),
    addAdminUserActionWatcher(),
    fetchAdminUserActionWatcher(),
    updateAdminUserActionWatcher(),
    deleteAdminUserActionWatcher(),
    checkAdminUserEmailWatcher(),
    checkAdminUserEmailInEditWatcher(),
    resetAdminUserPwdActionWatcher(),
    manageCustomersActionWatcher(),
    changeCustomerStatusActionWatcher(),
    fetchCustomerActionWatcher(),
    fetchAddCustomerActionWatcher(),
    addCustomerActionWatcher(),
    updateCustomerActionWatcher(),
    deleteCustomerActionWatcher(),
    checkCustomerEmailWatcher(),
    checkCustomerEmailInEditWatcher(),
    resetCustomerPwdActionWatcher(),
    manageSuppliersActionWatcher(),
    changeSupplierStatusActionWatcher(),
    fetchSupplierActionWatcher(),
    fetchAddSupplierActionWatcher(),
    addSupplierActionWatcher(),
    updateSupplierActionWatcher(),
    deleteSupplierActionWatcher(),
    checkSupplierEmailWatcher(),
    checkSupplierEmailInEditWatcher(),
    resetSupplierPwdActionWatcher(),
    manageSupplierStockActionWatcher(),
    changeSupplierStockStatusActionWatcher(),
    fetchSupplierStockActionWatcher(),
    fetchAddSupplierStockActionWatcher(),
    addSupplierStockActionWatcher(),
    updateSupplierStockActionWatcher(),
    deleteSupplierStockActionWatcher(),
    checkSupplierStockPartNumberWatcher(),
    checkSupplierStockPartNumberInEditWatcher(),
    uploadSupplierStockActionWatcher(),
    manageOrderActionWatcher(),
    changeOrderStatusActionWatcher(),
    fetchOrderActionWatcher(),
    fetchAddOrderActionWatcher(),
    addOrderActionWatcher(),
    updateOrderActionWatcher(),
    deleteOrderActionWatcher(),
    manageAccountDepositActionWatcher(),
    changeAccountDepositStatusActionWatcher(),
    fetchAddAccountDepositActionWatcher(),
    addAccountDepositActionWatcher(),
    fetchAccountDepositActionWatcher(),
    updateAccountDepositActionWatcher(),
    deleteAccountDepositActionWatcher(),
    manageAccountStatementActionWatcher(),
    manageLatestNewsActionWatcher(),
    changeLatestNewsStatusActionWatcher(),
    fetchAddLatestNewsActionWatcher(),
    addLatestNewsActionWatcher(),
    fetchLatestNewsActionWatcher(),
    updateLatestNewsActionWatcher(),
    deleteLatestNewsActionWatcher(),
    fetchAboutUsActionWatcher(),
    updateAboutUsActionWatcher(),
    manageTestimonialsActionWatcher(),
    changeTestimonialsStatusActionWatcher(),
    fetchAddTestimonialsActionWatcher(),
    addTestimonialsActionWatcher(),
    fetchTestimonialsActionWatcher(),
    updateTestimonialsActionWatcher(),
    deleteTestimonialsActionWatcher(),
    manageFeaturesActionWatcher(),
    changeFeaturesStatusActionWatcher(),
    fetchAddFeaturesActionWatcher(),
    addFeaturesActionWatcher(),
    fetchFeaturesActionWatcher(),
    updateFeaturesActionWatcher(),
    deleteFeaturesActionWatcher(),
    fetchHomeActionWatcher(),
    updateHomeActionWatcher()
  ]);
}