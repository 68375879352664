import React, {Component} from 'react';
import {Dialog} from 'primereact/dialog';

export interface AlertSchema {
	header: any;
	visible: boolean;
	hideHandler: any;
	handleFooter: any;
	contentStyle?: string;
	content: any;
	className?:any;
}

export const Alert = (props: AlertSchema) =>  {
  return (
  	<Dialog header={props.header} visible={props.visible} style={{width: '50vw'}} modal={true} onHide={props.hideHandler} footer={props.handleFooter} className={props.className}>
	    {props.content}
	</Dialog>
  );
}