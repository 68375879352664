import React, {Component} from 'react';

export interface AnchorSchema {
	handleClick?: any;
	class?: string;
	label: any;
	title?: any;
}

export const Anchor = (props: AnchorSchema) =>  {
  return (
  	<a href="#" title={props.title} className={props.class} onClick={props.handleClick}>{props.label}</a>
  );
}