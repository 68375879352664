import React, {Component} from 'react';
import {InputTextarea} from 'primereact/inputtextarea';

export interface InputTextAreaSchema {
	placeholder?: string;
	id?: string;
	name?: string;
	rows?: number;
	cols?: number;
	handleChange?: any;
	handleBlur?: any;
	handleInput?: any;
	class?: string;
	value?: string;
	attrName?: string;
	display?: string;
	disabled?: boolean;
}

export const TextArea = (props: InputTextAreaSchema) =>  {
  return (
    <InputTextarea id={props.id ? props.id : ''} rows={props.rows ? props.rows : 2} cols={props.cols ? props.cols : 3} style={{"display":props.display ? props.display : ''}} name={props.name} className={props.class} placeholder={props.placeholder ? props.placeholder:''} value={props.value? props.value: ''} onInput={props.handleInput} onBlur={props.handleBlur} onChange={props.handleChange} data-attribute-name={props.attrName} disabled={props.disabled? props.disabled : false}/>
  );
}