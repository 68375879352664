import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { fillFeaturesDataTable, fillAddFeatures, fillFeatures, setError, clearError } from '../actionCreators/featuresActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { URLS } from '../../constants/constants';
import * as constants from '../../constants/constants';
import { translationStrings} from '../../utils/locales';
/**
* Manage Features Operation using saga
*/

// Manage Features API
function manageFeaturesApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_FEATURES_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageFeaturesActionEffect is triggered
export function* manageFeaturesActionEffect(manageFeaturesAction) {
  let { payload, resolve, reject } = manageFeaturesAction;
  try {
    yield put(loadUI());
    let data = yield call(manageFeaturesApi, payload);
    yield put(fillFeaturesDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_FEATURES_USERS_WATCHER action
export function* manageFeaturesActionWatcher() {
  yield takeLatest(actions.MANAGE_FEATURES_WATCHER, manageFeaturesActionEffect);
}

/**
* Change Features Status Operation using saga
*/
// Change Features Status API
function changeFeaturesStatusApi(id, status) {
  return axios.request({
    method: 'put',
    url: '/change-status/features/'+id
  });
}

// Saga function that handles the side effect when the changeFeaturesStatusActionEffect is triggered
export function* changeFeaturesStatusActionEffect(changeFeaturesStatusAction) {
  let { payload, status, resolve, reject } = changeFeaturesStatusAction;
  try {
    yield put(loadUI());
    let { data } = yield call(changeFeaturesStatusApi, payload, status);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to CHANGE_FEATURES_USER_STATUS_WATCHER action
export function* changeFeaturesStatusActionWatcher() {
  yield takeLatest(actions.CHANGE_FEATURES_STATUS_WATCHER, changeFeaturesStatusActionEffect);
}

/**
* Fetch Features Operation using saga
*/
// Fetch Features Basic API
function fetchFeaturesApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.FEATURES_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchFeaturesActionWatcher is triggered
export function* fetchFeaturesActionEffect(fetchFeaturesAction) {
  let { id, resolve, reject } = fetchFeaturesAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchFeaturesApi, id);
    yield put(fillFeatures(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_FEATURES_WATCHER action
export function* fetchFeaturesActionWatcher() {
  yield takeLatest(actions.GET_FEATURES_WATCHER, fetchFeaturesActionEffect);
}


/**
* Fetch Add Features Operation using saga
*/
// Fetch Add Features API
function fetchAddFeaturesApi() {
  return axios.request({
    method: 'get',
    url: URLS.GET_FEATURES_ADD
  });
}

// Saga function that handles the side effect when the fetchAddFeaturesActionWatcher is triggered
export function* fetchAddFeaturesActionEffect(fetchAddFeaturesAction) {
  let { resolve, reject } = fetchAddFeaturesAction;
  try {
    yield put(loadUI());
    //let data = yield call(fetchAddFeaturesApi);
    //yield put(fillAddFeatures(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ADD_FEATURES_WATCHER action
export function* fetchAddFeaturesActionWatcher() {
  yield takeLatest(actions.GET_ADD_FEATURES_WATCHER, fetchAddFeaturesActionEffect);
}

/**
* Add Features Operation using saga
*/
// Add Features API
function addFeaturesApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.FEATURES_ADD,
    data: params
  });
}

// Saga function that handles the side effect when the addFeaturesActionWatcher is triggered
export function* addFeaturesActionEffect(addFeaturesAction) {
  let { payload, resolve, reject } = addFeaturesAction;
  try {
    yield put(loadUI());
    let { data } = yield call(addFeaturesApi, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(constants.URLS.FEATURES_LIST));
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve(data);
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to ADD_FEATURES_WATCHER action
export function* addFeaturesActionWatcher() {
  yield takeLatest(actions.ADD_FEATURES_WATCHER, addFeaturesActionEffect);
}


/**
* Update Features Operation using saga
*/
// Update Features API
function updateFeaturesApi(id, params) {
  
  // let updateUrl: any;
  // if(editType=='basic'){
    // updateUrl: URLS.FEATURES_ADD+'/'+id
  // } else {
  //   updateUrl: URLS.FEATURES_ADD+'/'+id
  // }
  return axios.request({
    method: 'put',
    url: URLS.FEATURES_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateFeaturesActionWatcher is triggered
export function* updateFeaturesActionEffect(updateFeaturesAction) {
  let { id, payload, resolve, reject } = updateFeaturesAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateFeaturesApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.FEATURES_LIST));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_FEATURES_WATCHER action
export function* updateFeaturesActionWatcher() {
  yield takeLatest(actions.UPDATE_FEATURES_WATCHER, updateFeaturesActionEffect);
}


/**
* Delete Features Operation using saga
*/
// Delete Features API
function deleteFeaturesApi(id) {
  return axios.request({
    method: 'delete',
    url: URLS.FEATURES_ADD+'/'+id,
  });
}

// Saga function that handles the side effect when the deleteFeaturesActionWatcher is triggered
export function* deleteFeaturesActionEffect(deleteFeaturesAction) {
  let { id, resolve, reject } = deleteFeaturesAction;
  try {
    yield put(loadUI());
    let { data } = yield call(deleteFeaturesApi, id);
    yield put(clearUIError());
    yield put(clearError());
    //yield put(push('/Features/list'));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to DELETE_FEATURES_WATCHER action
export function* deleteFeaturesActionWatcher() {
  yield takeLatest(actions.DELETE_FEATURES_WATCHER, deleteFeaturesActionEffect);
}

