import React from 'react';
import ReactFormInputValidation from "react-form-input-validation";
import SupplierForm from "./SupplierForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  getSupplierWatcher, 
  getAddSupplierWatcher, 
  addSupplierWatcher, 
  updateSupplierWatcher, 
  checkSupplierEmailWatcher, 
  checkSupplierEmailInEditWatcher } from '../../redux/actionCreators/suppliersActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings } from '../../utils/locales';

class Supplier extends React.Component<any, any>{
  form: any;
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        company_name: "",
        company_tax_registration_number: "",
        office_phone_number: "",
        address: "",
        password: "",
        password_confirmation: "",
        default_profit_percentage: "",
        supplier_code_name: "",
        about: "",
        handling_time: "",
        attrName: {
          first_name: translationStrings.common_lbl_first_name,
          last_name: translationStrings.common_lbl_last_name,
          email: translationStrings.common_lbl_email,
          mobile_number: translationStrings.common_lbl_contact_number,
          company_name: translationStrings.common_lbl_company_name,
          company_tax_registration_number: translationStrings.common_lbl_company_tax_reg_number,
          office_phone_number: translationStrings.common_lbl_office_contact_number,
          address: translationStrings.common_lbl_address,
          password: translationStrings.common_lbl_password,
          password_confirmation: translationStrings.common_lbl_confirm_password,
          default_profit_percentage: translationStrings.common_lbl_default_profit_percentage,
          supplier_code_name: translationStrings.common_lbl_supplier_code_name,
          about: translationStrings.common_lbl_about,
          handling_time: translationStrings.common_lbl_handling_time,
        },
        errors: {}
      },
      isLoaded: false,
      defaultLoadingMessage: translationStrings.default_loading_msg,
      newPasswordType: 'password',
      confirmPasswordType: 'password'
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
      first_name: "required|max:255",
      last_name: "required|max:255",
      email: "required|max:255",
      mobile_number: "required|max:20",
      password: "required|min:6|max:20",
      password_confirmation: "required|min:6|max:20",
      supplier_code_name: "required|max:255"
    });
    this.form.onformsubmit = (fields: any) => {
      if (!this.state.fields.id) {
        const userData = {
          first_name: fields.first_name,
          last_name: fields.last_name,
          email: fields.email,
          mobile_number: fields.mobile_number,
          company_name: fields.company_name,
          company_tax_registration_number: fields.company_tax_registration_number,
          office_phone_number: fields.office_phone_number,
          address: fields.address,
          password: fields.password,
          default_profit_percentage: fields.default_profit_percentage,
          supplier_code_name: fields.supplier_code_name,
          about: fields.about,
          handling_time: fields.handling_time
        };
        props.addSupplierWatcher(userData);
      } else {
        const userData = {
          first_name: fields.first_name,
          last_name: fields.last_name,
          email: fields.email,
          mobile_number: fields.mobile_number,
          company_name: fields.company_name,
          company_tax_registration_number: fields.company_tax_registration_number,
          office_phone_number: fields.office_phone_number,
          address: fields.address,
          default_profit_percentage: fields.default_profit_percentage,
          supplier_code_name: fields.supplier_code_name,
          about: fields.about,
          handling_time: fields.handling_time
        };
        props.updateSupplierWatcher(this.state.fields.id, userData);
      }
    }

    this.form.handleNewPasswordIconClick = (e) => {
      e.preventDefault();

      if (this.state.newPasswordType === constants.PASSWORD) {
        this.setState({ newPasswordType: constants.TEXT });
      } else if (this.state.newPasswordType === constants.TEXT) {
        this.setState({ newPasswordType: constants.PASSWORD });
      }
    }
    this.form.handleConfirmPasswordIconClick = (e) => {
      e.preventDefault();

      if (this.state.confirmPasswordType === constants.PASSWORD) {
        this.setState({ confirmPasswordType: constants.TEXT });
      } else if (this.state.confirmPasswordType === constants.TEXT) {
        this.setState({ confirmPasswordType: constants.PASSWORD });
      }
    }
  }

  validateEmail = (e) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!e.target.value) {
      this.setState(prevState => {
        let fields = Object.assign({}, prevState.errors);
        this.state.errors.email = translationStrings.required_email_validation_msg;
        return this.state;
      });
    }
    if (e.target.name == 'email' && !this.props.match.params.id && e.target.value && re.test(String(e.target.value).toLowerCase())) {
      var response = new Promise((resolve, reject) => {
        this.props.checkSupplierEmailWatcher(e.target.value, resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        if (this.props.suppliers.error && this.props.suppliers.error.status == constants.EMAIL_ALREADY_EXISTS) {
          this.setState(prevState => {
            let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
            fields.email = '';        // update the name property, assign a new value                 
            return { fields };        // return new object fields object
          });
        }
      });
    } else if (e.target.name == 'email' && this.props.match.params.id && e.target.value && re.test(String(e.target.value).toLowerCase())) {
      var response = new Promise((resolve, reject) => {
        this.props.checkSupplierEmailInEditWatcher(e.target.value, this.props.match.params.id, resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        if (this.props.suppliers.error && this.props.suppliers.error.status == constants.EMAIL_ALREADY_EXISTS) {
          this.setState(prevState => {
            let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
            fields.email = '';        // update the name property, assign a new value                 
            return { fields };        // return new object fields object
          });
        }
      });
    }

  }

  componentDidMount() {
    if (this.props.match.params.id) {
      document.title = translationStrings.edit_user_page_title;
      var response = new Promise((resolve, reject) => {
        this.props.getSupplierWatcher(this.props.match.params.id, resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        if (this.props.suppliers.record) {
          let { data } = this.props.suppliers.record;
          this.state.fields.id = data.id;
          this.state.fields.first_name = checkStringNullValue(data.supplier_dtls.first_name);
          this.state.fields.last_name = checkStringNullValue(data.supplier_dtls.last_name);
          this.state.fields.email = checkStringNullValue(data.email);
          this.state.fields.mobile_number = checkStringNullValue(data.supplier_dtls.mobile_number);
          this.state.fields.company_name = checkStringNullValue(data.supplier_dtls.company_name);
          this.state.fields.company_tax_registration_number = checkStringNullValue(data.supplier_dtls.company_tax_registration_number);
          this.state.fields.office_phone_number = checkStringNullValue(data.supplier_dtls.office_phone_number);
          this.state.fields.address = checkStringNullValue(data.supplier_dtls.address);
          this.state.fields.default_profit_percentage = checkStringNullValue(data.supplier_dtls.default_profit_percentage);
          this.state.fields.supplier_code_name = checkStringNullValue(data.supplier_dtls.supplier_code_name);
          this.state.fields.about = checkStringNullValue(data.supplier_dtls.about);
          this.state.fields.handling_time = checkStringNullValue(data.supplier_dtls.handling_time);
          this.setState({ isLoaded: true });
        } else {
          this.setState({ isLoaded: false });
          this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
        }
      });
    } else {
      document.title = translationStrings.add_supplier_page_title;
      //this.setState({isLoaded: true});
      var response = new Promise((resolve, reject) => {
        this.props.getAddSupplierWatcher(resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        this.setState({ isLoaded: true });
      });
    }
  }

  handleCancelClick = () => {
    history.goBack();
  }

  render() {
    return (
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
                {this.state && this.state.isLoaded &&
                  <SupplierForm {...this.state}
                    formHandler={this.form}
                    handleCancelClick={this.handleCancelClick}
                    validateEmail={this.validateEmail}
                    handleNewPasswordIconClick={this.form.handleNewPasswordIconClick}
                    handleConfirmPasswordIconClick={this.form.handleConfirmPasswordIconClick}
                  />
                }
                {this.state && !this.state.isLoaded &&
                  <div className="text-center">{this.state.defaultLoadingMessage}</div>
                }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  suppliers: state.suppliers,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getSupplierWatcher,
    getAddSupplierWatcher,
    addSupplierWatcher,
    updateSupplierWatcher,
    checkSupplierEmailWatcher,
    checkSupplierEmailInEditWatcher
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Supplier)