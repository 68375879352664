import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { fillAboutUs, setError, clearError } from '../actionCreators/aboutUsActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { URLS } from '../../constants/constants';
import { translationStrings} from '../../utils/locales';

/**
* Fetch AboutUs Operation using saga
*/
// Fetch AboutUs Basic API
function fetchAboutUsApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.ABOUT_US_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchAboutUsActionWatcher is triggered
export function* fetchAboutUsActionEffect(fetchAboutUsAction) {
  let { id, resolve, reject } = fetchAboutUsAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchAboutUsApi, id);
    yield put(fillAboutUs(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ABOUT_US_WATCHER action
export function* fetchAboutUsActionWatcher() {
  yield takeLatest(actions.GET_ABOUT_US_WATCHER, fetchAboutUsActionEffect);
}

/**
* Update AboutUs Operation using saga
*/
// Update AboutUs API
function updateAboutUsApi(id, params) {
  
  // let updateUrl: any;
  // if(editType=='basic'){
    // updateUrl: URLS.LATEST_NEWS_ADD+'/'+id
  // } else {
  //   updateUrl: URLS.LATEST_NEWS_ADD+'/'+id
  // }
  return axios.request({
    method: 'put',
    url: URLS.ABOUT_US_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateAboutUsActionWatcher is triggered
export function* updateAboutUsActionEffect(updateAboutUsAction) {
  let { id, payload, resolve, reject } = updateAboutUsAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateAboutUsApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_ABOUT_US_WATCHER action
export function* updateAboutUsActionWatcher() {
  yield takeLatest(actions.UPDATE_ABOUT_US_WATCHER, updateAboutUsActionEffect);
}