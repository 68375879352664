import * as actions from '../types';

// Worker triggering actionCreators
export function manageAccountDepositWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_ACCOUNT_DEPOSIT_WATCHER, payload: payload, resolve, reject};
}
export function changeAccountDepositStatusWatcher(payload, status, resolve, reject) {
  return { type: actions.CHANGE_ACCOUNT_DEPOSIT_STATUS_WATCHER, payload: payload, status: status, resolve, reject};
}
export function getAccountDepositWatcher(id, resolve, reject) {
  return { type: actions.GET_ACCOUNT_DEPOSIT_WATCHER, id: id, resolve, reject};
}
export function getAddAccountDepositWatcher(resolve, reject) {
  return { type: actions.GET_ADD_ACCOUNT_DEPOSIT_WATCHER, resolve, reject}; 
}
export function addAccountDepositWatcher(payload, resolve, reject) {
  return { type: actions.ADD_ACCOUNT_DEPOSIT_WATCHER, payload: payload, resolve, reject };   
}
export function updateAccountDepositWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_ACCOUNT_DEPOSIT_WATCHER, id: id, payload: payload, resolve, reject };    
}
export function deleteAccountDepositWatcher(id, resolve, reject) {
  return { type: actions.DELETE_ACCOUNT_DEPOSIT_WATCHER, id: id, resolve, reject };  
}

// Redux state changing actionCreators
export function fillAccountDepositDataTable(payload) {
  return { type: actions.FILL_ACCOUNT_DEPOSIT_DATATABLE, payload: payload };
}
export function fillAddAccountDeposit(payload) {
  return { type: actions.FILL_ADD_ACCOUNT_DEPOSIT, payload: payload };
}
export function fillAccountDeposit(payload) {
  return { type: actions.FILL_ACCOUNT_DEPOSIT, payload: payload};
}
export function setError(error) {
  return { type: actions.SET_ACCOUNT_DEPOSIT_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_ACCOUNT_DEPOSIT_ERRORS, payload: null };
}