import * as actions from '../types';

// Worker triggering actionCreators
export function manageCustomersWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_CUSTOMERS_WATCHER, payload: payload, resolve, reject};
}
export function changeCustomerStatusWatcher(payload, status, resolve, reject) {
  return { type: actions.CHANGE_CUSTOMER_STATUS_WATCHER, payload: payload, status: status, resolve, reject};
}
export function getCustomerWatcher(id, resolve, reject) {
  return { type: actions.GET_CUSTOMER_WATCHER, id: id, resolve, reject};
}
export function getAddCustomerWatcher(resolve, reject) {
  return { type: actions.GET_ADD_CUSTOMER_WATCHER, resolve, reject}; 
}
export function addCustomerWatcher(payload, resolve, reject) {
  return { type: actions.ADD_CUSTOMER_WATCHER, payload: payload, resolve, reject };   
}
export function updateCustomerWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_CUSTOMER_WATCHER, id: id, payload: payload, resolve, reject };    
}
export function deleteCustomerWatcher(id, resolve, reject) {
  return { type: actions.DELETE_CUSTOMER_WATCHER, id: id, resolve, reject };  
}

export function checkCustomerEmailWatcher(id, resolve,reject){
  return { type: actions.CHECK_CUSTOMER_EMAIL_WATCHER, id: id, resolve, reject};
}

export function checkCustomerEmailInEditWatcher(email, userId, resolve,reject){
  return { type: actions.CHECK_CUSTOMER_EMAIL_IN_EDIT_WATCHER, email: email, userId: userId,  resolve, reject};
}

export function resetCustomerPwdWatcher(id, payload, resolve, reject) {
  return { type: actions.RESET_CUSTOMER_PWD_WATCHER, id: id, payload: payload, resolve, reject };
}

// Redux state changing actionCreators
export function fillCustomersDataTable(payload) {
  return { type: actions.FILL_CUSTOMERS_DATATABLE, payload: payload };
}
export function fillAddCustomer(payload) {
  return { type: actions.FILL_ADD_CUSTOMER, payload: payload };
}
export function fillCustomer(payload) {
  return { type: actions.FILL_CUSTOMER, payload: payload};
}
export function setError(error) {
  return { type: actions.SET_CUSTOMERS_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_CUSTOMERS_ERRORS, payload: null };
}