import React from "react";
//import { createBrowserHistory } from 'history';
import { Switch } from "react-router-dom";
import { history } from '../redux/store';
import { ConnectedRouter } from 'connected-react-router';
import { getPermissionByModule } from "../utils/utils";
import * as constants from '../constants/constants';
import PublicRoute from "./PublicRouter";
import PrivateRoute from "./PrivateRouter";
import LogIn from "../components/auth/LogIn";
import NotAuthorised from "../components/notAuthorised/NotAuthorised";
import ChangePassword from "../components/auth/ChangePassword";
import Profile from "../components/profile/Profile";
import Dashboard from "../components/dashboard/Dashboard";
import ForgetPassword from "../components/auth/ForgetPassword";
import ResetPassword from "../components/auth/ResetPassword";
import UnderConstruction from "../components/underConstruction/UnderConstruction";

import ManageAdminUsers from "../components/adminUser/ManageAdminUsers";
import AdminUser from "../components/adminUser/AdminUser";
import ManageCustomers from "../components/customers/ManageCustomers";
import ManageSupplier from "../components/suppliers/ManageSupplier";
import Customer from "../components/customers/Customer";
import Supplier from "../components/suppliers/Supplier";
import ManageSupplierStock from "../components/supplierStock/ManageSupplierStock";
import SupplierStock from "../components/supplierStock/SupplierStock";
import ManageOrder from "../components/order/ManageOrder";
import Order from "../components/order/Order";
import ManageAccountDeposit from "../components/accountDeposit/ManageAccountDeposit";
import AccountDeposit from "../components/accountDeposit/AccountDeposit";
import AccountStatement from "../components/accountStatement/AccountStatement";
import ManageLatestNews from "../components/latestNews/ManageLatestNews";
import LatestNews from "../components/latestNews/LatestNews";
import AboutUs from "../components/aboutUs/AboutUs";
import ManageTestimonials from "../components/testimonials/ManageTestimonials";
import Testimonials from "../components/testimonials/Testimonials";
import ManageFeatures from "../components/features/ManageFeatures";
import Features from "../components/features/Features";
import Home from "../components/home/Home";

//export const history = createBrowserHistory();
// alert(window.location.pathname);

if (!localStorage.role && (window.location.pathname != constants.URLS.LOGIN && window.location.pathname.search("reset-password") == -1 && window.location.pathname != constants.URLS.FORGOT_PASSWORD && window.location.pathname != '/')) {
    var currentUrl = window.location.href;
    var paramsInUrl = currentUrl.split('?');
    var flag = true;
    for (var i in paramsInUrl) {
        if (!paramsInUrl[i].includes('returnUrl=')) {
            continue;
        }
        else {
            flag = false;
            break;
        }
    }
    if (flag) {
        currentUrl = constants.URLS.LOGIN + '?returnUrl=' + btoa(window.location.pathname);
        window.location.href = currentUrl;

    }
}

export default () => (
    <ConnectedRouter history={history}>
        <Switch>
            <PublicRoute path={constants.URLS.LOGIN} component={LogIn} exact validFor={true} />
            <PublicRoute path={constants.URLS.FORGOT_PASSWORD} component={ForgetPassword} exact />
            <PublicRoute path={constants.URLS.RESET_PASSWORD} component={ResetPassword} exact validFor={true} />
            <PrivateRoute path={constants.URLS.NOT_AUTHORISED} component={NotAuthorised} exact validFor={true} />
            <PrivateRoute path="/" component={Dashboard} exact validFor={true} />
            <PrivateRoute path={constants.URLS.CHANGE_PASSWORD} component={ChangePassword} exact validFor={true} />
            <PrivateRoute path={constants.URLS.AUTOPARTS_EDIT_PROFILE} component={Profile} exact validFor={true} />
            <PrivateRoute path={constants.URLS.UNDERCONSTRUCTION} component={UnderConstruction} exact validFor={true} />
            <PrivateRoute path={constants.URLS.ADMIN_USER_LIST} component={ManageAdminUsers} exact moduleName='admin_users' permissionType='READ' />
            <PrivateRoute path={constants.URLS.GET_ADMIN_USER_ADD} component={AdminUser} exact moduleName='admin_users' permissionType='CREATE' />
            <PrivateRoute path={constants.URLS.ADMIN_USER_EDIT} component={AdminUser} exact moduleName='admin_users' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.CUSTOMER_LIST} component={ManageCustomers} exact moduleName='customers' permissionType='READ' />
            <PrivateRoute path={constants.URLS.CUSTOMER_EDIT} component={Customer} exact moduleName='customers' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.GET_CUSTOMER_ADD} component={Customer} exact moduleName='customers' permissionType='CREATE' />
            <PrivateRoute path={constants.URLS.SUPPLIER_LIST} component={ManageSupplier} exact moduleName='suppliers' permissionType='READ' />
            <PrivateRoute path={constants.URLS.SUPPLIER_EDIT} component={Supplier} exact moduleName='suppliers' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.GET_SUPPLIER_ADD} component={Supplier} exact moduleName='suppliers' permissionType='CREATE' />
            <PrivateRoute path={constants.URLS.SUPPLIER_STOCK_LIST} component={ManageSupplierStock} exact moduleName='supplier_stock' permissionType='READ' />
            <PrivateRoute path={constants.URLS.SUPPLIER_STOCK_EDIT} component={SupplierStock} exact moduleName='supplier_stock' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.GET_SUPPLIER_STOCK_ADD} component={SupplierStock} exact moduleName='supplier_stock' permissionType='CREATE' />
            <PrivateRoute path={constants.URLS.ORDER_LIST} component={ManageOrder} exact moduleName='orders' permissionType='READ' />
            <PrivateRoute path={constants.URLS.ORDER_EDIT} component={Order} exact moduleName='orders' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.GET_ORDER_ADD} component={Order} exact moduleName='orders' permissionType='CREATE' />
            <PrivateRoute path={constants.URLS.ACCOUNT_DEPOSIT_LIST} component={ManageAccountDeposit} exact moduleName='account_deposit' permissionType='READ' />
            <PrivateRoute path={constants.URLS.GET_ACCOUNT_DEPOSIT_ADD} component={AccountDeposit} exact moduleName='account_deposit' permissionType='CREATE' />
            <PrivateRoute path={constants.URLS.ACCOUNT_DEPOSIT_EDIT} component={AccountDeposit} exact moduleName='account_deposit' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.ACCOUNT_STATEMENT} component={AccountStatement} exact moduleName='customers' permissionType='ACCOUNT_STATEMENT' />
            <PrivateRoute path={constants.URLS.LATEST_NEWS_LIST} component={ManageLatestNews} exact moduleName='latest_news' permissionType='READ' />
            <PrivateRoute path={constants.URLS.GET_LATEST_NEWS_ADD} component={LatestNews} exact moduleName='latest_news' permissionType='CREATE' />
            <PrivateRoute path={constants.URLS.LATEST_NEWS_EDIT} component={LatestNews} exact moduleName='latest_news' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.ABOUT_US_EDIT} component={AboutUs} exact moduleName='about_us' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.TESTIMONIALS_LIST} component={ManageTestimonials} exact moduleName='testimonials' permissionType='READ' />
            <PrivateRoute path={constants.URLS.GET_TESTIMONIALS_ADD} component={Testimonials} exact moduleName='testimonials' permissionType='CREATE' />
            <PrivateRoute path={constants.URLS.TESTIMONIALS_EDIT} component={Testimonials} exact moduleName='testimonials' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.FEATURES_LIST} component={ManageFeatures} exact moduleName='features' permissionType='READ' />
            <PrivateRoute path={constants.URLS.GET_FEATURES_ADD} component={Features} exact moduleName='features' permissionType='CREATE' />
            <PrivateRoute path={constants.URLS.FEATURES_EDIT} component={Features} exact moduleName='features' permissionType='UPDATE' />
            <PrivateRoute path={constants.URLS.HOME_EDIT} component={Home} exact moduleName='home' permissionType='UPDATE' />
        </Switch>
    </ConnectedRouter>
);