import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { fillCustomersDataTable, fillAddCustomer, fillCustomer, setError, clearError } from '../actionCreators/customersActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { translationStrings} from '../../utils/locales';
import { URLS } from '../../constants/constants';

/**
* Manage Customer Operation using saga
*/

// Manage Customers API
function manageCustomersApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_CUSTOMER_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageCustomersActionEffect is triggered
export function* manageCustomersActionEffect(manageCustomersAction) {
  let { payload, resolve, reject } = manageCustomersAction;
  try {
    yield put(loadUI());
    let data = yield call(manageCustomersApi, payload);
    yield put(fillCustomersDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_CUSTOMERS_WATCHER action
export function* manageCustomersActionWatcher() {
  yield takeLatest(actions.MANAGE_CUSTOMERS_WATCHER, manageCustomersActionEffect);
}

/**
* Change Customer Status Operation using saga
*/
// Change Customer Status API
function changeCustomerStatusApi(id, status) {
  return axios.request({
    method: 'put',
    url: '/change-status/customer/'+id
  });
}

// Saga function that handles the side effect when the changeCustomerStatusActionEffect is triggered
export function* changeCustomerStatusActionEffect(changeCustomerStatusAction) {
  let { payload, status, resolve, reject } = changeCustomerStatusAction;
  try {
    yield put(loadUI());
    let { data } = yield call(changeCustomerStatusApi, payload, status);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to CHANGE_CUSTOMER_STATUS_WATCHER action
export function* changeCustomerStatusActionWatcher() {
  yield takeLatest(actions.CHANGE_CUSTOMER_STATUS_WATCHER, changeCustomerStatusActionEffect);
}

/**
* Fetch Customer Operation using saga
*/
// Fetch Customer Basic API
function fetchCustomerApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.CUSTOMER_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchCustomerActionWatcher is triggered
export function* fetchCustomerActionEffect(fetchCustomerAction) {
  let { id, resolve, reject } = fetchCustomerAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchCustomerApi, id);
    yield put(fillCustomer(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_CUSTOMER_WATCHER action
export function* fetchCustomerActionWatcher() {
  yield takeLatest(actions.GET_CUSTOMER_WATCHER, fetchCustomerActionEffect);
}


/**
* Fetch Add Customer Operation using saga
*/
// Fetch Add Customer API
function fetchAddCustomerApi() {
  return axios.request({
    method: 'get',
    url: URLS.API_URL_FOR_CUSTOMER_ADD
  });
}

// Saga function that handles the side effect when the fetchAddCustomerActionWatcher is triggered
export function* fetchAddCustomerActionEffect(fetchAddCustomerAction) {
  let { resolve, reject } = fetchAddCustomerAction;
  try {
    yield put(loadUI());
    let data = yield call(fetchAddCustomerApi);
    yield put(fillAddCustomer(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ADD_CUSTOMER_WATCHER action
export function* fetchAddCustomerActionWatcher() {
  yield takeLatest(actions.GET_ADD_CUSTOMER_WATCHER, fetchAddCustomerActionEffect);
}

/**
* Add Customer Operation using saga
*/
// Add Customer API
function addCustomerApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.CUSTOMER_ADD,
    data: params
  });
}

// Saga function that handles the side effect when the addCustomerActionWatcher is triggered
export function* addCustomerActionEffect(addCustomerAction) {
  let { payload, resolve, reject } = addCustomerAction;
  try {
    yield put(loadUI());
    let { data } = yield call(addCustomerApi, payload);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve(data);
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to ADD_CUSTOMER_WATCHER action
export function* addCustomerActionWatcher() {
  yield takeLatest(actions.ADD_CUSTOMER_WATCHER, addCustomerActionEffect);
}


/**
* Update Customer Operation using saga
*/
// Update Customer API
function updateCustomerApi(id, params) {
  
  // let updateUrl: any;
  // if(editType=='basic'){
    // updateUrl: URLS.Admin_USER_ADD+'/'+id
  // } else {
  //   updateUrl: URLS.Admin_USER_ADD+'/'+id
  // }
  return axios.request({
    method: 'put',
    url: URLS.CUSTOMER_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateCustomerActionWatcher is triggered
export function* updateCustomerActionEffect(updateCustomerAction) {
  let { id, payload, resolve, reject } = updateCustomerAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateCustomerApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.CUSTOMER_LIST));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_CUSTOMER_WATCHER action
export function* updateCustomerActionWatcher() {
  yield takeLatest(actions.UPDATE_CUSTOMER_WATCHER, updateCustomerActionEffect);
}


/**
* Delete Customer Operation using saga
*/
// Delete Customer API
function deleteCustomerApi(id) {
  return axios.request({
    method: 'delete',
    url: URLS.CUSTOMER_ADD+'/'+id,
  });
}

// Saga function that handles the side effect when the deleteCustomerActionWatcher is triggered
export function* deleteCustomerActionEffect(deleteCustomerAction) {
  let { id, resolve, reject } = deleteCustomerAction;
  try {
    yield put(loadUI());
    let { data } = yield call(deleteCustomerApi, id);
    yield put(clearUIError());
    yield put(clearError());
    //yield put(push('/Customer/list'));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to DELETE_CUSTOMER_WATCHER action
export function* deleteCustomerActionWatcher() {
  yield takeLatest(actions.DELETE_CUSTOMER_WATCHER, deleteCustomerActionEffect);
}

/**
* Check duplicate Customers users email for Operation using saga
*/
function checkCustomersDuplicateEmailApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.ADMIN_USER_EMAIL+id+URLS.CHECK,
  });
}

export function* checkCustomersDuplicateEmailActionEffect(CustomersEmail) {
  let { id, resolve, reject } = CustomersEmail;
  try {
    yield put(loadUI());
    let { data } = yield call(checkCustomersDuplicateEmailApi, id);
    yield put(clearUIError());
    
    // yield put(push('customer/user/list'));
    // showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    
    if(e.status == 'EMAIL_ALREADY_EXISTS'){
      if (reject) resolve();  
    }else{
      if (reject) reject(e);
    }
  }
}

export function* checkCustomerEmailWatcher() {
  yield takeLatest(actions.CHECK_CUSTOMER_EMAIL_WATCHER, checkCustomersDuplicateEmailActionEffect);
}

/**
* Check duplicate Customers users email for Edit Operation using saga
*/
function checkCustomersDuplicateEmailInEditApi(email, userId) {
  return axios.request({
    method: 'get',
    url: URLS.ADMIN_USER_EMAIL+email+URLS.CHECK_USER+userId,
  });
}

export function* checkCustomersDuplicateEmailInEditActionEffect(CustomersEmail) {
  let { email, userId, resolve, reject } = CustomersEmail;
  try {
    yield put(loadUI());
    let { data } = yield call(checkCustomersDuplicateEmailInEditApi, email, userId);
    yield put(clearUIError());
    
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    
    if(e.status == 'EMAIL_ALREADY_EXISTS'){
      if (reject) resolve();  
    }else{
      if (reject) reject(e);
    }
  }
}

export function* checkCustomerEmailInEditWatcher() {
  yield takeLatest(actions.CHECK_CUSTOMER_EMAIL_IN_EDIT_WATCHER, checkCustomersDuplicateEmailInEditActionEffect);
}

/**
 * Reset Customer Password Operation using saga
 */
// Reset Customer Password API
function resetCustomerPwdApi(id, params) {
    return axios.request({
      method: 'put',
      url: URLS.API_URL_FOR_ADMIN_RESET_PASSWORD_URL+ id,
      data: params
    });
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* resetCustomerPwdActionEffect(resetCustomerPwdAction) {
  let { id, payload, resolve, reject } = resetCustomerPwdAction;

  try {
    yield put(loadUI());
    let { data } = yield call(resetCustomerPwdApi, id, payload);
    yield put(clearUIError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* resetCustomerPwdActionWatcher() {
  yield takeLatest(actions.RESET_CUSTOMER_PWD_WATCHER, resetCustomerPwdActionEffect);
}

