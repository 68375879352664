import React from 'react';
import { Link } from "react-router-dom";
import ReactFormInputValidation from "react-form-input-validation";
import { TextInput, FormButton, DataTableList, Alert, Anchor } from "../common";
import UploadFileForm from "./UploadFileForm";
import * as constants from '../../constants/constants';

//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAddSupplierStockWatcher,
  manageSupplierStockWatcher, 
  changeSupplierStockStatusWatcher, 
  deleteSupplierStockWatcher, 
  uploadSupplierStockWatcher } from '../../redux/actionCreators/supplierStockActions';
import { loginAsUserWatcher } from '../../redux/actionCreators/authActions';
import { translationStrings } from '../../utils/locales';
import * as utils from "../../utils/utils";

class ManageSupplierStock extends React.Component<any, any>{
  dt: any;
  form: any;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          "field": "id",
          "header": translationStrings.common_datatable_sr_no,
          "sortable": true,
          "width": "8%"
        },
        {
          "field": "supplier_name",
          "header": translationStrings.common_lbl_supplier,
          "sortable": true,
          "width": "12%"
        },
        {
          "field": "brand",
          "header": translationStrings.common_lbl_brand,
          "sortable": true,
          "width": "10%"
        },
        {
          "field": "part_number",
          "header": translationStrings.common_lbl_part_number,
          "sortable": true,
          "width": "10%"
        },
        {
          "field": "description",
          "header": translationStrings.common_lbl_description,
          "sortable": true,
          "width": "10%"
        },
        {
          "field": "weight",
          "header": translationStrings.common_lbl_weight,
          "sortable": true,
          "width": "8%"
        },
        /*{
          "field": "qty",
          "header": translationStrings.common_lbl_qty,
          "sortable": true,
          "width": "5%"
        },*/
        {
          "field": "available_qty",
          "header": translationStrings.common_lbl_available_qty,
          "sortable": true,
          "width": "8%"
        },
        {
          "field": "unit_price",
          "header": translationStrings.common_lbl_unit_price,
          "sortable": true,
          "width": "9%"
        },
        {
          "field": "profit_percentage",
          "header": translationStrings.common_lbl_profit_percentage,
          "sortable": true,
          "width": "13%"
        },
        {
          "field": "actions",
          "header": translationStrings.common_datatable_actions,
          "width": "12%",
          "textAlign": "center"
        }
      ],
      event: {
        "event": {
          "first": 0,
          "rows": 25,
          "page": 0,
          "pageCount": 1,
          "sortOrder": 1,
          "sortField": null,
          "filters": {},
          "globalFilter": ""
        }
      },
      totalRecords: null,
      filterRecords: null,
      isLoaded: false,
      defaultLoadingMessage: translationStrings.default_loading_msg,
      alertVisible: false,
      alertId: null,
      alertStatus: null,
      filterPlaceholder: translationStrings.datatable_search_placeholder,
      fileError: null,
      profitPercentage: "",
      fields: {
        supplier: "",
        supplierList: [],
        selectedSupplier: [],
        input_file: "",
        attrName:{
          supplier: "Supplier",
          input_file: "File"
        },
        errors: {}
      },
    };
    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
      supplier: "required"
    });
    this.form.handleChangeEvent = (e) => {
      if(e.target.name=='input_file'){
        this.setState({ fields: { ...this.state.fields, ['input_file']: e.target.files[0]} });
      } else {
        this.setState({ fields: { ...this.state.fields, [e.target.name]: e.target.value} });
      }
        /*const target : any = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ fields: { ...this.state.fields, [name]: value} });
        formFields = this.state.fields;
        formFields[name] = value;*/
    }
    this.form.onformsubmit = (fields) => {
      if(this.state.fields.input_file===''){
        this.setState({'fileError': 'File is required'});
      } else {
        this.setState({'fileError': ''});
        const formData = new FormData();

        formData.append(
          'input_file',
          this.state.fields.input_file,
          this.state.fields.input_file.name
        );
        formData.append(
          'user_id',
          this.state.fields.supplier
        );
        formData.append(
          'profit_percentage',
          this.state.profitPercentage
        );
        props.uploadSupplierStockWatcher(formData);
        this.loadTable(this.state.event);
        this.setState({alertVisible: false, alertId: null, alertStatus: null});
        this.setState({
          fields: {
            supplier: "",
            supplierList: [],
            selectedSupplier: [],
            input_file: "",
            attrName:{
              supplier: translationStrings.common_lbl_supplier,
              input_file: translationStrings.common_lbl_upload
            },
            errors: {}
          }
        });
      }
    }
    this.rowClassName = this.rowClassName.bind(this);
  }
  renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  rowClassName(rowData) {
    let isActive = rowData.isActive;
    return { 'disableRow': (!isActive) };
  }

  componentDidMount() {
    document.title = translationStrings.supplier_stock_list_heading;
    if (this.props.supplierStock.data && this.props.supplierStock.record) {
      this.loadTable(this.state.event, this.props.supplierStock.data.data.input.event);
    } else {
      this.loadTable(this.state.event);
    }
  }

  loadTable(event, hasEvent: any = null) {
    if (hasEvent) {
      event = { "event": hasEvent };
      this.setState({ event: { "event": hasEvent } });
    }

    utils.printVal(event, 'event');

    var response = new Promise((resolve, reject) => {
      this.props.manageSupplierStockWatcher(event, resolve, reject);
    }).catch(e => {
      this.setState({ isLoaded: false });
      this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
    });
    response.then(() => {
      if (this.props.supplierStock.data.data) {
        let i = 0;
        this.setState({ data: [] });
        this.setState({ totalRecords: this.props.supplierStock.data.data.recordsTotal });
        this.setState({ filterRecords: this.props.supplierStock.data.data.recordsFiltered });
        this.props.supplierStock.data.data.data.forEach((value) => {
          let dataArr = {};
          dataArr['id'] = value.id;
          dataArr['supplier_name'] = value.supplier_name;
          dataArr['brand'] = value.brand;
          dataArr['part_number'] = value.part_number;
          dataArr['description'] = this.renderHTML(value.description);
          dataArr['weight'] = value.weight.toFixed(3);
          //dataArr['qty'] = value.qty;
          dataArr['available_qty'] = value.available_qty;
          dataArr['unit_price'] = value.unit_price.toFixed(2);
          dataArr['profit_percentage'] = value.profit_percentage;
          //dataArr['isActive'] = value.active;
          
            
          dataArr['actions'] = <div className="action-text-align">
            {utils.getPermissionByModule('supplier_stock', 'UPDATE') &&
              <><Link to={value.id + '/edit'} title={translationStrings.btn_title_edit}><i className="fa fa-lg fa-edit" /></Link>&nbsp;&nbsp;</>
            }
            {utils.getPermissionByModule('supplier_stock', 'DELETE') &&
              <>
              <Anchor class=""
              title={translationStrings.btn_title_delete}
              label={<i className="fa fa-lg fa-trash" />}
              handleClick={(e) => this.handleAlertClick(e, value.id, constants.DELETE)}
              />&nbsp;&nbsp;
              </>
            }
            {utils.getPermissionByModule('supplier_stock', 'CHANGE_STATUS') &&
              <>
              <Anchor class="" 
              label={<span style={{ color: value.is_active ? 'red' : 'green' }}><i className="fa fa-lg fa-bookmark" /></span>} 
              handleClick={(e) => this.handleAlertClick(e, value.id, value.is_active)} 
              title={value.is_active ? translationStrings.btn_title_deactivate : translationStrings.btn_title_activate} 
              />&nbsp;&nbsp;
              </>
            }

            {/*<Anchor class="" label={<i className="fa fa-lg fa-sign-in"/>} handleClick={(e) => this.onLoginAs(e, value.id)} title={translationStrings.lbl_login_as_user}/>*/}
          </div>

          this.state.data.push(dataArr);
          i++;
        });
        this.setState({ isLoaded: true });
      } else {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      }
    });
  }


  renderHeader() {
    return (
      <div className="row">
        <div className="col-sm-7"></div>
        <div className="col-sm-5">
          <div className="p-datatable-globalfilter-container text-right">
            <TextInput name="searchFilter" 
            class="form-control" 
            type={translationStrings.btn_title_search} 
            handleChange={this.filterEvent} 
            handleInput={this.filterEvent}
            placeholder={this.state.filterPlaceholder} 
            value={this.state.event.event.globalFilter} />
          </div>
        </div>
      </div>
    );
  }

  pageEvent = (e: any) => {
    this.state.event.event.first = e.first;
    this.state.event.event.rows = e.rows;
    this.state.event.event.page = e.page;
    this.state.event.event.pageCount = e.pageCount;
    this.loadTable(this.state.event);
  };

  sortEvent = (e: any) => {
    this.state.event.event.sortField = e.sortField;
    this.state.event.event.sortOrder = e.sortOrder;
    this.loadTable(this.state.event);
  }

  filterEvent = (e: any) => {
    this.state.event.event.globalFilter = e.target.value
    this.loadTable(this.state.event);
  }

  onLoginAs = (e: any, id: number) => {
    e.preventDefault();
    var response = new Promise((resolve, reject) => {
      this.props.loginAsUserWatcher(id, resolve, reject);
    }).catch(e => {

    });
    response.then(() => {

    });
  }

  handleSelectChange = (values) => {
      this.state.fields.selectedSupplier = values;
      let selectedIds = [];
      let profitPercentage = [];
      if(values && values.length>0){
          values.forEach(function(v){
              selectedIds.push(v.value);
              profitPercentage.push(v.profit_percentage);
          });
      }
      this.state.fields.supplier = selectedIds;
      this.state.fields.profit_percentage = profitPercentage[0];
      this.setState({profitPercentage: profitPercentage[0]});
  }

  handleAlertClick = (e: any, id: number, status: any) => {
    e.preventDefault();
    let currentStatus: any = status;
    if (currentStatus != constants.DELETE && currentStatus!=constants.UPLOAD_FILE) {
      currentStatus = currentStatus ? constants.DEACTIVATE : constants.ACTIVATE;
      this.setState({ alertVisible: true, alertId: id, alertStatus: currentStatus });
    } else if(currentStatus==constants.UPLOAD_FILE){
      this.setState({
          fields: {
            supplier: "",
            supplierList: [],
            selectedSupplier: [],
            input_file: "",
            attrName:{
              supplier: translationStrings.common_lbl_supplier,
              input_file: translationStrings.common_lbl_upload
            },
            errors: {}
          },
          alertVisible: true, 
          alertId: null, 
          alertStatus: currentStatus,
          errors: {}
      });
      
      this.form.errors = {};
      var response = new Promise((resolve, reject) => {
        this.props.getAddSupplierStockWatcher(resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        this.setState({ isLoaded: true });
        if(this.props.supplierStock.addData){
          let {data} = this.props.supplierStock.addData;
          
          if(utils.chkForUndefinedNullBlank(data.supplierStock.supplierList)){
            this.state.fields.supplierList = data.supplierStock.supplierList;
          }
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
    } else {
      this.setState({ alertVisible: true, alertId: id, alertStatus: currentStatus });
    }
  }

  handleAlertConfirmClick = (e: any, id: number, status: any) => {
    e.preventDefault();
    let objId = id;
    let changeStatus: string = null;
    changeStatus = this.state.alertStatus;
    this.setState({ alertVisible: false, alertId: null, alertStatus: null });
    var response = new Promise((resolve, reject) => {
      if(changeStatus == constants.UPLOAD_FILE){
        if(this.form.valid()){
          this.form.onformsubmit(id, this.state.fields);
        }
      }else if (changeStatus == constants.DELETE) {
        this.props.deleteSupplierStockWatcher(objId, resolve, reject);
      } else {
        this.props.changeSupplierStockStatusWatcher(objId, changeStatus, resolve, reject);
      }
    }).catch(e => {
      this.setState({ isLoaded: false });
    });
    response.then(() => {
      this.loadTable(this.state.event);
    });
  }

  handleCancelClick = (e) => {
    this.setState({ alertVisible: false, alertId: null, alertStatus: null });
  }

  renderAlertFooter = (name) => {
    return (
      <div>
        <FormButton 
        type="button" 
        class="btn btn-theme" 
        label={this.state.alertStatus && this.state.alertStatus != constants.UPLOAD_FILE ? translationStrings.btn_title_yes : translationStrings.btn_submit} 
        handleClick={(e) => this.handleAlertConfirmClick(e, this.state.alertId, this.state.alertStatus)} 
        />

        <FormButton 
        type="button" 
        class="btn btn-theme p-button-secondary cancelButton" 
        label={this.state.alertStatus && this.state.alertStatus != constants.UPLOAD_FILE ? translationStrings.btn_title_no : translationStrings.btn_cancle} 
        handleClick={() => this.setState({ alertVisible: false, alertId: null, alertStatus: null })} 
        />
      </div>
    );
  }

  addNewStock = () => {
    history.push(constants.URLS.GET_SUPPLIER_STOCK_ADD);
  }

  render() {
    const header = this.renderHeader();
    var status = '';
    var popup_heading = '';
    var confim_msg = '';
    if (this.state.alertStatus && this.state.alertStatus == constants.DELETE) {
      popup_heading = translationStrings.confirmation_popup_heading_delete_supplier_stock;
      confim_msg = translationStrings.confirmation_msg_for_delete_supplier_stock;
    } else if (this.state.alertStatus && this.state.alertStatus == constants.DEACTIVATE) {
      popup_heading = translationStrings.confirmation_popup_heading_deactivate_supplier_stock;
      confim_msg = translationStrings.confirmation_msg_for_deactivate_supplier_stock;
    } else if (this.state.alertStatus && this.state.alertStatus == constants.ACTIVATE) {
      popup_heading = translationStrings.confirmation_popup_heading_activate_supplier_stock;
      confim_msg = translationStrings.confirmation_msg_for_activate_supplier_stock;
    } else if (this.state.alertStatus && this.state.alertStatus == constants.UPLOAD_FILE) {
      popup_heading = translationStrings.confirmation_popup_heading_upload_file;
    }

    return (
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6  text-right">
                {utils.getPermissionByModule('supplier_stock', 'CREATE') &&
                  <><FormButton type="button" class="btn btn-theme" label={translationStrings.btn_upload} handleClick={(e) => this.handleAlertClick(e, -1, constants.UPLOAD_FILE)} />&nbsp;</>
                }
                {utils.getPermissionByModule('supplier_stock', 'UPLOAD_FILE') &&
                  <FormButton type="button" class="btn btn-theme" label={translationStrings.btn_add} handleClick={this.addNewStock} />
                }
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
                {this.state && this.state.isLoaded &&
                  <DataTableList
                    dtRef={(el) => this.dt = el}
                    header={header}
                    value={this.state.data}
                    columns={this.state.columns}
                    sortField={this.state.event.event.sortField}
                    sortOrder={this.state.event.event.sortOrder}
                    first={this.state.event.event.first}
                    rows={this.state.event.event.rows}
                    page={this.state.event.event.page}
                    pageCount={this.state.event.event.pageCount}
                    totalRecords={this.state.totalRecords}
                    filterRecords={this.state.filterRecords}
                    globalFilter={this.state.event.event.globalFilter}
                    pageEvent={this.pageEvent}
                    sortEvent={this.sortEvent}
                    rowClassName={this.rowClassName}
                  />
                }
                {this.state && !this.state.isLoaded &&
                  <div className="text-center">{this.state.defaultLoadingMessage}</div>
                }
              </div>
            </div>
          </section>
        </section>
        <Alert header={popup_heading} visible={this.state.alertVisible} hideHandler={(e) => this.setState({alertVisible: false, alertId: null, alertStatus: null})}
        content={this.state.alertStatus && this.state.alertStatus!=constants.UPLOAD_FILE ?
          confim_msg : this.state.alertStatus && this.state.alertStatus==constants.UPLOAD_FILE && this.state.isLoaded ?
          <UploadFileForm {...this.state} formHandler={this.form} fileError={this.state.fileError} handleCancelClick={this.handleCancelClick} handleSelectChange={this.handleSelectChange}/> : ''
        } handleFooter={this.state.alertStatus && this.state.alertStatus!=constants.UPLOAD_FILE  ? this.renderAlertFooter('displayBasic') : ''}/>
      </main>
    )
  }
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
  user: state.user,
  supplierStock: state.supplierStock,
  requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getAddSupplierStockWatcher,
    manageSupplierStockWatcher,
    changeSupplierStockStatusWatcher,
    deleteSupplierStockWatcher,
    uploadSupplierStockWatcher,
    loginAsUserWatcher
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManageSupplierStock)