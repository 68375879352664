import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { fillAccountDepositDataTable, fillAddAccountDeposit, fillAccountDeposit, setError, clearError } from '../actionCreators/accountDepositActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { URLS } from '../../constants/constants';
import { translationStrings} from '../../utils/locales';
/**
* Manage Account Deposit Operation using saga
*/

// Manage Account Deposit API
function manageAccountDepositApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_ACCOUNT_DEPOSIT_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageAccountDepositActionEffect is triggered
export function* manageAccountDepositActionEffect(manageAccountDepositAction) {
  let { payload, resolve, reject } = manageAccountDepositAction;
  try {
    yield put(loadUI());
    let data = yield call(manageAccountDepositApi, payload);
    yield put(fillAccountDepositDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_Admin_USERS_WATCHER action
export function* manageAccountDepositActionWatcher() {
  yield takeLatest(actions.MANAGE_ACCOUNT_DEPOSIT_WATCHER, manageAccountDepositActionEffect);
}

/**
* Change Account Deposit Status Operation using saga
*/
// Change Account Deposit Status API
function changeAccountDepositStatusApi(id, status) {
  return axios.request({
    method: 'put',
    url: '/change-status/accountDeposit/'+id
  });
}

// Saga function that handles the side effect when the changeAccountDepositStatusActionEffect is triggered
export function* changeAccountDepositStatusActionEffect(changeAccountDepositStatusAction) {
  let { payload, status, resolve, reject } = changeAccountDepositStatusAction;
  try {
    yield put(loadUI());
    let { data } = yield call(changeAccountDepositStatusApi, payload, status);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to CHANGE_Admin_USER_STATUS_WATCHER action
export function* changeAccountDepositStatusActionWatcher() {
  yield takeLatest(actions.CHANGE_ACCOUNT_DEPOSIT_STATUS_WATCHER, changeAccountDepositStatusActionEffect);
}

/**
* Fetch Account Deposit Operation using saga
*/
// Fetch Account Deposit Basic API
function fetchAccountDepositApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.ACCOUNT_DEPOSIT_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchAccountDepositActionWatcher is triggered
export function* fetchAccountDepositActionEffect(fetchAccountDepositAction) {
  let { id, resolve, reject } = fetchAccountDepositAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchAccountDepositApi, id);
    yield put(fillAccountDeposit(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_Admin_USER_WATCHER action
export function* fetchAccountDepositActionWatcher() {
  yield takeLatest(actions.GET_ACCOUNT_DEPOSIT_WATCHER, fetchAccountDepositActionEffect);
}


/**
* Fetch Add Account Deposit Operation using saga
*/
// Fetch Add Account Deposit API
function fetchAddAccountDepositApi() {
  return axios.request({
    method: 'get',
    url: URLS.ACCOUNT_DEPOSIT_ADD+'/create'
  });
}

// Saga function that handles the side effect when the fetchAddAccountDepositActionWatcher is triggered
export function* fetchAddAccountDepositActionEffect(fetchAddAccountDepositAction) {
  let { resolve, reject } = fetchAddAccountDepositAction;
  try {
    yield put(loadUI());
    let data = yield call(fetchAddAccountDepositApi);
    yield put(fillAddAccountDeposit(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ADD_ADMIN_USER_WATCHER action
export function* fetchAddAccountDepositActionWatcher() {
  yield takeLatest(actions.GET_ADD_ACCOUNT_DEPOSIT_WATCHER, fetchAddAccountDepositActionEffect);
}

/**
* Add Account Deposit Operation using saga
*/
// Add Account Deposit API
function addAccountDepositApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.ACCOUNT_DEPOSIT_ADD,
    data: params
  });
}

// Saga function that handles the side effect when the addAccountDepositActionWatcher is triggered
export function* addAccountDepositActionEffect(addAccountDepositAction) {
  let { payload, resolve, reject } = addAccountDepositAction;
  try {
    yield put(loadUI());
    let { data } = yield call(addAccountDepositApi, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.ACCOUNT_DEPOSIT_LIST));
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve(data);
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to ADD_Admin_USER_WATCHER action
export function* addAccountDepositActionWatcher() {
  yield takeLatest(actions.ADD_ACCOUNT_DEPOSIT_WATCHER, addAccountDepositActionEffect);
}


/**
* Update Account Deposit Operation using saga
*/
// Update Account Deposit API
function updateAccountDepositApi(id, params) {

  return axios.request({
    method: 'put',
    url: URLS.ACCOUNT_DEPOSIT_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateAccountDepositActionWatcher is triggered
export function* updateAccountDepositActionEffect(updateAccountDepositAction) {
  let { id, payload, resolve, reject } = updateAccountDepositAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateAccountDepositApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.ACCOUNT_DEPOSIT_LIST));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_Admin_USER_WATCHER action
export function* updateAccountDepositActionWatcher() {
  yield takeLatest(actions.UPDATE_ACCOUNT_DEPOSIT_WATCHER, updateAccountDepositActionEffect);
}


/**
* Delete Account Deposit Operation using saga
*/
// Delete Account Deposit API
function deleteAccountDepositApi(id) {
  return axios.request({
    method: 'delete',
    url: URLS.ACCOUNT_DEPOSIT_ADD+'/'+id,
  });
}

// Saga function that handles the side effect when the deleteAccountDepositActionWatcher is triggered
export function* deleteAccountDepositActionEffect(deleteAccountDepositAction) {
  let { id, resolve, reject } = deleteAccountDepositAction;
  try {
    yield put(loadUI());
    let { data } = yield call(deleteAccountDepositApi, id);
    yield put(clearUIError());
    yield put(clearError());
    //yield put(push('/AdminUser/list'));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to DELETE_Admin_USER_WATCHER action
export function* deleteAccountDepositActionWatcher() {
  yield takeLatest(actions.DELETE_ACCOUNT_DEPOSIT_WATCHER, deleteAccountDepositActionEffect);
}