import * as React from 'react';
import { PasswordInput, TextInput, FormButton, Label, Anchor, FileInput, MultipleSelect } from "../common";
import { translationStrings} from '../../utils/locales';
import { download_file_url } from '../../utils/axios';

export default class UploadFileForm extends React.Component<any, any>{

  buildSuppliers() {
    let supplierList = [];
    this.props.fields.supplierList.forEach(function(v){
      let supplierObj={};
      supplierObj['label'] = v.name.trim();
      supplierObj['value'] = v.id;
      supplierObj['profit_percentage'] = v.default_profit_percentage;
      supplierList.push(supplierObj);
    });
    return supplierList; 
  }

  render(){
    const {fields, formHandler, handleCancelClick, handleSelectChange, fileError} = this.props;
    return (
      <form className="resetPasswordContainer" onSubmit={formHandler.handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <a href={download_file_url+'ProductsUploadFormat.xlsx'}>Download Format</a>
        </div>
        <div className="form-group">
            <Label forName="user_id" label={translationStrings.add_edit_supplier_stock_field_supplier} require="inline"/>
            <MultipleSelect isMultiple={false} name="supplier" options={this.buildSuppliers()} handleChange={handleSelectChange} placeHolder={translationStrings.lbl_select} attrName={fields.attrName.supplier} value={fields.selectedSupplier} />
            <label className="error">
              {formHandler.errors.supplier ? formHandler.errors.supplier : ""}
            </label>
        </div>
        <div className="form-group">
          <Label forName="input_file" label={translationStrings.common_lbl_upload} require="inline"/>
          {/*<FileInput class="form-control" name="input_file" handleUpload={formHandler.handleChangeEvent} attrName={fields.attrName.input_file}/>*/}
          <input type="file" name="input_file" onChange={formHandler.handleChangeEvent} data-attribute-name={fields.attrName.input_file}/>  
          {fileError &&
            <label className="error">
              {fileError}
            </label>
          }
        </div>
        <div className="form-group text-right">
            <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme"/>
            <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick}/>
        </div>
      </form>
    )
  }
}