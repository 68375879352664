import * as actions from '../types';

// Worker triggering actionCreators
export function manageTestimonialsWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_TESTIMONIALS_WATCHER, payload: payload, resolve, reject};
}
export function changeTestimonialsStatusWatcher(payload, status, resolve, reject) {
  return { type: actions.CHANGE_TESTIMONIALS_STATUS_WATCHER, payload: payload, status: status, resolve, reject};
}
export function getTestimonialsWatcher(id, resolve, reject) {
  return { type: actions.GET_TESTIMONIALS_WATCHER, id: id, resolve, reject};
}
export function getAddTestimonialsWatcher(resolve, reject) {
  return { type: actions.GET_ADD_TESTIMONIALS_WATCHER, resolve, reject}; 
}
export function addTestimonialsWatcher(payload, resolve, reject) {
  return { type: actions.ADD_TESTIMONIALS_WATCHER, payload: payload, resolve, reject };   
}
export function updateTestimonialsWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_TESTIMONIALS_WATCHER, id: id, payload: payload, resolve, reject };    
}
export function deleteTestimonialsWatcher(id, resolve, reject) {
  return { type: actions.DELETE_TESTIMONIALS_WATCHER, id: id, resolve, reject };  
}

// Redux state changing actionCreators
export function fillTestimonialsDataTable(payload) {
  return { type: actions.FILL_TESTIMONIALS_DATATABLE, payload: payload };
}
export function fillAddTestimonials(payload) {
  return { type: actions.FILL_ADD_TESTIMONIALS, payload: payload };
}
export function fillTestimonials(payload) {
  return { type: actions.FILL_TESTIMONIALS, payload: payload};
}
export function setError(error) {
  return { type: actions.SET_TESTIMONIALS_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_TESTIMONIALS_ERRORS, payload: null };
}