import React, {Component} from 'react';
import Select from 'react-dropdown-select';

export interface MultiPleSelectSchema {
	isMultiple?: boolean;
	name: string;
	value?: any[] | any | string;
	options: any;
	placeHolder: string;
	class?: string;
	attrName?: string;
	handleChange?: any;
}

export const MultipleSelect = (props: MultiPleSelectSchema) =>  {
  return (
  	<Select
  		multi={props.isMultiple}
  		values={props.value}
  		name={props.name}
  		options={props.options}
  		onChange={props.handleChange}
  		data-attribute-name={props.attrName}
  		placeholder={props.placeHolder}
  		clearable
  	/>
  );
}