import React, {Component} from 'react';

export function Notification(message) {
    return (
    	<ol className="error-list">
    		{
	          Object.keys(message).map((oneKey,i)=>{
	          	if(message[oneKey]['field']){
	          		return (
		                <li key={i}>{message[oneKey]['field']} {message[oneKey]['message']}</li>
		            )
	          	} else {
	          		var errorList = [];
	          		$.each( message[oneKey], function( key:any, value ) {
	          			errorList[key] = <li key={i}>{value}</li>;
	          		});
	          		return (
		                errorList
		            )
	          	}
	          })
	        }
    	</ol>
    )
}