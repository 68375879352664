import * as React from 'react';
import { TextInput, TextArea, PasswordInput, DropDown, FormButton, Label, Anchor } from "../common";
import { translationStrings } from '../../utils/locales';

export default class SupplierForm extends React.Component<any, any>{
  // buildRoles() {
  //     var arr = [];
  //     var i = 0;
  //     this.props.fields.roles.forEach(function(v){
  //         arr.push(<option key={i} value={v.id}>{v.name}</option>)
  //         i++;
  //     });

  //     return arr; 
  // }

  // buildCountries() {
  //     var arr = [];
  //     var i = 0;
  //     this.props.fields.countries.forEach(function(v){
  //         arr.push(<option key={i} value={v.id}>{v.name}</option>)
  //         i++;
  //     });

  //     return arr; 
  // }

  // buildLocales() {
  //     var arr = [];
  //     var i = 0;
  //     this.props.fields.locales.forEach(function(v){
  //         arr.push(<option key={i} value={v.id}>{v.name}</option>)
  //         i++;
  //     });

  //     return arr; 
  // }

  render() {
    const {
      fields,
      formHandler,
      handleCancelClick,
      validateEmail,
      handleNewPasswordIconClick,
      handleConfirmPasswordIconClick } = this.props;
    return (
      <form onSubmit={formHandler.handleSubmit}>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="first_name" label={translationStrings.common_lbl_first_name} require="inline" />
            <TextInput
              class="form-control"
              name="first_name"
              type="text"
              placeholder={translationStrings.common_lbl_first_name}
              handleBlur={formHandler.handleBlurEvent}
              handleChange={formHandler.handleChangeEvent}
              value={fields.first_name}
              attrName={fields.attrName.first_name}
            />
            <label className="error">
              {formHandler.errors.first_name ? formHandler.errors.first_name : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="last_name" label={translationStrings.common_lbl_last_name} require="inline" />
            <TextInput 
            class="form-control" 
            name="last_name" 
            type="text" 
            placeholder={translationStrings.common_lbl_last_name} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.last_name} 
            attrName={fields.attrName.last_name} 
            />
            <label className="error">
              {formHandler.errors.last_name ? formHandler.errors.last_name : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="email" label={translationStrings.common_lbl_email} require="inline" />
            <TextInput 
            class="form-control" 
            name="email" 
            type="text" 
            placeholder={translationStrings.common_lbl_email} 
            handleBlur={validateEmail} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.email} 
            attrName={fields.attrName.email} 
            />
            {!fields.email &&
              <label className="error">
                {formHandler.errors.email ? formHandler.errors.email : ""}
              </label>
            }
          </div>
          <div className="form-group col-sm-6">
            <Label forName="mobile_number" label={translationStrings.common_lbl_contact_number} require="inline" />
            <TextInput 
            class="form-control" 
            name="mobile_number" 
            type="text" 
            placeholder={translationStrings.common_lbl_contact_number} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.mobile_number} 
            attrName={fields.attrName.mobile_number} />
            <label className="error">
              {formHandler.errors.mobile_number ? formHandler.errors.mobile_number : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="company_name" label={translationStrings.common_lbl_company_name} />
            <TextInput 
            class="form-control" 
            name="company_name" 
            type="text" 
            placeholder={translationStrings.common_lbl_company_name} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.company_name} 
            attrName={fields.attrName.company_name} />
            <label className="error">
              {formHandler.errors.company_name ? formHandler.errors.company_name : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="company_tax_registration_number" label={translationStrings.common_lbl_company_tax_reg_number} />
            <TextInput 
            class="form-control" 
            name="company_tax_registration_number" 
            type="text" 
            placeholder={translationStrings.common_lbl_company_tax_reg_number} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.company_tax_registration_number} 
            attrName={fields.attrName.company_tax_registration_number} />
            <label className="error">
              {formHandler.errors.company_tax_registration_number ? formHandler.errors.company_tax_registration_number : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="office_phone_number" label={translationStrings.common_lbl_office_contact_number} />
            <TextInput 
            class="form-control" 
            name="office_phone_number" 
            type="text" 
            placeholder={translationStrings.common_lbl_office_contact_number} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.office_phone_number} 
            attrName={fields.attrName.office_phone_number} 
            />
            <label className="error">
              {formHandler.errors.office_phone_number ? formHandler.errors.office_phone_number : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="address" label={translationStrings.common_lbl_address} />
            <TextArea 
            class="form-control" 
            name="address" 
            placeholder={translationStrings.common_lbl_address}
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.address} 
            attrName={fields.attrName.address}
            />
            <label className="error">
              {formHandler.errors.address ? formHandler.errors.address : ""}
            </label>
          </div>
        </div>
        {/*!fields.id &&
          <div className="row">
            <div className="form-group col-sm-6">
              <Label forName="password" label={translationStrings.common_lbl_password} require="inline" />
              <TextInput 
              class="form-control" 
              name="password" 
              type={this.props.newPasswordType} 
              placeholder={translationStrings.common_lbl_password} 
              handleChange={formHandler.handleChangeEvent} 
              handleBlur={formHandler.handleBlurEvent} 
              value={'123456'} 
              attrName={fields.attrName.password}
              />
              <Anchor 
              class="eye-icon" 
              label={(this.props.newPasswordType == 'password') ? <i className="fa fa-eye icon-theme" /> : <i className="fa fa-eye-slash icon-theme" />} 
              handleClick={(e) => formHandler.handleNewPasswordIconClick(e)} 
              title={(this.props.newPasswordType == 'password') ? translationStrings.common_lbl_show : translationStrings.common_lbl_hide} />
              <label className="error">
                {formHandler.errors.password ? formHandler.errors.password : ""}
              </label>
            </div>
            <div className="form-group col-sm-6">
              <Label forName="password_confirmation" label={translationStrings.common_lbl_confirm_password} require="inline" />
              <TextInput 
              class="form-control" 
              name="password_confirmation" 
              type={this.props.confirmPasswordType} 
              placeholder={translationStrings.common_lbl_confirm_password} 
              handleChange={formHandler.handleChangeEvent} 
              handleBlur={formHandler.handleBlurEvent} 
              value={'123456'} 
              attrName={fields.attrName.password_confirmation} 
              />
              <Anchor 
              class="eye-icon" 
              label={(this.props.confirmPasswordType == 'password') ? <i className="fa fa-eye icon-theme" /> : <i className="fa fa-eye-slash icon-theme" />} 
              handleClick={(e) => formHandler.handleConfirmPasswordIconClick(e)} 
              title={(this.props.confirmPasswordType == 'password') ? translationStrings.common_lbl_show : translationStrings.common_lbl_hide} 
              />
              <label className="error">
                {formHandler.errors.password_confirmation ? formHandler.errors.password_confirmation : ""}
              </label>
            </div>
          </div>
        */}
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="office_phone_number" label={translationStrings.common_lbl_default_profit_percentage} />
            <TextInput 
            class="form-control" 
            name="default_profit_percentage" 
            type="text" 
            placeholder={translationStrings.common_lbl_default_profit_percentage} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.default_profit_percentage} 
            attrName={fields.attrName.default_profit_percentage} 
            />
            <label className="error">
              {formHandler.errors.default_profit_percentage ? formHandler.errors.default_profit_percentage : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="address" label={translationStrings.common_lbl_supplier_code_name} require="inline"/>
            <TextInput 
              class="form-control" 
              name="supplier_code_name" 
              type="text" 
              placeholder={translationStrings.common_lbl_supplier_code_name} 
              handleBlur={formHandler.handleBlurEvent} 
              handleChange={formHandler.handleChangeEvent} 
              value={fields.supplier_code_name} 
              attrName={fields.attrName.supplier_code_name} 
            />
            <label className="error">
                {formHandler.errors.supplier_code_name ? formHandler.errors.supplier_code_name : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="about" label={translationStrings.common_lbl_about} />
            <TextArea 
            class="form-control" 
            name="about" 
            placeholder={translationStrings.common_lbl_about}
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.about} 
            attrName={fields.attrName.about}
            />
            <label className="error">
              {formHandler.errors.about ? formHandler.errors.about : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="handling_time" label={translationStrings.common_lbl_handling_time} />
            <TextArea 
            class="form-control" 
            name="handling_time" 
            placeholder={translationStrings.common_lbl_handling_time}
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.handling_time} 
            attrName={fields.attrName.handling_time}
            />
            <label className="error">
              {formHandler.errors.handling_time ? formHandler.errors.handling_time : ""}
            </label>
          </div>
        </div>
        <div className="form-group text-right">
          <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme" />
          <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick} />
        </div>
      </form>
    )
  }
}