import React, {Component} from 'react';

export interface LabelSchema {
	forName?: string;
	class?: string;
	label?: string;
	require?: 'none' | string;
}

export const Label = (props: LabelSchema) =>  {
  return (
  	<label htmlFor={props.forName} className={props.class}>{props.label}<span style={{display: props.require ? props.require : 'none',color: "red"}}>*</span></label>
  );
}