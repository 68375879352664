import React, {useEffect} from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Link, Redirect } from "react-router-dom";

//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as constants from '../../constants/constants';
import axios from 'axios'
import { base_url } from '../../utils/axios';

class Dashboard extends React.Component<any,any>{
  constructor(props) {
    super(props);

    this.state = {
      allMakes: [],
      mostOrderedProducts: [],
      mostSearchedProducts: [],
      mostPopularCustomers: []
    };

    const headers = {
        'x-auth-token': localStorage.getItem('accessToken')
    };

    axios.get(base_url+'dashboard/get-all-makes', { headers }).then(response => {
      this.setState({allMakes: response.data.makeData});
    });

    axios.post(base_url + 'dashboard/most-ordered-products', null, {headers}).then(response => {
      this.setState({mostOrderedProducts: response.data.chartData});
    });

    axios.post(base_url + 'dashboard/most-searched-products', null, {headers}).then(response => {
      this.setState({mostSearchedProducts: response.data.chartData});
    });

    axios.post(base_url + 'dashboard/most-popular-customers', null, {headers}).then(response => {
      this.setState({mostPopularCustomers: response.data.chartData});
    });
  }

  renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  filterTopSoldStats = (e) => {
    e.preventDefault();
    const fromDate = (document.getElementById('top_sold_from_date') as HTMLInputElement).value;
    const toDate = (document.getElementById('top_sold_to_date') as HTMLInputElement).value;
    const brand = (document.getElementById('top_sold_brand') as HTMLInputElement).value;
    const top = (document.getElementById('top_sold_top') as HTMLInputElement).value;

    const headers = {
        'x-auth-token': localStorage.getItem('accessToken')
    };
    
    const params = {
      from_date: fromDate,
      to_date: toDate,
      brand: brand,
      top: top
    }

    axios.post(base_url + 'dashboard/most-ordered-products', params, {headers}).then(response => {
      this.setState({mostOrderedProducts: response.data.chartData});
    });
  }

  filterTopSearchedStats = (e) => {
    e.preventDefault();
    const fromDate = (document.getElementById('top_searched_from_date') as HTMLInputElement).value;
    const toDate = (document.getElementById('top_searched_to_date') as HTMLInputElement).value;
    const brand = (document.getElementById('top_searched_brand') as HTMLInputElement).value;
    const top = (document.getElementById('top_searched_top') as HTMLInputElement).value;

    const headers = {
        'x-auth-token': localStorage.getItem('accessToken')
    };
    
    const params = {
      from_date: fromDate,
      to_date: toDate,
      brand: brand,
      top: top
    }

    axios.post(base_url + 'dashboard/most-searched-products', params, {headers}).then(response => {
      this.setState({mostSearchedProducts: response.data.chartData});
    });
  }

  filterTopCustomersStats = (e) => {
    e.preventDefault();
    const fromDate = (document.getElementById('top_customers_from_date') as HTMLInputElement).value;
    const toDate = (document.getElementById('top_customers_to_date') as HTMLInputElement).value;
    const top = (document.getElementById('top_customers_top') as HTMLInputElement).value;

    const headers = {
        'x-auth-token': localStorage.getItem('accessToken')
    };
    
    const params = {
      from_date: fromDate,
      to_date: toDate,
      top: top
    }

    axios.post(base_url + 'dashboard/most-popular-customers', params, {headers}).then(response => {
      this.setState({mostPopularCustomers: response.data.chartData});
    });
  }

  downloadStats = (e, id) => {
    e.preventDefault();

    var htmls = "";
    var uri = 'data:application/vnd.ms-excel;base64,';
    var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'; 
    var base64 = function(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
    };

    var format = function(s, c) {
        return s.replace(/{(\w+)}/g, function(m, p) {
            return c[p];
        })
    };

    htmls = document.getElementById(id).innerHTML

    var ctx = {
        worksheet : 'Worksheet',
        table : htmls
    }


    var link = document.createElement("a");
    link.download = id+".xls";
    link.href = uri + base64(format(template, ctx));
    link.click();
  }

  render(){
        return(
           <main className="sectionWrapper">
            <section className="container">
              <div className="sectionHeader">
                <div className="row">
                  <div className="col-sm-6">
                    <h3>{document.title}</h3>
                  </div>
                  <div className="col-sm-6 text-right">
                  </div>
                </div>
              </div>
              <section className="chartContainer">
                <div className="row">
                  <div className="col-sm-12">
                    <strong>Top Sold Products</strong>
                    <form className="form-horizontal" role="form">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label className="control-label">From Date:</label>
                            <input id="top_sold_from_date" name="top_sold_from_date" className="form-control" type="date"/>
                          </div>
                          <div className="col-sm-2">
                            <label className="control-label">To Date:</label>
                            <input id="top_sold_to_date" name="top_sold_to_date" className="form-control" type="date"/>
                          </div>
                          <div className="col-sm-2">
                            <label className="control-label">Brand:</label>
                            <select id="top_sold_brand" name="top_sold_brand" className="form-control">
                              <option value="">All</option>
                              {this.state.allMakes &&
                                this.state.allMakes.map((item) => {
                                  return (
                                    <option key={item} value={item}>{item}</option>
                                  );
                                })
                              }
                            </select>
                          </div>
                          <div className="col-sm-2">
                            <label className="control-label">Top:</label>
                            <select id="top_sold_top" name="top_sold_top" className="form-control">
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <div className="col-sm-2 mt-4 text-right">
                            <button onClick={this.filterTopSoldStats}>Show</button>
                          </div>
                          <div className="col-sm-2 mt-4">
                            <button onClick={(e) => this.downloadStats(e, 'top-sold-products')}>Download Excel</button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <table id="top-sold-products" className="table">
                        <thead>
                          <tr>
                            <th>Part Number</th>
                            <th>Description</th>
                            <th>Brand</th>
                            <th>Sold Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.mostOrderedProducts.length > 0 ?
                          this.state.mostOrderedProducts.map((item) => {
                            return(
                              <tr key={item.id}>
                                <td>{item.part_number}</td>
                                <td>{this.renderHTML(item.description)}</td>
                                <td>{item.make}</td>
                                <td>{item.total}</td>
                              </tr>
                            );
                          }) : <tr key={0}><td colSpan={4} align="center">No Data Found!</td></tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <strong>Top Searched Products</strong>
                    <form className="form-horizontal" role="form">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label className="control-label">From Date:</label>
                            <input id="top_searched_from_date" name="top_searched_from_date" className="form-control" type="date"/>
                          </div>
                          <div className="col-sm-2">
                            <label className="control-label">To Date:</label>
                            <input id="top_searched_to_date" name="top_searched_to_date" className="form-control" type="date"/>
                          </div>
                          <div className="col-sm-2">
                            <label className="control-label">Brand:</label>
                            <select id="top_searched_brand" name="top_searched_brand" className="form-control">
                              <option value="">All</option>
                              {this.state.allMakes &&
                                this.state.allMakes.map((item) => {
                                  return (
                                    <option key={item} value={item}>{item}</option>
                                  );
                                })
                              }
                            </select>
                          </div>
                          <div className="col-sm-2">
                            <label className="control-label">Top:</label>
                            <select id="top_searched_top" name="top_searched_top" className="form-control">
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <div className="col-sm-2 mt-4 text-right">
                            <button onClick={this.filterTopSearchedStats}>Show</button>
                          </div>
                          <div className="col-sm-2 mt-4">
                            <button onClick={(e) => this.downloadStats(e, 'top-searched-products')}>Download Excel</button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <table id="top-searched-products" className="table">
                        <thead>
                          <tr>
                            <th>Part Number</th>
                            <th>Description</th>
                            <th>Brand</th>
                            <th>Searched</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.mostSearchedProducts.length > 0 ?
                          this.state.mostSearchedProducts.map((item) => {
                            return(
                              <tr key={item.id}>
                                <td>{item.part_number}</td>
                                <td>{this.renderHTML(item.description)}</td>
                                <td>{item.brand}</td>
                                <td>{item.total}</td>
                              </tr>
                            );
                          }) : <tr key={0}><td colSpan={4} align="center">No Data Found!</td></tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <strong>Top Customers</strong>
                    <form className="form-horizontal" role="form">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label className="control-label">From Date:</label>
                            <input id="top_customers_from_date" name="top_customers_from_date" className="form-control" type="date"/>
                          </div>
                          <div className="col-sm-2">
                            <label className="control-label">To Date:</label>
                            <input id="top_customers_to_date" name="top_customers_to_date" className="form-control" type="date"/>
                          </div>
                          <div className="col-sm-2">
                            <label className="control-label">Top:</label>
                            <select id="top_customers_top" name="top_customers_top" className="form-control">
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <div className="col-sm-2 mt-4 text-right">
                            <button onClick={this.filterTopCustomersStats}>Show</button>
                          </div>
                          <div className="col-sm-2 mt-4">
                            <button onClick={(e) => this.downloadStats(e, 'top-customers')}>Download Excel</button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <table id="top-customers" className="table">
                      <thead>
                      <tr>
                        <th>Customer Name</th>
                        <th>Order Amount</th>
                      </tr>
                      </thead>
                      <tbody>
                        {this.state.mostPopularCustomers.length > 0 ?
                          this.state.mostPopularCustomers.map(function(item) {
                            return(
                              <tr key={item.id}>
                                <td>{item.customer_name}</td>
                                <td>{item.total.toFixed(2)}</td>
                              </tr>
                            );
                          }) : <tr key={0}><td colSpan={2} align="center">No Data Found!</td></tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </section>
        </main>
        )
     }
}
const mapStateToProps = (state: any) => ({
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)