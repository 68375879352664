import * as React from 'react';
import { TextInput, TextArea, PasswordInput, DropDown, FormButton, Label, Anchor, MultipleSelect } from "../common";
import { translationStrings } from '../../utils/locales';

export default class SupplierStockForm extends React.Component<any, any>{
  buildSuppliers() {
    let supplierList = [];
    this.props.fields.supplierList.forEach(function(v){
      let supplierObj={};
      supplierObj['label'] = v.name.trim();
      supplierObj['value'] = v.id;
      supplierObj['profit_percentage'] = v.default_profit_percentage;
      supplierList.push(supplierObj);
    });
    return supplierList; 
  }

  render() {
    const {
      fields,
      formHandler,
      handleCancelClick,
      handleSelectChange,
      validatePartNumber,
      profitPercentage,
      handleMakeBlur
    } = this.props;
    return (
      <form onSubmit={formHandler.handleSubmit}>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="supplier" label={translationStrings.add_edit_supplier_stock_field_supplier} require="inline"/>
            <MultipleSelect isMultiple={false} name="supplier" options={this.buildSuppliers()} handleChange={handleSelectChange} placeHolder={translationStrings.lbl_select} attrName={fields.attrName.supplier} value={fields.selectedSupplier} />
            <label className="error">
              {formHandler.errors.supplier ? formHandler.errors.supplier : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="make" label={translationStrings.common_lbl_brand} require="inline" />
            <TextInput
              class="form-control"
              name="make"
              type="text"
              placeholder={translationStrings.common_lbl_brand}
              handleBlur={handleMakeBlur}
              handleChange={formHandler.handleChangeEvent}
              value={fields.make}
              attrName={fields.attrName.make}
            />
            <label className="error">
              {formHandler.errors.make ? formHandler.errors.make : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="part_number" label={translationStrings.common_lbl_part_number} require="inline" />
            <TextInput 
            class="form-control" 
            name="part_number" 
            type="text" 
            placeholder={translationStrings.common_lbl_part_number} 
            handleBlur={validatePartNumber} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.part_number} 
            attrName={fields.attrName.part_number} 
            />
            <label className="error">
              {formHandler.errors.part_number ? formHandler.errors.part_number : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="description" label={translationStrings.common_lbl_description} require="inline" />
            <TextInput 
            class="form-control" 
            name="description" 
            type="text" 
            placeholder={translationStrings.common_lbl_description} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.description} 
            attrName={fields.attrName.description} 
            />
            <label className="error">
              {formHandler.errors.description ? formHandler.errors.description : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="weight" label={translationStrings.common_lbl_weight} require="inline" />
            <TextInput 
            class="form-control" 
            name="weight" 
            type="text" 
            placeholder={translationStrings.common_lbl_weight} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.weight} 
            attrName={fields.attrName.weight} />
            <label className="error">
              {formHandler.errors.weight ? formHandler.errors.weight : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="weight" label={translationStrings.common_lbl_qty} require="inline" />
            <TextInput 
            class="form-control" 
            name="available_qty" 
            type="text" 
            placeholder={translationStrings.common_lbl_qty} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.available_qty} 
            attrName={fields.attrName.available_qty} />
            <label className="error">
              {formHandler.errors.available_qty ? formHandler.errors.available_qty : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="unit_price" label={translationStrings.common_lbl_unit_price} require="inline" />
            <TextInput 
            class="form-control" 
            name="unit_price" 
            type="text" 
            placeholder={translationStrings.common_lbl_unit_price} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.unit_price} 
            attrName={fields.attrName.unit_price} />
            <label className="error">
              {formHandler.errors.unit_price ? formHandler.errors.unit_price : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="profit_percentage" label={translationStrings.common_lbl_profit_percentage} require="inline" />
            <TextInput 
            class="form-control" 
            name="profit_percentage" 
            type="text" 
            placeholder={translationStrings.common_lbl_profit_percentage} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={profitPercentage} 
            attrName={fields.attrName.profit_percentage} />
            <label className="error">
              {formHandler.errors.profit_percentage ? formHandler.errors.profit_percentage : ""}
            </label>
          </div>
        </div>
        <div className="form-group text-right">
          <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme" />
          <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick} />
        </div>
      </form>
    )
  }
}