import * as React from 'react';
import { TextInput, PasswordInput, FormButton, Label, Anchor } from "../common";
import { translationStrings} from '../../utils/locales';

export default class ForgetPasswordForm extends React.Component<any, any>{
  render(){
    const {fields, formHandler, handleClick} = this.props;
    return (
      <form onSubmit={formHandler.handleSubmit}>
          <p>{translationStrings.forgot_password}</p>
          <div className="form-group">
              <Label forName="email" label={translationStrings.common_lbl_email} require="inline"/>
              <TextInput class="form-control" name="email" type="text" placeholder={translationStrings.common_lbl_email} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.email} attrName={fields.attrName.email}/>
              <label className="error">
                {formHandler.errors.email ? formHandler.errors.email : ""}
              </label>
          </div>
          <div className="form-group">
              <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme full-width-button"/>
          </div>
          <Anchor class="forgetLink" label={translationStrings.lbl_go_to_login_text} handleClick={handleClick}/>
      </form>
    )
  }
}