import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { 
  fillOrderDataTable, 
  fillAddOrder, 
  fillOrder, 
  setError, 
  clearError } from '../actionCreators/orderActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { URLS } from '../../constants/constants';
import { translationStrings} from '../../utils/locales';
/**
* Manage Order Operation using saga
*/

// Manage Order API
function manageOrderApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_ORDER_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageOrderActionEffect is triggered
export function* manageOrderActionEffect(manageOrderAction) {
  let { payload, resolve, reject } = manageOrderAction;
  try {
    yield put(loadUI());
    let data = yield call(manageOrderApi, payload);
    yield put(fillOrderDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_ORDER_WATCHER action
export function* manageOrderActionWatcher() {
  yield takeLatest(actions.MANAGE_ORDER_WATCHER, manageOrderActionEffect);
}

/**
* Change Order Status Operation using saga
*/
// Change Order Status API
function changeOrderStatusApi(id, status) {
  return axios.request({
    method: 'put',
    url: '/change-status/order/'+id
  });
}

// Saga function that handles the side effect when the changeOrderStatusActionEffect is triggered
export function* changeOrderStatusActionEffect(changeOrderStatusAction) {
  let { payload, status, resolve, reject } = changeOrderStatusAction;
  try {
    yield put(loadUI());
    let { data } = yield call(changeOrderStatusApi, payload, status);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to CHANGE_ORDER_STATUS_WATCHER action
export function* changeOrderStatusActionWatcher() {
  yield takeLatest(actions.CHANGE_ORDER_STATUS_WATCHER, changeOrderStatusActionEffect);
}

/**
* Fetch Order Operation using saga
*/
// Fetch Order Basic API
function fetchOrderApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.ORDER_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchOrderActionWatcher is triggered
export function* fetchOrderActionEffect(fetchOrderAction) {
  let { id, resolve, reject } = fetchOrderAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchOrderApi, id);
    yield put(fillOrder(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ORDER_WATCHER action
export function* fetchOrderActionWatcher() {
  yield takeLatest(actions.GET_ORDER_WATCHER, fetchOrderActionEffect);
}


/**
* Fetch Add Order Operation using saga
*/
// Fetch Add Order API
function fetchAddOrderApi() {
  return axios.request({
    method: 'get',
    url: URLS.API_URL_FOR_ORDER_LIST
  });
}

// Saga function that handles the side effect when the fetchAddOrderActionWatcher is triggered
export function* fetchAddOrderActionEffect(fetchAddOrderAction) {
  let { resolve, reject } = fetchAddOrderAction;
  try {
    yield put(loadUI());
    let data = yield call(fetchAddOrderApi);
    yield put(fillAddOrder(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ADD_ORDER_WATCHER action
export function* fetchAddOrderActionWatcher() {
  yield takeLatest(actions.GET_ADD_ORDER_WATCHER, fetchAddOrderActionEffect);
}

/**
* Add Order Operation using saga
*/
// Add Order API
function addOrderApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.ORDER_ADD,
    data: params
  });
}

// Saga function that handles the side effect when the addOrderActionWatcher is triggered
export function* addOrderActionEffect(addOrderAction) {
  let { payload, resolve, reject } = addOrderAction;
  try {
    yield put(loadUI());
    let { data } = yield call(addOrderApi, payload);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve(data);
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to ADD_ORDER_WATCHER action
export function* addOrderActionWatcher() {
  yield takeLatest(actions.ADD_ORDER_WATCHER, addOrderActionEffect);
}


/**
* Update Order Operation using saga
*/
// Update Order API
function updateOrderApi(id, params) {
  
  // let updateUrl: any;
  // if(editType=='basic'){
    // updateUrl: URLS.Admin_USER_ADD+'/'+id
  // } else {
  //   updateUrl: URLS.Admin_USER_ADD+'/'+id
  // }
  return axios.request({
    method: 'put',
    url: URLS.ORDER_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateOrderActionWatcher is triggered
export function* updateOrderActionEffect(updateOrderAction) {
  let { id, payload, resolve, reject } = updateOrderAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateOrderApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.ORDER_LIST));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_ORDER_WATCHER action
export function* updateOrderActionWatcher() {
  yield takeLatest(actions.UPDATE_ORDER_WATCHER, updateOrderActionEffect);
}


/**
* Delete Order Operation using saga
*/
// Delete Order API
function deleteOrderApi(id) {
  return axios.request({
    method: 'delete',
    url: URLS.ORDER_ADD+'/'+id,
  });
}

// Saga function that handles the side effect when the deleteOrderActionWatcher is triggered
export function* deleteOrderActionEffect(deleteOrderAction) {
  let { id, resolve, reject } = deleteOrderAction;
  try {
    yield put(loadUI());
    let { data } = yield call(deleteOrderApi, id);
    yield put(clearUIError());
    yield put(clearError());
    //yield put(push('/order/list'));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to DELETE_ORDER_WATCHER action
export function* deleteOrderActionWatcher() {
  yield takeLatest(actions.DELETE_ORDER_WATCHER, deleteOrderActionEffect);
}