import * as actions from '../types';

const initialState = {
  data: null,
  record: null,
  addData: null,
  error: null,
  cars:null
};

const supplierStock = (state = initialState, action) => {
  switch (action.type) {
    case actions.FILL_SUPPLIER_STOCK_DATATABLE:
      return {
        ...state,
        data: { ...action.payload },
        addData: null,
        record: null
      };

    case actions.FILL_ADD_SUPPLIER_STOCK:
      return {
        ...state,
        addData: { ...action.payload },
      };

    case actions.FILL_SUPPLIER_STOCK:
      return {
        ...state,
        record: { ...action.payload }
      };
  
    case actions.SET_ERRORS:
      return {
        ...state,
        error: action.payload
      };

    case actions.CLEAR_ERRORS:
      return { 
        ...state,
        error: null
      };

    default:
      return state;
  }
};

export default supplierStock;