import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import { Anchor } from "./Anchor"
import { getPermissionByModule } from "../../../utils/utils";
//redux stuff
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutWatcher } from '../../../redux/actionCreators/authActions';
import { translationStrings } from '../../../utils/locales';
import { history } from '../../../redux/store';
import * as constants from '../../../constants/constants';

function Header(props: any) {
  if (localStorage.localeI18nCode) {
    translationStrings.setLanguage(localStorage.localeI18nCode);
  } else {
    translationStrings.setLanguage('en');
  }
  if (localStorage.oldSessionData) {
    var previousSessionData = JSON.parse(localStorage.oldSessionData);
    var previousUsersFirstName = previousSessionData.firstName;
    var previousUsersRole = previousSessionData.role;
    if (localStorage.role == constants.MASTER_ADMIN || localStorage.role == constants.ADMIN_USER) {
      var type = constants.ADMIN;
    }
  }
  /*const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
      
      if (props.requestUIHandler.errors) {
          setErrors(props.requestUIHandler.errors);
      }
      setLoading(props.requestUIHandler.loading);
  }, [props.requestUIHandler])*/

  const handleClick = (e: any) => {
    e.preventDefault();
    props.logoutWatcher();
  }

  const backToPreviousUser = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    Object.keys(previousSessionData).forEach(key => {
      localStorage.setItem(key, previousSessionData[key]);
    });
    {
      localStorage.role && (localStorage.role == constants.MASTER_ADMIN) &&
      history.push(constants.HOME_PAGE_URLS[constants.MASTER_ADMIN]);
    }
    {
      localStorage.role && (localStorage.role == constants.ADMIN_USER) &&
      history.push(constants.HOME_PAGE_URLS[constants.ADMIN_USER]);
    }
  }

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <a href="/#" className="navbar-brand">
          <img src="../../../../../../../../images/logo_autoparts.png" />
        </a>
        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse navigationMenu" id="navbarCollapse">

          <ul className="navbar-nav">
            <li>
              <button type="button">
                <Link to={constants.URLS.ADMIN_USER_LIST}>{translationStrings.menu_lbl_for_admin_users}</Link>
              </button>
            </li>
            <li>
              <button type="button">
                <Link to={constants.URLS.SUPPLIER_LIST}>{translationStrings.menu_lbl_for_suppliers}</Link>
              </button>
            </li>
            <li>
              <button type="button">
                <Link to={constants.URLS.CUSTOMER_LIST}>{translationStrings.menu_lbl_for_customers}</Link>
              </button>
            </li>
            <li>
              <button type="button">
                <Link to={constants.URLS.SUPPLIER_STOCK_LIST}>{translationStrings.menu_lbl_for_supplier_stock}</Link>
              </button>
            </li>
            <li>
              <button type="button">
                <Link to={constants.URLS.ORDER_LIST}>{translationStrings.menu_lbl_for_order}</Link>
              </button>
            </li>
            <li>
              <button type="button">
                <Link to={constants.URLS.ACCOUNT_DEPOSIT_LIST}>{translationStrings.menu_lbl_for_account_deposit}</Link>
              </button>
            </li>
            <li className="userInfo">
              <div className="dropdown">
                <button type="button" className="dropdown-toggle" data-toggle="dropdown">
                  <p>Content</p><p>Pages</p>
                </button>
                <div className="dropdown-menu">
                  <Link to={constants.URLS.TESTIMONIALS_LIST} className="dropdown-item">{translationStrings.menu_lbl_for_testimonials}</Link>
                  <Link to={constants.URLS.FEATURES_LIST} className="dropdown-item">{translationStrings.menu_lbl_for_features}</Link>
                  <Link to={constants.URLS.LATEST_NEWS_LIST} className="dropdown-item">{translationStrings.menu_lbl_for_latest_news}</Link>
                  <Link to={constants.URLS.HOME_EDIT} className="dropdown-item">{translationStrings.menu_lbl_for_home}</Link>
                  <Link to={constants.URLS.ABOUT_US_EDIT} className="dropdown-item">{translationStrings.menu_lbl_for_about_us}</Link>
                </div>
              </div>
            </li>
            {(localStorage.role == constants.MASTER_ADMIN || localStorage.role == constants.ADMIN_USER) &&
              <li className="userInfo">
                <div className="dropdown">
                  <button type="button" className="dropdown-toggle" data-toggle="dropdown">
                    <p>{translationStrings.text_hi}, {localStorage.first_name}</p><p>{localStorage.role}</p>
                  </button>

                  <div className="dropdown-menu">
                    <Link to={constants.URLS.AUTOPARTS_EDIT_PROFILE} className="dropdown-item">{translationStrings.edit_profile_page_title}</Link>
                    <Link to={constants.URLS.CHANGE_PASSWORD} className="dropdown-item">{translationStrings.change_password_page_title}</Link>
                    <Anchor class="dropdown-item" handleClick={handleClick} label={translationStrings.menu_lbl_for_logout} />
                  </div>
                </div>
              </li>
            }
          </ul>
        </div>
      </nav>
      {localStorage.oldSessionData &&
        <p className="loginAsUser">{translationStrings.common_lbl_you_are_currently_loggedin} {localStorage.firstName} ({type}). {translationStrings.lbl_click}  <Anchor handleClick={backToPreviousUser} label={translationStrings.lbl_here} /> {translationStrings.to_login_as}  {previousUsersFirstName.charAt(0).toUpperCase() + previousUsersFirstName.slice(1)}.</p>
      }
    </header>
  )

}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
  user: state.user,
  requestUIHandler: state.requestUIHandler
});
//this map actions to our props in this functional component
/*const mapActionsToProps = {
    logOut
};*/
const mapDispatchToProps = dispatch => bindActionCreators({ logoutWatcher }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header)