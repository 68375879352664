import React, {Component} from 'react';
import { DataTable } from 'primereact/datatable';
import {Column} from 'primereact/column';

import * as constants from '../../../constants/constants';
import { translationStrings} from '../../../utils/locales';

export interface DataTableListSchema {
  value: any[] | any;
  columns: any[] | any;
  header: any;
  rows: number;
  sortField: string;
  sortOrder: number;
  globalFilter: string;
  first: number;
  page: number;
  pageCount: number;
  totalRecords: number;
  filterRecords: number;
  dtRef: any;
  pageEvent: any;
  sortEvent: any;
  rowClassName?:any;
  paginator?:any;
  responsive?:any;
  //filterEvent: any;
}

export const DataTableList = (props: DataTableListSchema) =>  {

    let dynamicColumns = props.columns.map((col,i) => {
        return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{"width":col.width,"textAlign":col.textAlign}}/>;
    });

    let first = props.first+1;
    let last = first * props.rows;
    let paginator;

    if(props.filterRecords == 0){
      first = 0;
    }
    if(last >= props.totalRecords){
      last = props.totalRecords;
    }

    if(props.paginator){
        paginator = props.paginator;
    }else{
        paginator = true;
    }

  	return (
  		<DataTable
      lazy={true} 
      resizableColumns={true}
      rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS} 
      paginator={true} 
      paginatorPosition="bottom" 
      paginatorTemplate="{FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
      currentPageReportTemplate={translationStrings.common_datatable_lbl_showing +' '+ first +' '+ translationStrings.common_datatable_lbl_to +' '+ last +' '+ translationStrings.common_datatable_lbl_of +' '+ props.totalRecords +' '+ translationStrings.common_datatable_lbl_entries} 
      emptyMessage={translationStrings.common_lbl_datatable_no_records_found}
      ref={props.dtRef}
      header={props.header} 
      value={props.value}
      sortField={props.sortField}
      sortOrder={props.sortOrder}
      first={props.first}
      rows={props.rows}
      totalRecords={props.totalRecords}
      globalFilter={props.globalFilter}
      onPage={props.pageEvent}
      onSort={props.sortEvent}
      rowClassName={props.rowClassName}
      //onFilter={props.filterEvent}
      >
  		{dynamicColumns}
  		</DataTable>
  	);
}