import React, {Component, useEffect} from "react";
import {InputTextarea} from 'primereact/inputtextarea';

interface Window {
  CKEDITOR: any;
}

declare var window: Window;

export default class TextEditor extends Component<any,any> {
  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  render() {
    return (
      <InputTextarea id={this.props.id} rows={2} cols={3} name={this.props.name} value={this.props.value? this.props.value: ''} onChange={this.props.handleChange} data-attribute-name={this.props.attrName}/>
    )
  }

  componentDidMount() {
    let configuration = {
      toolbar: "full"
    };
    if(window.CKEDITOR!==undefined){
      window.CKEDITOR.replace(this.props.id, configuration);
      window.CKEDITOR.instances[this.props.id].on("change", function () {
        let data = window.CKEDITOR.instances[this.props.id].getData();
        this.props.handleChange(data);
      }.bind(this));
    }
  }
}