import * as React from 'react';
import { TextInput, TextArea, PasswordInput, DropDown, FormButton, Label, Anchor, InputCheckbox } from "../common";
import TextEditor from '../common/form/TextEditor';
import { translationStrings} from '../../utils/locales';
import { convertLabel } from '../../utils/utils';

export default class AboutUsForm extends React.Component<any, any>{
    render(){
        const {fields, formHandler, handleCancelClick, handleEditorChange} = this.props;
        return (
          <form onSubmit={formHandler.handleSubmit}>
              <div className="row">
                <div className="form-group col-sm-6">
                  <Label forName="about_content" label={translationStrings.common_lbl_about_content} require="inline"/>
                  <TextEditor id='about_content' name='about_content' value={fields.about_content} handleChange={(e) => handleEditorChange(e, 'about_content')} attrName={fields.attrName.about_content}/>
                  <label className="error">
                    {formHandler.errors.about_content ? formHandler.errors.about_content : ""}
                  </label>
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="our_history" label={translationStrings.common_lbl_our_history} require="inline"/>
                  <TextEditor id='our_history' name='our_history' value={fields.our_history} handleChange={(e) => handleEditorChange(e, 'our_history')} attrName={fields.attrName.our_history}/>
                  <label className="error">
                    {formHandler.errors.our_history ? formHandler.errors.our_history : ""}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-6">
                  <Label forName="our_milestones" label={translationStrings.common_lbl_our_milestones} require="inline"/>
                  <TextEditor id='our_milestones' name='our_milestones' value={fields.our_milestones} handleChange={(e) => handleEditorChange(e, 'our_milestones')} attrName={fields.attrName.our_milestones}/>
                  <label className="error">
                    {formHandler.errors.our_milestones ? formHandler.errors.our_milestones : ""}
                  </label>
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="our_customer_service" label={translationStrings.common_lbl_our_customer_service} require="inline"/>
                  <TextEditor id='our_customer_service' name='our_customer_service' value={fields.our_customer_service} handleChange={(e) => handleEditorChange(e, 'our_customer_service')} attrName={fields.attrName.our_customer_service}/>
                  <label className="error">
                    {formHandler.errors.our_customer_service ? formHandler.errors.our_customer_service : ""}
                  </label>
                </div>
              </div>
              <div className="form-group text-right">
                  <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme"/>
                  <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick}/>
              </div>
          </form>
        )
    }
}