import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from "react-router-dom"

import { history } from '../../redux/store';
import ForgetPasswordForm from "./ForgetPasswordForm";
//redux stuff
import { connect } from 'react-redux';
//import { loginUser } from "../../redux/actions/userActions"
import { bindActionCreators } from 'redux';
import { forgetPwdWatcher } from '../../redux/actionCreators/authActions';
import ReactFormInputValidation from "react-form-input-validation";
import { translationStrings} from '../../utils/locales';
import * as constants from '../../constants/constants';
import { printVal} from '../../utils/utils';

class ForgetPassword extends React.Component{
    form: any;
    constructor(props) {
        super(props);
        this.state = {
            fields:{
                email: "",
                attrName:{
                    email: "Email"
                },
                errors: {}
            }
        };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            email: "required|email"
        });
        this.form.onformsubmit = (fields) => {
            const userData = {
                email: fields.email,
            };
            var response = new Promise((resolve, reject) => {
                props.forgetPwdWatcher(userData, resolve, reject);
                this.setState({
                    fields:{
                        email: "",
                        attrName:{
                            email: "Email"
                        },
                    }
                });
            }).catch(e => {
                printVal(e);
                this.setState({
                    fields:{
                        email: fields.email,
                        attrName:{
                            email: "Email"
                        },
                        // errors: {}
                    }
                });
            });
        }

        document.title = translationStrings.forgot_password;
    }

    handleClick = (e: any) => {
        e.preventDefault();
        history.push(constants.URLS.LOGIN);
    }

    render(){
        return(
            <main className="loginScreen">
                <section className="loginWrapper">
                    <div className="left-wrapper">
                        <div className="container-fluid">
                            <div className="logo-container">
                                <img src="../images/logo_autoparts.png"/>
                            </div>
                        </div>
                    </div>
                    <div className="right-wrapper">
                        <div className="container-fluid">
                            <div className="logo-container logo-container-mobile">
                                <img src="../images/logo_autoparts.png"/>
                            </div>
                            <ForgetPasswordForm {...this.state} formHandler={this.form} handleClick={this.handleClick}/>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
    user: state.user,
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators({ forgetPwdWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)