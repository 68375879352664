import React from 'react';
import { Link } from "react-router-dom"
import ReactFormInputValidation from "react-form-input-validation";
import AccountDepositForm from "./AccountDepositForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAccountDepositWatcher, getAddAccountDepositWatcher, addAccountDepositWatcher, updateAccountDepositWatcher } from '../../redux/actionCreators/accountDepositActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings} from '../../utils/locales';

class AccountDeposit extends React.Component<any,any>{
  form: any;
  constructor(props) {
    super(props);
    this.state = {
        fields:{
            id: "",
            user_id: "",
            payment_method: "",
            payment_date: "",
            payment_amount: 0,
            customerList: [],
            selectedCustomer: [],
            attrName:{
              user_id: translationStrings.common_lbl_customer,
              payment_method: translationStrings.common_lbl_payment_method,
              payment_date: translationStrings.common_lbl_payment_date,
              payment_amount: translationStrings.common_lbl_payment_amount
            },
            errors: {}
        },
        isLoaded: false,
        defaultLoadingMessage: translationStrings.default_loading_msg
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
        user_id: "required",
        payment_method: "required|max:255",
        payment_date: "required|max:255",
        payment_amount: "required|numeric"
    });
    this.form.onformsubmit = (fields: any) => {
      if(!this.state.fields.id){
          const userData = {
            user_id: fields.user_id,
            payment_method: fields.payment_method,
            payment_date: fields.payment_date,
            payment_amount: fields.payment_amount
          };
          props.addAccountDepositWatcher(userData);
      } else {
          const userData = {
            user_id: fields.user_id,
            payment_method: fields.payment_method,
            payment_date: fields.payment_date,
            payment_amount: fields.payment_amount
          };
          props.updateAccountDepositWatcher(this.state.fields.id,userData);
      }
    }
  }

  componentDidMount() {
    if(this.props.match.params.id){
      document.title = translationStrings.edit_account_deposit_page_title;
      var response = new Promise((resolve, reject) => {
         this.props.getAccountDepositWatcher(this.props.match.params.id, resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        if(this.props.accountDeposit.record){
          let {data} = this.props.accountDeposit.record;
          this.state.fields.id = data.id;
          this.state.fields.user_id = checkStringNullValue(data.user_id);
          this.state.fields.payment_method = checkStringNullValue(data.payment_method);
          let payment_date: any = new Date(data.payment_date);
          let formatDate
          if(parseInt(payment_date.getMonth()+1) < 10){
              formatDate = payment_date.getFullYear() + "-0"+ parseInt(payment_date.getMonth()+1) +"-"+ payment_date.getDate(); 
          } else {
              formatDate = payment_date.getFullYear() + "-"+ parseInt(payment_date.getMonth()+1) +"-"+ payment_date.getDate();
          }
          this.state.fields.payment_date = formatDate.toString();
          this.state.fields.payment_amount = checkStringNullValue(data.payment_amount);
          let selectedCustomerArr = [];
          let selectedIds = [];
          if(chkForUndefinedNullBlank(data.customerList)){
            this.state.fields.customerList = data.customerList;
            let customerObj={};
            customerObj['label'] = data.name;
            customerObj['value'] = data.user_id;
            selectedIds.push(data.user_id);
            selectedCustomerArr.push(customerObj);
          }
          this.state.fields.selectedCustomer = selectedCustomerArr;
          this.state.fields.user_id = selectedIds;
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
    } else {
      document.title = translationStrings.add_account_deposit_page_title;
      //this.setState({isLoaded: true});
      var response = new Promise((resolve, reject) => {
        this.props.getAddAccountDepositWatcher(resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        this.setState({isLoaded: true});
        if(this.props.accountDeposit.addData){
          let {data} = this.props.accountDeposit.addData;

          if(chkForUndefinedNullBlank(data.customer.customerList)){
            this.state.fields.customerList = data.customer.customerList;
          }
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
    }
  }

  handleSelectChange = (values) => {
      this.state.fields.selectedCustomer = values;
      let selectedIds = [];
      if(values && values.length>0){
          values.forEach(function(v){
              selectedIds.push(v.value);
          });
      }
      this.state.fields.user_id = selectedIds;
  }

  handleCancelClick = () => {
    history.goBack();
  }

  render(){
    return(
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
              { this.state && this.state.isLoaded &&
                <AccountDepositForm {...this.state} formHandler={this.form} handleCancelClick={this.handleCancelClick} handleSelectChange={this.handleSelectChange}/>
              }
              { this.state  && !this.state.isLoaded &&
                <div className="text-center">{this.state.defaultLoadingMessage}</div>
              }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  accountDeposit: state.accountDeposit,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators({ getAccountDepositWatcher, getAddAccountDepositWatcher, addAccountDepositWatcher, updateAccountDepositWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AccountDeposit)