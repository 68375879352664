import React from 'react';
import ReactFormInputValidation from "react-form-input-validation";
import CustomerForm from "./CustomerForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  getCustomerWatcher, 
  getAddCustomerWatcher, 
  addCustomerWatcher, 
  updateCustomerWatcher, 
  checkCustomerEmailWatcher, 
  checkCustomerEmailInEditWatcher } from '../../redux/actionCreators/customersActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings } from '../../utils/locales';

class Customer extends React.Component<any, any>{
  form: any;
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        company_name: "",
        company_tax_registration_number: "",
        office_phone_number: "",
        address: "",
        password: "",
        password_confirmation: "",
        supplierList: [],
        selectedSupplier: [],
        attrName: {
          first_name: translationStrings.common_lbl_first_name,
          last_name: translationStrings.common_lbl_last_name,
          email: translationStrings.common_lbl_email,
          mobile_number: translationStrings.common_lbl_contact_number,
          company_name: translationStrings.common_lbl_company_name,
          company_tax_registration_number: translationStrings.common_lbl_company_tax_reg_number,
          office_phone_number: translationStrings.common_lbl_office_contact_number,
          address: translationStrings.common_lbl_address,
          password: translationStrings.common_lbl_password,
          password_confirmation: translationStrings.common_lbl_confirm_password,
          supplier: translationStrings.add_edit_supplier_stock_field_supplier
        },
        errors: {}
      },
      arrayElem: [{
        'supplier': "",
        'discount_percentage': ""
      }],
      isLoaded: false,
      defaultLoadingMessage: translationStrings.default_loading_msg,
      newPasswordType: 'password',
      confirmPasswordType: 'password'
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
      first_name: "required|max:255",
      last_name: "required|max:255",
      email: "required|max:255",
      mobile_number: "required|max:20",
      password: "required|min:6|max:20",
      password_confirmation: "required|min:6|max:20"
    });
    this.form.onformsubmit = (fields: any) => {
      if (!this.state.fields.id) {
        const userData = {
          first_name: fields.first_name,
          last_name: fields.last_name,
          email: fields.email,
          mobile_number: fields.mobile_number,
          company_name: fields.company_name,
          company_tax_registration_number: fields.company_tax_registration_number,
          office_phone_number: fields.office_phone_number,
          address: fields.address,
          password: fields.password,
          discountList: this.state.arrayElem
        };
        props.addCustomerWatcher(userData);
      } else {
        const userData = {
          first_name: fields.first_name,
          last_name: fields.last_name,
          email: fields.email,
          mobile_number: fields.mobile_number,
          company_name: fields.company_name,
          company_tax_registration_number: fields.company_tax_registration_number,
          office_phone_number: fields.office_phone_number,
          address: fields.address,
          discountList: this.state.arrayElem
        };
        props.updateCustomerWatcher(this.state.fields.id, userData);
      }
    }

    this.form.handleNewPasswordIconClick = (e) => {
      e.preventDefault();

      if (this.state.newPasswordType === constants.PASSWORD) {
        this.setState({ newPasswordType: constants.TEXT });
      } else if (this.state.newPasswordType === constants.TEXT) {
        this.setState({ newPasswordType: constants.PASSWORD });
      }
    }
    this.form.handleConfirmPasswordIconClick = (e) => {
      e.preventDefault();

      if (this.state.confirmPasswordType === constants.PASSWORD) {
        this.setState({ confirmPasswordType: constants.TEXT });
      } else if (this.state.confirmPasswordType === constants.TEXT) {
        this.setState({ confirmPasswordType: constants.PASSWORD });
      }
    }
  }

  validateEmail = (e) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!e.target.value) {
      this.setState(prevState => {
        let fields = Object.assign({}, prevState.errors);
        if(this.state.errors){
          this.state.errors.email = translationStrings.required_email_validation_msg;
        }
        return this.state;
      });
    }
    if (e.target.name == 'email' && !this.props.match.params.id && e.target.value && re.test(String(e.target.value).toLowerCase())) {
      var response = new Promise((resolve, reject) => {
        this.props.checkCustomerEmailWatcher(e.target.value, resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        if (this.props.customers.error && this.props.customers.error.status == constants.EMAIL_ALREADY_EXISTS) {
          this.setState(prevState => {
            let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
            fields.email = '';        // update the name property, assign a new value                 
            return { fields };        // return new object fields object
          });
        }
      });
    } else if (e.target.name == 'email' && this.props.match.params.id && e.target.value && re.test(String(e.target.value).toLowerCase())) {
      var response = new Promise((resolve, reject) => {
        this.props.checkCustomerEmailInEditWatcher(e.target.value, this.props.match.params.id, resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        if (this.props.customers.error && this.props.customers.error.status == constants.EMAIL_ALREADY_EXISTS) {
          this.setState(prevState => {
            let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
            fields.email = '';        // update the name property, assign a new value                 
            return { fields };        // return new object fields object
          });
        }
      });
    }

  }

  componentDidMount() {
    if (this.props.match.params.id) {
      document.title = translationStrings.edit_customer_page_title;
      var response = new Promise((resolve, reject) => {
        this.props.getCustomerWatcher(this.props.match.params.id, resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        if (this.props.customers.record) {
          let { data } = this.props.customers.record;
          this.state.fields.id = data.id;
          this.state.fields.first_name = checkStringNullValue(data.customer_dtls.first_name);
          this.state.fields.last_name = checkStringNullValue(data.customer_dtls.last_name);
          this.state.fields.email = checkStringNullValue(data.email);
          this.state.fields.mobile_number = checkStringNullValue(data.customer_dtls.mobile_number);
          this.state.fields.company_name = checkStringNullValue(data.customer_dtls.company_name);
          this.state.fields.company_tax_registration_number = checkStringNullValue(data.customer_dtls.company_tax_registration_number);
          this.state.fields.office_phone_number = checkStringNullValue(data.customer_dtls.office_phone_number);
          this.state.fields.address = checkStringNullValue(data.customer_dtls.address);
          
          if(chkForUndefinedNullBlank(data.customer_discount)){
            let selectedDiscounts = [];
            data.customer_discount.map(function(value,key){
              let discountObj = {};
              discountObj['supplier'] = value.supplier_id;
              discountObj['discount_percentage'] = value.discount_percentage;
              selectedDiscounts.push(discountObj);
            });
            this.setState({ arrayElem: selectedDiscounts});
          }
          let selectedSupplierArr = [];
          let selectedIds = [];
          if(chkForUndefinedNullBlank(data.supplierList)){
            this.state.fields.supplierList = data.supplierList;
            let supplierObj={};
            supplierObj['label'] = data.name;
            supplierObj['value'] = data.user_id;
            selectedIds.push(data.user_id);
            selectedSupplierArr.push(supplierObj);
          }
          this.state.fields.selectedSupplier = selectedSupplierArr;
          this.state.fields.supplier = selectedIds;
          this.setState({ isLoaded: true });
        } else {
          this.setState({ isLoaded: false });
          this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
        }
      });
    } else {
      document.title = translationStrings.add_customer_page_title;
      //this.setState({isLoaded: true});
      var response = new Promise((resolve, reject) => {
        this.props.getAddCustomerWatcher(resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        this.setState({ isLoaded: true });
        if(this.props.customers.addData){
          let {data} = this.props.customers.addData;
          
          if(chkForUndefinedNullBlank(data.supplierStock.supplierList)){
            this.state.fields.supplierList = data.supplierStock.supplierList;
          }
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
    }
  }

  handleSelectChange = (e, index) => {
    //alert('test');
    /*this.state.fields.selectedSupplier = [];
    this.state.fields.supplier = [];
    this.state.fields.selectedSupplier.push(e.target.value);
    this.state.fields.supplier.push(e.target.value);
    console.log(this.state.fields.supplier);*/
  }

  handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.arrayElem];
    list[index][name] = value;
    this.setState({ arrayElem: list});
  }

  handleAddClick = () => {
    this.setState({ arrayElem: [...this.state.arrayElem, {'supplier':"", 'discount_percentage':""}]});
  }

  handleRemoveClick = index => {
    const list = [...this.state.arrayElem];
    list.splice(index, 1);
    this.setState({ arrayElem: list});
  };

  handleCancelClick = () => {
    history.goBack();
  }

  render() {
    return (
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
                {this.state && this.state.isLoaded &&
                  <CustomerForm {...this.state}
                    formHandler={this.form}
                    handleCancelClick={this.handleCancelClick}
                    validateEmail={this.validateEmail}
                    //handleSelectChange={this.handleSelectChange}
                    handleChange={this.handleChange}
                    handleAddClick={this.handleAddClick}
                    handleRemoveClick={this.handleRemoveClick}
                    arrayElem={this.state.arrayElem}
                    handleNewPasswordIconClick={this.form.handleNewPasswordIconClick}
                    handleConfirmPasswordIconClick={this.form.handleConfirmPasswordIconClick}
                  />
                }
                {this.state && !this.state.isLoaded &&
                  <div className="text-center">{this.state.defaultLoadingMessage}</div>
                }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  customers: state.customers,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getCustomerWatcher,
    getAddCustomerWatcher,
    addCustomerWatcher,
    updateCustomerWatcher,
    checkCustomerEmailWatcher,
    checkCustomerEmailInEditWatcher
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Customer)