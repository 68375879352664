import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { 
  fillSupplierStockDataTable, 
  fillAddSupplierStock, 
  fillSupplierStock, 
  setError, 
  clearError } from '../actionCreators/supplierStockActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { URLS } from '../../constants/constants';
import { translationStrings} from '../../utils/locales';
/**
* Manage Supplier Stock Operation using saga
*/

// Manage Supplier Stock API
function manageSupplierStockApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_SUPPLIER_STOCK_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageSupplierStockActionEffect is triggered
export function* manageSupplierStockActionEffect(manageSupplierStockAction) {
  let { payload, resolve, reject } = manageSupplierStockAction;
  try {
    yield put(loadUI());
    let data = yield call(manageSupplierStockApi, payload);
    yield put(fillSupplierStockDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_SUPPLIER_STOCK_WATCHER action
export function* manageSupplierStockActionWatcher() {
  yield takeLatest(actions.MANAGE_SUPPLIER_STOCK_WATCHER, manageSupplierStockActionEffect);
}

/**
* Change Supplier Stock Status Operation using saga
*/
// Change Supplier Stock Status API
function changeSupplierStockStatusApi(id, status) {
  return axios.request({
    method: 'put',
    url: '/change-status/supplier-stock/'+id
  });
}

// Saga function that handles the side effect when the changeSupplierStockStatusActionEffect is triggered
export function* changeSupplierStockStatusActionEffect(changeSupplierStockStatusAction) {
  let { payload, status, resolve, reject } = changeSupplierStockStatusAction;
  try {
    yield put(loadUI());
    let { data } = yield call(changeSupplierStockStatusApi, payload, status);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to CHANGE_SUPPLIER_STOCK_STATUS_WATCHER action
export function* changeSupplierStockStatusActionWatcher() {
  yield takeLatest(actions.CHANGE_SUPPLIER_STOCK_STATUS_WATCHER, changeSupplierStockStatusActionEffect);
}

/**
* Fetch SupplierStock Operation using saga
*/
// Fetch Supplier Stock Basic API
function fetchSupplierStockApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.SUPPLIER_STOCK_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchSupplierStockActionWatcher is triggered
export function* fetchSupplierStockActionEffect(fetchSupplierStockAction) {
  let { id, resolve, reject } = fetchSupplierStockAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchSupplierStockApi, id);
    yield put(fillSupplierStock(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_SUPPLIER_STOCK_WATCHER action
export function* fetchSupplierStockActionWatcher() {
  yield takeLatest(actions.GET_SUPPLIER_STOCK_WATCHER, fetchSupplierStockActionEffect);
}


/**
* Fetch Add Supplier Stock Operation using saga
*/
// Fetch Add Supplier Stock API
function fetchAddSupplierStockApi() {
  return axios.request({
    method: 'get',
    url: URLS.API_URL_FOR_SUPPLIER_STOCK_SUPPLIER_LIST
  });
}

// Saga function that handles the side effect when the fetchAddSupplierStockActionWatcher is triggered
export function* fetchAddSupplierStockActionEffect(fetchAddSupplierStockAction) {
  let { resolve, reject } = fetchAddSupplierStockAction;
  try {
    yield put(loadUI());
    let data = yield call(fetchAddSupplierStockApi);
    yield put(fillAddSupplierStock(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ADD_SUPPLIER_STOCK_WATCHER action
export function* fetchAddSupplierStockActionWatcher() {
  yield takeLatest(actions.GET_ADD_SUPPLIER_STOCK_WATCHER, fetchAddSupplierStockActionEffect);
}

/**
* Add Supplier Stock Operation using saga
*/
// Add Supplier Stock API
function addSupplierStockApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.SUPPLIER_STOCK_ADD,
    data: params
  });
}

// Saga function that handles the side effect when the addSupplierStockActionWatcher is triggered
export function* addSupplierStockActionEffect(addSupplierStockAction) {
  let { payload, resolve, reject } = addSupplierStockAction;
  try {
    yield put(loadUI());
    let { data } = yield call(addSupplierStockApi, payload);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve(data);
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to ADD_SUPPLIER_STOCK_WATCHER action
export function* addSupplierStockActionWatcher() {
  yield takeLatest(actions.ADD_SUPPLIER_STOCK_WATCHER, addSupplierStockActionEffect);
}


/**
* Update Supplier Stock Operation using saga
*/
// Update Supplier Stock API
function updateSupplierStockApi(id, params) {
  
  // let updateUrl: any;
  // if(editType=='basic'){
    // updateUrl: URLS.Admin_USER_ADD+'/'+id
  // } else {
  //   updateUrl: URLS.Admin_USER_ADD+'/'+id
  // }
  return axios.request({
    method: 'put',
    url: URLS.SUPPLIER_STOCK_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateSupplierStockActionWatcher is triggered
export function* updateSupplierStockActionEffect(updateSupplierStockAction) {
  let { id, payload, resolve, reject } = updateSupplierStockAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateSupplierStockApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.SUPPLIER_STOCK_LIST));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_SUPPLIER_STOCK_WATCHER action
export function* updateSupplierStockActionWatcher() {
  yield takeLatest(actions.UPDATE_SUPPLIER_STOCK_WATCHER, updateSupplierStockActionEffect);
}


/**
* Delete Supplier Stock Operation using saga
*/
// Delete Supplier Stock API
function deleteSupplierStockApi(id) {
  return axios.request({
    method: 'delete',
    url: URLS.SUPPLIER_STOCK_ADD+'/'+id,
  });
}

// Saga function that handles the side effect when the deleteSupplierStockActionWatcher is triggered
export function* deleteSupplierStockActionEffect(deleteSupplierStockAction) {
  let { id, resolve, reject } = deleteSupplierStockAction;
  try {
    yield put(loadUI());
    let { data } = yield call(deleteSupplierStockApi, id);
    yield put(clearUIError());
    yield put(clearError());
    //yield put(push('/supplier-stock/list'));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to DELETE_SUPPLIER_STOCK_WATCHER action
export function* deleteSupplierStockActionWatcher() {
  yield takeLatest(actions.DELETE_SUPPLIER_STOCK_WATCHER, deleteSupplierStockActionEffect);
}

/**
* Check duplicate supplier stock users email for Operation using saga
*/
function checkSupplierStockDuplicatePartNumberApi(id, supplierId, make) {
  return axios.request({
    method: 'get',
    url: URLS.PART_NUMBER+id+URLS.CHECK+'/'+supplierId+'/'+make,
  });
}

export function* checkSupplierStockDuplicatePartNumberActionEffect(partNumber) {
  let { id, supplierId, make, resolve, reject } = partNumber;
  try {
    yield put(loadUI());
    if(!supplierId){
      supplierId = -1;
    }
    if(!make){
      make = -1;
    }
    let { data } = yield call(checkSupplierStockDuplicatePartNumberApi, id, supplierId, make);
    yield put(clearUIError());
    
    // yield put(push('reseller/user/list'));
    // showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    
    if(e.status == 'PART_NUMBER_ALREADY_EXISTS'){
      if (reject) resolve();  
    }else{
      if (reject) reject(e);
    }
  }
}

export function* checkSupplierStockPartNumberWatcher() {
  yield takeLatest(actions.CHECK_SUPPLIER_STOCK_PART_NUMBER_WATCHER, checkSupplierStockDuplicatePartNumberActionEffect);
}

/**
* Check duplicate Supplier Stock users email for Edit Operation using saga
*/
function checkSupplierStockDuplicatePartNumberInEditApi(partNumber, stockId, supplierId, make) {
  return axios.request({
    method: 'get',
    url: URLS.PART_NUMBER+partNumber+URLS.CHECK_USER+stockId+'/'+supplierId+'/'+make,
  });
}

export function* checkSupplierStockDuplicatePartNumberInEditActionEffect(partNum) {
  let { partNumber, stockId, supplierId, make, resolve, reject } = partNum;
  try {
    yield put(loadUI());
    if(!supplierId){
      supplierId = -1;
    }
    if(!make){
      make = -1;
    }
    let { data } = yield call(checkSupplierStockDuplicatePartNumberInEditApi, partNumber, stockId, supplierId, make);
    yield put(clearUIError());
    
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    
    if(e.status == 'PART_NUMBER_ALREADY_EXISTS'){
      if (reject) resolve();  
    }else{
      if (reject) reject(e);
    }
  }
}

export function* checkSupplierStockPartNumberInEditWatcher() {
  yield takeLatest(actions.CHECK_SUPPLIER_STOCK_PART_NUMBER_IN_EDIT_WATCHER, checkSupplierStockDuplicatePartNumberInEditActionEffect);
}

/**
 * Reset User Password Operation using saga
 */
// Reset User Password API
function uploadSupplierStockApi(params) {
    return axios.request({
      method: 'post',
      url: URLS.API_URL_FOR_SUPPLIER_STOCK_UPLOAD_URL,
      data: params
    });
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* uploadSupplierStockActionEffect(uploadSupplierStockAction) {
  let { payload, resolve, reject } = uploadSupplierStockAction;

  try {
    yield put(loadUI());
    let { data } = yield call(uploadSupplierStockApi, payload);
    yield put(clearUIError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPLOAD_SUPPLIER_STOCK_WATCHER action
export function* uploadSupplierStockActionWatcher() {
  yield takeLatest(actions.UPLOAD_SUPPLIER_STOCK_WATCHER, uploadSupplierStockActionEffect);
}