import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import requestUIHandler from './requestUIHandler';
import auth from './auth';
import adminUsers from './adminUsers';
import customers from './customers';
import suppliers from './suppliers';
import supplierStock from './supplierStock';
import accountDeposit from './accountDeposit';
import accountStatement from './accountStatement';
import order from './order'
import latestNews from './latestNews';
import aboutUs from './aboutUs';
import testimonials from './testimonials';
import features from './features';
import home from './home';


const rootReducer = (history) => combineReducers({
	router: connectRouter(history),
	auth, requestUIHandler, adminUsers, customers, suppliers, supplierStock, order, accountDeposit, accountStatement, latestNews, aboutUs,
	testimonials, features, home
});

export default rootReducer;