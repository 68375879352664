import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { fillTestimonialsDataTable, fillAddTestimonials, fillTestimonials, setError, clearError } from '../actionCreators/testimonialsActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { URLS } from '../../constants/constants';
import * as constants from '../../constants/constants';
import { translationStrings} from '../../utils/locales';
/**
* Manage Testimonials Operation using saga
*/

// Manage Testimonials API
function manageTestimonialsApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_TESTIMONIALS_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageTestimonialsActionEffect is triggered
export function* manageTestimonialsActionEffect(manageTestimonialsAction) {
  let { payload, resolve, reject } = manageTestimonialsAction;
  try {
    yield put(loadUI());
    let data = yield call(manageTestimonialsApi, payload);
    yield put(fillTestimonialsDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_TESTIMONIALS_USERS_WATCHER action
export function* manageTestimonialsActionWatcher() {
  yield takeLatest(actions.MANAGE_TESTIMONIALS_WATCHER, manageTestimonialsActionEffect);
}

/**
* Change Testimonials Status Operation using saga
*/
// Change Testimonials Status API
function changeTestimonialsStatusApi(id, status) {
  return axios.request({
    method: 'put',
    url: '/change-status/testimonials/'+id
  });
}

// Saga function that handles the side effect when the changeTestimonialsStatusActionEffect is triggered
export function* changeTestimonialsStatusActionEffect(changeTestimonialsStatusAction) {
  let { payload, status, resolve, reject } = changeTestimonialsStatusAction;
  try {
    yield put(loadUI());
    let { data } = yield call(changeTestimonialsStatusApi, payload, status);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to CHANGE_TESTIMONIALS_USER_STATUS_WATCHER action
export function* changeTestimonialsStatusActionWatcher() {
  yield takeLatest(actions.CHANGE_TESTIMONIALS_STATUS_WATCHER, changeTestimonialsStatusActionEffect);
}

/**
* Fetch Testimonials Operation using saga
*/
// Fetch Testimonials Basic API
function fetchTestimonialsApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.TESTIMONIALS_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchTestimonialsActionWatcher is triggered
export function* fetchTestimonialsActionEffect(fetchTestimonialsAction) {
  let { id, resolve, reject } = fetchTestimonialsAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchTestimonialsApi, id);
    yield put(fillTestimonials(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_TESTIMONIALS_WATCHER action
export function* fetchTestimonialsActionWatcher() {
  yield takeLatest(actions.GET_TESTIMONIALS_WATCHER, fetchTestimonialsActionEffect);
}


/**
* Fetch Add Testimonials Operation using saga
*/
// Fetch Add Testimonials API
function fetchAddTestimonialsApi() {
  return axios.request({
    method: 'get',
    url: URLS.GET_TESTIMONIALS_ADD
  });
}

// Saga function that handles the side effect when the fetchAddTestimonialsActionWatcher is triggered
export function* fetchAddTestimonialsActionEffect(fetchAddTestimonialsAction) {
  let { resolve, reject } = fetchAddTestimonialsAction;
  try {
    yield put(loadUI());
    //let data = yield call(fetchAddTestimonialsApi);
    //yield put(fillAddTestimonials(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ADD_TESTIMONIALS_WATCHER action
export function* fetchAddTestimonialsActionWatcher() {
  yield takeLatest(actions.GET_ADD_TESTIMONIALS_WATCHER, fetchAddTestimonialsActionEffect);
}

/**
* Add Testimonials Operation using saga
*/
// Add Testimonials API
function addTestimonialsApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.TESTIMONIALS_ADD,
    data: params
  });
}

// Saga function that handles the side effect when the addTestimonialsActionWatcher is triggered
export function* addTestimonialsActionEffect(addTestimonialsAction) {
  let { payload, resolve, reject } = addTestimonialsAction;
  try {
    yield put(loadUI());
    let { data } = yield call(addTestimonialsApi, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(constants.URLS.TESTIMONIALS_LIST));
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve(data);
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to ADD_TESTIMONIALS_WATCHER action
export function* addTestimonialsActionWatcher() {
  yield takeLatest(actions.ADD_TESTIMONIALS_WATCHER, addTestimonialsActionEffect);
}


/**
* Update Testimonials Operation using saga
*/
// Update Testimonials API
function updateTestimonialsApi(id, params) {
  
  // let updateUrl: any;
  // if(editType=='basic'){
    // updateUrl: URLS.TESTIMONIALS_ADD+'/'+id
  // } else {
  //   updateUrl: URLS.TESTIMONIALS_ADD+'/'+id
  // }
  return axios.request({
    method: 'put',
    url: URLS.TESTIMONIALS_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateTestimonialsActionWatcher is triggered
export function* updateTestimonialsActionEffect(updateTestimonialsAction) {
  let { id, payload, resolve, reject } = updateTestimonialsAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateTestimonialsApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.TESTIMONIALS_LIST));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_TESTIMONIALS_WATCHER action
export function* updateTestimonialsActionWatcher() {
  yield takeLatest(actions.UPDATE_TESTIMONIALS_WATCHER, updateTestimonialsActionEffect);
}


/**
* Delete Testimonials Operation using saga
*/
// Delete Testimonials API
function deleteTestimonialsApi(id) {
  return axios.request({
    method: 'delete',
    url: URLS.TESTIMONIALS_ADD+'/'+id,
  });
}

// Saga function that handles the side effect when the deleteTestimonialsActionWatcher is triggered
export function* deleteTestimonialsActionEffect(deleteTestimonialsAction) {
  let { id, resolve, reject } = deleteTestimonialsAction;
  try {
    yield put(loadUI());
    let { data } = yield call(deleteTestimonialsApi, id);
    yield put(clearUIError());
    yield put(clearError());
    //yield put(push('/Testimonials/list'));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to DELETE_TESTIMONIALS_WATCHER action
export function* deleteTestimonialsActionWatcher() {
  yield takeLatest(actions.DELETE_TESTIMONIALS_WATCHER, deleteTestimonialsActionEffect);
}

