import React, {useEffect} from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Link, Redirect } from "react-router-dom";

//redux stuff
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class NotAuthorised extends React.Component<any,any>{
  constructor(props) {
    super(props);
    document.title = 'Not Authorised';
  }

  render(){
        return(
           <main className="sectionWrapper">
            <section className="container">
              <div className="sectionHeader">
                <div className="row">
                  <div className="col-sm-6">
                    <h3>Not Authorised</h3>
                  </div>
                  <div className="col-sm-6 text-right">
                  </div>
                </div>
              </div>
              <section className="chartContainer">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <h2>Error 403</h2>
                    <p>Oops! You are not authorised to access this page. Please contact administrator.</p>
                  </div>
                </div>
              </section>
            </section>
        </main>
        )
     }
}
const mapStateToProps = (state: any) => ({
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NotAuthorised)