import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { fillAccountStatementDataTable, setError, clearError } from '../actionCreators/accountStatementActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { URLS } from '../../constants/constants';
import { translationStrings} from '../../utils/locales';
/**
* Manage Account Deposit Operation using saga
*/

// Manage Account Deposit API
function manageAccountStatementApi(params, id) {
  params.id = id;
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_ACCOUNT_STATEMENT_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageAccountStatementActionEffect is triggered
export function* manageAccountStatementActionEffect(manageAccountStatementAction) {
  let { payload, id, resolve, reject } = manageAccountStatementAction;
  try {
    yield put(loadUI());
    let data = yield call(manageAccountStatementApi, payload, id);
    yield put(fillAccountStatementDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_Admin_USERS_WATCHER action
export function* manageAccountStatementActionWatcher() {
  yield takeLatest(actions.MANAGE_ACCOUNT_STATEMENT_WATCHER, manageAccountStatementActionEffect);
}