import * as React from 'react';
import { TextInput, TextArea, PasswordInput, DropDown, FormButton, Label, Anchor, MultipleSelect } from "../common";
import { translationStrings } from '../../utils/locales';

export default class OrderForm extends React.Component<any, any>{

  render() {
    const {
      fields,
      formHandler,
      handleCancelClick,
      handleSelectChange,
      handleChange,
      profitPercentage,
      orderList
    } = this.props;
    return (
      <>
      <div className="row">
        <div className="col-sm-6">
          <p style={{margin:'0px'}}><strong>OrderId: </strong>{fields.id}</p>
          <p style={{margin:'0px'}}><strong>Status: </strong>{fields.status}</p>
          <p style={{margin:'0px'}}><strong>Date&Time: </strong>{fields.date_time}</p>
          <p style={{margin:'0px'}}><strong>Sub Total: </strong>{fields.total_amount}</p>
          <p style={{margin:'0px'}}><strong>Tax: </strong>{fields.tax_amount}</p>
          <p style={{margin:'0px'}}><strong>Final Total: </strong>{fields.final_amount}</p>
        </div>
        <div className="col-sm-6">
          <p style={{margin:'0px'}}><strong>Customer Id: </strong>{fields.customerDtls.id}</p>
          <p style={{margin:'0px'}}><strong>Customer Name: </strong>{fields.customerDtls.first_name} {fields.customerDtls.last_name}</p>
        </div>
      </div>
      <form onSubmit={formHandler.handleSubmit}>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="status" label={translationStrings.common_lbl_order_status} require="inline" />
            <select name="status" className="form-control" onBlur={formHandler.handleBlurEvent} onChange={formHandler.handleChangeEvent} data-attribute-name={fields.attrName.status} defaultValue={fields.status}>
              <option value="">Select</option>
              <option value="PENDING">Pending</option>
              <option value="PROCESSED">Processed</option>
              <option value="COMPLETED">Completed</option>
              <option value="CANCELLED">Cancelled</option>
              <option value="RETURNED">Returned</option>
            </select>
            <label className="error">
              {formHandler.errors.status ? formHandler.errors.status : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="invoice_no" label={translationStrings.common_lbl_invoice_no} />
            <TextInput
              class="form-control"
              name="invoice_no"
              type="text"
              placeholder={translationStrings.common_lbl_invoice_no}
              handleBlur={formHandler.handleBlurEvent}
              handleChange={formHandler.handleChangeEvent}
              value={fields.invoice_no}
              attrName={fields.attrName.invoice_no}
            />
            <label className="error">
              {formHandler.errors.invoice_no ? formHandler.errors.invoice_no : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="total_amount" label={translationStrings.common_lbl_total_amount} require="inline" />
            <TextInput
              disabled={true}
              class="form-control"
              name="total_amount"
              type="text"
              placeholder={translationStrings.common_lbl_total_amount}
              handleBlur={formHandler.handleBlurEvent}
              handleChange={formHandler.handleChangeEvent}
              value={fields.total_amount}
              attrName={fields.attrName.total_amount}
            />
            <label className="error">
              {formHandler.errors.total_amount ? formHandler.errors.total_amount : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="tax_amount" label={translationStrings.common_lbl_tax_amount} require="inline" />
            <TextInput
              disabled={true}
              class="form-control"
              name="tax_amount"
              type="text"
              placeholder={translationStrings.common_lbl_tax_amount}
              handleBlur={formHandler.handleBlurEvent}
              handleChange={formHandler.handleChangeEvent}
              value={fields.tax_amount}
              attrName={fields.attrName.tax_amount}
            />
            <label className="error">
              {formHandler.errors.tax_amount ? formHandler.errors.tax_amount : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="final_amount" label={translationStrings.common_lbl_final_amount} require="inline" />
            <TextInput
              disabled={true}
              class="form-control"
              name="final_amount"
              type="text"
              placeholder={translationStrings.common_lbl_final_amount}
              handleBlur={formHandler.handleBlurEvent}
              handleChange={formHandler.handleChangeEvent}
              value={fields.final_amount}
              attrName={fields.attrName.final_amount}
            />
            <label className="error">
              {formHandler.errors.final_amount ? formHandler.errors.final_amount : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <table className="table">
            <thead>
              <tr>
                <td>ID</td>
                <td>Supplier</td>
                <td>Part Number</td>
                <td>Make</td>
                <td>Description</td>
                <td>Weight</td>
                <td>Unit Price</td>
                <td>Qty</td>
                <td>Cancelled Qty</td>
                <td>Available Qty</td>
                <td>Total Price</td>
              </tr>
            </thead>
            <tbody>
              {orderList.map((x, i) => {
                return(
                <tr key={i}>
                  <td>{x.id}</td>
                  <td>{x.supplier_code_name}</td>
                  <td>{x.part_number}</td>
                  <td>{x.make}</td>
                  <td>{x.description}</td>
                  <td>{x.weight}</td>
                  <td>{x.final_unit_price}</td>
                  <td>{x.order_qty}</td>
                  <td>
                    <TextInput
                      readOnly={fields.status=='PENDING' ? false : true}
                      class="form-control"
                      name="cancelled_qty"
                      type="number"
                      min={0}
                      placeholder={translationStrings.common_lbl_cancelled_qty}
                      handleBlur={formHandler.handleBlurEvent}
                      handleChange={e => handleChange(e,i, x.order_qty)}
                      value={x.cancelled_qty}
                      attrName={fields.attrName.cancelled_qty}
                    />
                  </td>
                  <td>
                    <TextInput
                      readOnly={true}
                      class="form-control"
                      name="available_qty"
                      type="number"
                      min={0}
                      placeholder={translationStrings.common_lbl_available_qty}
                      handleBlur={formHandler.handleBlurEvent}
                      handleChange={e => handleChange(e,i, x.order_qty)}
                      value={x.available_qty}
                      attrName={fields.attrName.available_qty}
                    />
                  </td>
                  <td>{x.total_price}</td>
                </tr>
               );
              })}
            </tbody>
          </table>
        </div>
        <div className="form-group text-right">
          <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme" />
          <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick} />
        </div>
      </form>
      </>
    )
  }
}