import React, { Component }  from 'react';
import { NotificationManager } from 'react-notifications';
import { store as notificationStore } from 'react-notifications-component';

import { Notification } from "../components/common/layout/Notification";
import * as constants from '../constants/constants';
import { history } from '../redux/store';
import { translationStrings} from './locales';

require("dotenv").config()

const getPermissionByModule = (moduleName: string, type: string) => {
    let permissionData = localStorage.permissions ? JSON.parse(localStorage.permissions) : [];
    let filterData = [];
    filterData = permissionData.filter(function(data){
        if(data.module === moduleName && data.permission_type === type && data.is_active===1){
            return data;
        }
    });
    if(filterData.length > 0){
        return true;
    } else {
        return false;
    }
}

/**
*  CHECK FOR UNDEFINED NULL BLANK
*  @param {string} value : message desription
*  @returns {boolean} : true or false
*/
const chkForUndefinedNullBlank = (value: any) => {
    if (typeof value !== 'undefined' && value && value.length > 0) {
        return true;
    } else {
        return false;
    }
}

/**
*  CHECK FOR STRING NULL BLANK
*  @param {string} value : message desription
*  @returns {boolean} : true or false
*/
const checkStringNullValue = (value: any) => {
    return ((value + '').replace('null', ''));
}

const printVal = (value:any, text:any=null) =>{
    if(process.env.NODE_ENV==constants.DEVELOPMENT){
        return console.log(text, value);    
    }
    
}

const downloadExportFile = (filedata:any, filename:any) =>{
    if(filedata){
        const url = window.URL.createObjectURL(new Blob([filedata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }
    
}

const changeString = (messageParam) => {
    return (
          <p
            dangerouslySetInnerHTML={{__html: messageParam}} 
          />
        );
}

const convertLabel = (value) => {
    value = value.replace(/[^a-zA-Z ]/g, " ");
    return value.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
}

const showNotification = (type, title, message?: any, isObj?: false | boolean) => {
    let messageParam: any;
    let notification = {
        title: '',
        message: message,
        type: ''
    };
    if(isObj){
        messageParam = Notification(message);
        notification = {
            title: translationStrings.validation_error,
            message: messageParam,
            type: type
        };
    } else {
        messageParam = message
        var new_string = changeString(messageParam);
        notification = {
            title: title,
            message: new_string,
            type: type
        };
    }
    notificationStore.addNotification({
        ...notification,
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            showIcon: true
        }
    })
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'AED',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    //second: '2-digit'
});

export {
    getPermissionByModule,
    showNotification,
    checkStringNullValue,
    chkForUndefinedNullBlank,
    printVal,
    downloadExportFile,
    currencyFormatter,
    dateFormatter,
    convertLabel
}