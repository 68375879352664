import * as React from 'react';
import { TextInput, TextArea, PasswordInput, FormButton, Label, DropDown, Anchor } from "../common";
import { translationStrings} from '../../utils/locales';

export default class ProfileForm extends React.Component<any, any>{
  
  render(){
    const {fields, formHandler, handleClick} = this.props;
    return (
      <form onSubmit={formHandler.handleSubmit}>
          <div className="row">
                <div className="form-group col-sm-6">
                  <Label forName="first_name" label={translationStrings.common_lbl_first_name} require="inline"/>
                  <TextInput class="form-control" name="first_name" type="text" placeholder={translationStrings.common_lbl_first_name} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.first_name} attrName={fields.attrName.first_name}/>
                  <label className="error">
                    {formHandler.errors.first_name ? formHandler.errors.first_name : ""}
                  </label>
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="last_name" label={translationStrings.common_lbl_last_name} require="inline"/>
                  <TextInput class="form-control" name="last_name" type="text" placeholder={translationStrings.common_lbl_last_name} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.last_name} attrName={fields.attrName.last_name}/>
                  <label className="error">
                    {formHandler.errors.last_name ? formHandler.errors.last_name : ""}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-6">
                  <Label forName="email" label={translationStrings.common_lbl_email} require="inline"/>
                  <TextInput class="form-control" name="email" type="text" placeholder={translationStrings.common_lbl_email} handleChange={formHandler.handleChangeEvent} value={fields.email} attrName={fields.attrName.email} disabled={true}/>
                {!fields.email && 
                  <label className="error">
                    {formHandler.errors.email ? formHandler.errors.email : ""}
                  </label>
                }
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="mobile_number" label={translationStrings.common_lbl_contact_number} require="inline"/>
                  <TextInput class="form-control" name="mobile_number" type="text" placeholder={translationStrings.common_lbl_contact_number} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.mobile_number} attrName={fields.attrName.mobile_number}/>
                  <label className="error">
                    {formHandler.errors.mobile_number ? formHandler.errors.mobile_number : ""}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-6">
                  <Label forName="company_name" label={translationStrings.common_lbl_company_name}/>
                  <TextInput class="form-control" name="company_name" type="text" placeholder={translationStrings.common_lbl_company_name} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.company_name} attrName={fields.attrName.company_name}/>
                  <label className="error">
                    {formHandler.errors.company_name ? formHandler.errors.company_name : ""}
                  </label>
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="company_tax_registration_number" label={translationStrings.common_lbl_company_tax_reg_number}/>
                  <TextInput class="form-control" name="company_tax_registration_number" type="text" placeholder={translationStrings.common_lbl_company_tax_reg_number} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.company_tax_registration_number} attrName={fields.attrName.company_tax_registration_number}/>
                  <label className="error">
                    {formHandler.errors.company_tax_registration_number ? formHandler.errors.company_tax_registration_number : ""}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-6">
                  <Label forName="office_phone_number" label={translationStrings.common_lbl_office_contact_number}/>
                  <TextInput class="form-control" name="office_phone_number" type="text" placeholder={translationStrings.common_lbl_office_contact_number} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.office_phone_number} attrName={fields.attrName.office_phone_number}/>
                  <label className="error">
                    {formHandler.errors.office_phone_number ? formHandler.errors.office_phone_number : ""}
                  </label>
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="address" label={translationStrings.common_lbl_address}/>
                  <TextArea class="form-control" name="address" placeholder={translationStrings.common_lbl_address} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.address} attrName={fields.attrName.address}/>
                  <label className="error">
                    {formHandler.errors.address ? formHandler.errors.address : ""}
                  </label>
                </div>
              </div>
          <div className="form-group text-right">
              <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme"/>
          </div>
      </form>
    )
  }
}