import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Route, Redirect, Link } from 'react-router-dom'
import { history } from '../../redux/store';
import LoginForm from "./LoginForm";
//redux stuff
import { connect } from 'react-redux';
//import { loginUser } from "../../redux/actions/userActions"
import { bindActionCreators } from 'redux';
import { loginWatcher } from '../../redux/actionCreators/authActions';
import ReactFormInputValidation from "react-form-input-validation";
import { translationStrings} from '../../utils/locales';
import * as constants from '../../constants/constants';
import { printVal} from '../../utils/utils';

interface LoginSchema {
    confirmPasswordType: string,
    fields:any,
    location:any,
}

class Login extends React.Component<any, LoginSchema>{
    form: any;
    returnUrl:any;
    constructor(props) {
        super(props);
        this.state = {
            fields:{
                email: "",
                password: "",
                attrName:{
                    email: "Email",
                    password: "Password"
                },
                errors: {}
            },
            confirmPasswordType: 'password',
            location:""
        };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            email: "required|email",
            password: "required|min:6|max:20",
        });
        this.getParameterByName('returnUrl');
        
        this.form.onformsubmit = (fields) => {
            var response = new Promise((resolve, reject) => {
                const userData = {
                    email: fields.email,
                    password: fields.password
                };
                props.loginWatcher(userData, resolve, reject);

            }).catch(e => {
            });
            response.then(() => {
                if(this.props.user.user!== null && this.props.user.user.status==constants.SUCCESS){
                    printVal(this.props.user.user.data.role, 'Role');
                    if(this.returnUrl && this.returnUrl!=''){
                    history.push(atob(this.returnUrl));
                    }else if(this.props.user.user.data.firstTimeLogin){
                      history.push(constants.URLS.CHANGE_PASSWORD);
                    } else if(this.props.user.user.data.role==constants.MASTER_ADMIN) {
                      history.push(constants.HOME_PAGE_URLS.MASTER_ADMIN);
                    } else if(this.props.user.user.data.role==constants.ADMIN_USER) {
                      history.push(constants.HOME_PAGE_URLS.ADMIN_USER);
                    }
                }else{
                    printVal(this.props.user.error, 'error');
                }
            });
        }

        this.form.handleConfirmPasswordIconClick = (e) => {
            e.preventDefault();
            if(this.state.confirmPasswordType==='password'){
                this.setState({confirmPasswordType : 'text'});
            }else if(this.state.confirmPasswordType==='text'){
                this.setState({confirmPasswordType : 'password'});
            }
        }
        document.title = translationStrings.login_text;
    }

    getParameterByName = (name) => {
        var url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        this.returnUrl = decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    handleForgetPwdClick = (e: any) => {
        e.preventDefault();
        history.push(constants.URLS.FORGOT_PASSWORD);
    }

    render(){
       
        return(
            <main className="loginScreen">
                <section className="loginWrapper">
                    <div className="left-wrapper">
                        <div className="container-fluid">
                            <div className="logo-container">
                                <img src="../images/logo_autoparts.png"/>
                            </div>
                        </div>
                    </div>
                    <div className="right-wrapper">
                        <div className="container-fluid">
                            <div className="logo-container logo-container-mobile">
                                <img src="../images/logo_autoparts.png"/>
                            </div>
                            <LoginForm {...this.state} formHandler={this.form} handleForgetPwdClick={this.handleForgetPwdClick} handleConfirmPasswordIconClick={this.form.handleConfirmPasswordIconClick}/>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
    user: state.auth,
    requestUIHandler: state.requestUIHandler
});
//this map actions to our props in this functional component
/*const mapActionsToProps = {
    loginUser
};*/
const mapDispatchToProps = dispatch => bindActionCreators({ loginWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Login)