import * as React from 'react';
import { TextInput, TextArea, PasswordInput, DropDown, FormButton, Label, Anchor, InputCheckbox } from "../common";
import TextEditor from '../common/form/TextEditor';
import { translationStrings} from '../../utils/locales';
import { convertLabel } from '../../utils/utils';

export default class FeaturesForm extends React.Component<any, any>{
    render(){
        const {fields, formHandler, handleCancelClick, handleEditorChange, fileError} = this.props;
        return (
          <form onSubmit={formHandler.handleSubmit} encType="multipart/form-data">
              <div className="row">
                <div className="form-group col-sm-6">
                  <img src={fields.feature_image ? fields.feature_image: fields.default_feature_image} width={100}/>
                  <Label forName="news_image" label={translationStrings.common_lbl_feature_image}/>
                  <input type="file" name="feature_image" onChange={formHandler.handleChangeEvent} data-attribute-name={fields.attrName.feature_image}/>  
                  {fileError &&
                    <label className="error">
                      {fileError}
                    </label>
                  }
                  <Label forName="feature_title" label={translationStrings.common_lbl_feature_title} require="inline"/>
                  <TextInput class="form-control" name="feature_title" type="text" placeholder={translationStrings.common_lbl_feature_title} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.feature_title} attrName={fields.attrName.feature_title}/>
                  <label className="error">
                    {formHandler.errors.feature_title ? formHandler.errors.feature_title : ""}
                  </label>
                </div>
                <div className="form-group col-sm-6">
                  <Label forName="feature_text" label={translationStrings.common_lbl_feature_text} require="inline"/>
                  <TextArea class="form-control" name="feature_text" placeholder={translationStrings.common_lbl_feature_text} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.feature_text} attrName={fields.attrName.feature_text}/>
                  <label className="error">
                    {formHandler.errors.feature_text ? formHandler.errors.feature_text : ""}
                  </label>
                </div>
              </div>
              <div className="form-group text-right">
                  <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme"/>
                  <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick}/>
              </div>
          </form>
        )
    }
}