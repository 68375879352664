import * as actions from '../types';

// Worker triggering actionCreators
export function manageOrderWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_ORDER_WATCHER, payload: payload, resolve, reject};
}
export function changeOrderStatusWatcher(payload, status, resolve, reject) {
  return { type: actions.CHANGE_ORDER_STATUS_WATCHER, payload: payload, status: status, resolve, reject};
}
export function getOrderWatcher(id, resolve, reject) {
  return { type: actions.GET_ORDER_WATCHER, id: id, resolve, reject};
}
export function getAddOrderWatcher(resolve, reject) {
  return { type: actions.GET_ADD_ORDER_WATCHER, resolve, reject}; 
}
export function addOrderWatcher(payload, resolve, reject) {
  return { type: actions.ADD_ORDER_WATCHER, payload: payload, resolve, reject };   
}
export function updateOrderWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_ORDER_WATCHER, id: id, payload: payload, resolve, reject };    
}
export function deleteOrderWatcher(id, resolve, reject) {
  return { type: actions.DELETE_ORDER_WATCHER, id: id, resolve, reject };  
}

// Redux state changing actionCreators
export function fillOrderDataTable(payload) {
  return { type: actions.FILL_ORDER_DATATABLE, payload: payload };
}
export function fillAddOrder(payload) {
  return { type: actions.FILL_ADD_ORDER, payload: payload };
}
export function fillOrder(payload) {
  return { type: actions.FILL_ORDER, payload: payload};
}
export function setError(error) {
  return { type: actions.SET_ORDER_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_ORDER_ERRORS, payload: null };
}