import * as React from 'react';
import { TextInput, PasswordInput, FormButton, Label, Anchor } from "../common";
import { translationStrings} from '../../utils/locales';

export default class LoginForm extends React.Component<any, any>{
  render(){
    const {fields, formHandler, handleForgetPwdClick, handleConfirmPasswordIconClick} = this.props;
    return (
      <form onSubmit={formHandler.handleSubmit}>
          <p>Welcome to Parts Next</p>
          <div className="form-group">
              <Label forName="email" label={translationStrings.common_lbl_email} require="inline"/>
              <TextInput class="form-control" name="email" type="text" placeholder={translationStrings.common_lbl_email} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.email} attrName={fields.attrName.email}/>
              <label className="error">
                {formHandler.errors.email ? formHandler.errors.email : ""}
              </label>
          </div>
          <div className="form-group">
              <Label forName="password" label={translationStrings.common_lbl_password} require="inline"/>
              <TextInput class="form-control" name="password" type={this.props.confirmPasswordType} placeholder={translationStrings.common_lbl_password} handleChange={formHandler.handleChangeEvent} handleBlur={formHandler.handleBlurEvent} value={fields.password} attrName={fields.attrName.password}/>
              <Anchor class="eye-icon"  label={(this.props.confirmPasswordType=='password')?<i className="fa fa-eye icon-theme"/>:<i className="fa fa-eye-slash icon-theme"/>} handleClick={(e) => formHandler.handleConfirmPasswordIconClick(e)} title={(this.props.confirmPasswordType=='password')?translationStrings.common_lbl_show:translationStrings.common_lbl_hide}/>
              <label className="error">
                {formHandler.errors.password ? formHandler.errors.password : ""}
              </label>
          </div>
          <div className="form-group">
              <FormButton type="submit" label={translationStrings.login_text} class="btn btn-theme full-width-button"/>
          </div>
          <Anchor class="forgetLink" label={translationStrings.forgot_password_login_text} handleClick={handleForgetPwdClick}/>
      </form>
    )
  }
}