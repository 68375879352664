import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Header from "../components/common/layout/Header";
import * as constants from '../constants/constants';
import { getPermissionByModule } from "../utils/utils";

export default ({component: Component, ...rest}) => (
    <Route {...rest} component={(props) => (
        localStorage.accessToken ? (
            getPermissionByModule(rest.moduleName, rest.permissionType) || rest.validFor ? (
            <>
                <Header/>
                <Component {...props}/>
            </>) : (<Redirect to={constants.URLS.NOT_AUTHORISED} />)
        ) : (
                <Redirect to={constants.URLS.LOGIN} />
            )
        )} />
)