import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default ({component: Component, ...rest}) => (
    <Route {...rest} component={(props) => (
        localStorage.accessToken && rest.validFor ? (
            <Redirect
			  to={{
			    pathname: "/",
			    state: {from: props.location }
			  }}
			/>

        ) : (
            <Component {...props}/>
            )
        )} />
);