import * as actions from '../types'
const initialState = {
	loading: false,
	errors: null
}

const requestUIHandler = (state = initialState, action:any) => {
	switch (action.type) {
		case actions.SET_ERRORS:
			return {
				...state,
				loading: false,
				errors: action.payload
			};
		case actions.CLEAR_ERRORS:
			return {
				...state,
				loading: false,
				errors: null
			};
		case actions.LOADING_UI:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
};

export default requestUIHandler;