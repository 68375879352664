import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from "react-router-dom"
import { Anchor } from "../common";
import { history } from '../../redux/store';
import ResetPasswordForm from "./ResetPasswordForm";
//redux stuff
import { connect } from 'react-redux';
//import { loginUser } from "../../redux/actions/userActions"
import { bindActionCreators } from 'redux';
import { checkValidTokenWatcher, resetPwdWatcher } from '../../redux/actionCreators/authActions';
import ReactFormInputValidation from "react-form-input-validation";
import * as constants from '../../constants/constants';

class ResetPassword extends React.Component<any,any>{
    form: any;
    invalidErrorMsg:any;
    expiredErrorMsg:any;
    constructor(props) {
        super(props);
        //props.match.params.token
        this.state = {
            fields:{
                password: "",
                password_confirmation: "",
                errors: {},
                attrName:{
                    password: "Password",
                    password_confirmation: "Confirm Password"
                }
            },
            isLoaded: false,
            isInValid: false,
            isExpired: false,
            newPasswordType: 'password',
            confirmPasswordType: 'password',
        };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            password: "required|min:6|max:20",
            password_confirmation: "required|min:6|max:20|matchPassword:password",
            forgotPwdToken: "required"
        });

        let formFields: any;

        ReactFormInputValidation.register('matchPassword', function(value, requirement, attribute){
            return value === formFields[requirement];
        }, 'New Password and Confirm New Password does not match.');

        this.form.handleChangeEvent = (e) => {
            const target : any = e.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            this.setState({ fields: { ...this.state.fields, [name]: value} });
            formFields = this.state.fields;
            formFields[name] = value;
        }

        this.form.onformsubmit = (fields) => {
            const userData = {
                newPwd: fields.password,
                confirmNewPwd: fields.password_confirmation,
                id: this.props.match.params.id,
                forgotPwdToken:this.props.match.params.token
            };
            props.resetPwdWatcher(userData);
        }

        this.form.handleNewPasswordIconClick = (e) => {
            e.preventDefault();
            if(this.state.newPasswordType==='password'){
                this.setState({newPasswordType : 'text'});
            }else if(this.state.newPasswordType==='text'){
                this.setState({newPasswordType : 'password'});
            }
        }
        this.form.handleConfirmPasswordIconClick = (e) => {
            e.preventDefault();
            if(this.state.confirmPasswordType==='password'){
                this.setState({confirmPasswordType : 'text'});
            }else if(this.state.confirmPasswordType==='text'){
                this.setState({confirmPasswordType : 'password'});
            }
        }

        document.title = 'Reset Password';
    }

    componentDidMount() {
        var response = new Promise((resolve, reject) => {
            this.props.checkValidTokenWatcher(this.props.match.params.id, this.props.match.params.token, resolve, reject);
            //resolve();
        }).catch(e => {
          // Handle error
        });
        response.then(() => {
            this.setState({isLoaded: true});
            if(this.props.user.error && this.props.user.error.status=='INVALID_TOKEN'){
                this.invalidErrorMsg = this.props.user.error.message;
                this.setState({isInValid: true});
            } else if(this.props.user.error && this.props.user.error.status=='EXPIRED_TOKEN'){
                this.expiredErrorMsg = this.props.user.error.message;
                this.setState({isExpired: true});
            }
        });
    }

    handleClick = (e: any) => {
        e.preventDefault();
        history.push(constants.URLS.LOGIN);
    }

    render(){
        return(
            <main className="loginScreen">
                { this.state && this.state.isLoaded && !this.state.isExpired && !this.state.isInValid &&
                    <section className="loginWrapper">
                        <div className="left-wrapper">
                            <div className="container-fluid">
                                <div className="logo-container">
                                    <img src="../../images/logo_autoparts.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="right-wrapper">
                            <div className="container-fluid">
                                <div className="logo-container logo-container-mobile">
                                    <img src="../images/logo_autoparts.png"/>
                                </div>
                                <ResetPasswordForm {...this.state} formHandler={this.form} handleClick={this.handleClick} handleNewPasswordIconClick={this.form.handleNewPasswordIconClick} handleConfirmPasswordIconClick={this.form.handleConfirmPasswordIconClick}/>
                            </div>
                        </div>
                    </section>
                }
                { this.state && this.state.isLoaded && this.state.isExpired && !this.state.isInValid &&
                    <section className="loginWrapper">
                        <div className="center-wrapper">
                          <div className="logo-container">
                            <img src="../images/logo_autoparts.png"/>
                          </div>
                          <div className="form-wrapper">
                            <div className="iconHolder bottomSpaceSmall">
                              <img src="../images/timeOut.png" className="iconMedium alignCenter"/>
                            </div>
                            <h3 className="centerText">Expired Link !</h3>
                            <p className="centerText bottomSpaceSmall">{this.expiredErrorMsg}</p>
                            <hr className="dashedLine"/>
                            <Anchor class="expireLink centerText" label="Go to Login" handleClick={this.handleClick} title="Go to Login"/>
                          </div>
                        </div>
                    </section>
                }
                { this.state && this.state.isLoaded && !this.state.isExpired && this.state.isInValid &&
                    <div className="center-wrapper">
                      <div className="logo-container">
                        <img src="../images/logo_autoparts.png"/>
                      </div>
                      <div className="form-wrapper">
                        <div className="iconHolder bottomSpaceSmall">
                          <img src="../images/banned.png" className="iconMedium alignCenter"/>
                        </div>
                        <h3 className="centerText">Request Invalid !</h3>
                        <p className="centerText bottomSpaceSmall">{this.invalidErrorMsg}</p>
                        <hr className="dashedLine"/>
                        <Anchor class="expireLink centerText" label="Go to Login" handleClick={this.handleClick} title="Go to Login"/>
                      </div>
                    </div>
                }
            </main>
        )
    }
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
    user: state.auth,
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators({ checkValidTokenWatcher, resetPwdWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)