import * as actions from '../types';


export function getHomeWatcher(id, resolve, reject) {
  return { type: actions.GET_HOME_WATCHER, id: id, resolve, reject};
}

export function updateHomeWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_HOME_WATCHER, id: id, payload: payload, resolve, reject };    
}

export function fillHome(payload) {
  return { type: actions.FILL_HOME, payload: payload};
}

export function setError(error) {
  return { type: actions.SET_HOME_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_HOME_ERRORS, payload: null };
}