import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {handleServerError, handleCustomError} from "./errorHandler";
import * as constants from '../constants/constants';

// export const download_file_url = "http://127.0.0.1:8000/storage/";
export const download_file_url = "https://api.injazauto.com/storage/";
//export const download_file_url = "http://devautoparts-api.56-north.com/storage/";

// export const base_url = "http://127.0.0.1:8000/api/admin/";
export const base_url = "https://api.injazauto.com/api/admin/";
//export const base_url = "http://devautoparts-api.56-north.com/api/admin/";

const Axios =  axios.create({
    baseURL: base_url,
    //timeout: 1000 * 10
});

// Add a request interceptor
Axios.interceptors.request.use((config: AxiosRequestConfig) =>  {
  config.headers['x-auth-token'] = `${localStorage.accessToken}`;
  config.headers['Accept-Language'] = localStorage.localeUtf8Code ? localStorage.localeUtf8Code: 'en-US';
  return config;
}, (error: any) =>  {
  return Promise.reject(error);
});

// Add a response interceptor
Axios.interceptors.response.use( (response: AxiosResponse) => {
  var urlTest = response.config.url.search("valResetPwdReqData");
  var downloadFileUrl = response.config.url.search("downloadExportFile");
  if(downloadFileUrl>-1){
    return response;
  }
  if(response.data.status!=constants.SUCCESS){
    if(urlTest==-1){
      handleCustomError(response);
    }
    return Promise.reject(response.data);
  } else {
    return response;
  }
}, (error: AxiosError) => {
  handleServerError(error);
  return Promise.reject(error.response);
});

export default Axios;