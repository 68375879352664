import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { fillAdminUsersDataTable, fillAddAdminUser, fillAdminUser, setError, clearError } from '../actionCreators/adminUsersActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { URLS } from '../../constants/constants';
import { translationStrings} from '../../utils/locales';
/**
* Manage AdminUsers Operation using saga
*/

// Manage AdminUsers API
function manageAdminUsersApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_ADMIN_USER_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageAdminUsersActionEffect is triggered
export function* manageAdminUsersActionEffect(manageAdminUsersAction) {
  let { payload, resolve, reject } = manageAdminUsersAction;
  try {
    yield put(loadUI());
    let data = yield call(manageAdminUsersApi, payload);
    yield put(fillAdminUsersDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_Admin_USERS_WATCHER action
export function* manageAdminUsersActionWatcher() {
  yield takeLatest(actions.MANAGE_ADMIN_USERS_WATCHER, manageAdminUsersActionEffect);
}

/**
* Change AdminUser Status Operation using saga
*/
// Change AdminUser Status API
function changeAdminUserStatusApi(id, status) {
  return axios.request({
    method: 'put',
    url: '/change-status/adminUser/'+id
  });
}

// Saga function that handles the side effect when the changeAdminUserStatusActionEffect is triggered
export function* changeAdminUserStatusActionEffect(changeAdminUserStatusAction) {
  let { payload, status, resolve, reject } = changeAdminUserStatusAction;
  try {
    yield put(loadUI());
    let { data } = yield call(changeAdminUserStatusApi, payload, status);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to CHANGE_Admin_USER_STATUS_WATCHER action
export function* changeAdminUserStatusActionWatcher() {
  yield takeLatest(actions.CHANGE_ADMIN_USER_STATUS_WATCHER, changeAdminUserStatusActionEffect);
}

/**
* Fetch AdminUser Operation using saga
*/
// Fetch AdminUser Basic API
function fetchAdminUserApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.ADMIN_USER_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchAdminUserActionWatcher is triggered
export function* fetchAdminUserActionEffect(fetchAdminUserAction) {
  let { id, resolve, reject } = fetchAdminUserAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchAdminUserApi, id);
    yield put(fillAdminUser(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_Admin_USER_WATCHER action
export function* fetchAdminUserActionWatcher() {
  yield takeLatest(actions.GET_ADMIN_USER_WATCHER, fetchAdminUserActionEffect);
}


/**
* Fetch Add AdminUser Operation using saga
*/
// Fetch Add AdminUser API
function fetchAddAdminUserApi() {
  return axios.request({
    method: 'get',
    url: URLS.GET_ADMIN_USER_ADD
  });
}

// Saga function that handles the side effect when the fetchAddAdminUserActionWatcher is triggered
export function* fetchAddAdminUserActionEffect(fetchAddAdminUserAction) {
  let { resolve, reject } = fetchAddAdminUserAction;
  try {
    yield put(loadUI());
    //let data = yield call(fetchAddAdminUserApi);
    //yield put(fillAddAdminUser(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ADD_ADMIN_USER_WATCHER action
export function* fetchAddAdminUserActionWatcher() {
  yield takeLatest(actions.GET_ADD_ADMIN_USER_WATCHER, fetchAddAdminUserActionEffect);
}

/**
* Add AdminUser Operation using saga
*/
// Add AdminUser API
function addAdminUserApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.ADMIN_USER_ADD,
    data: params
  });
}

// Saga function that handles the side effect when the addAdminUserActionWatcher is triggered
export function* addAdminUserActionEffect(addAdminUserAction) {
  let { payload, resolve, reject } = addAdminUserAction;
  try {
    yield put(loadUI());
    let { data } = yield call(addAdminUserApi, payload);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve(data);
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to ADD_Admin_USER_WATCHER action
export function* addAdminUserActionWatcher() {
  yield takeLatest(actions.ADD_ADMIN_USER_WATCHER, addAdminUserActionEffect);
}


/**
* Update AdminUser Operation using saga
*/
// Update AdminUser API
function updateAdminUserApi(id, params) {
  
  // let updateUrl: any;
  // if(editType=='basic'){
    // updateUrl: URLS.Admin_USER_ADD+'/'+id
  // } else {
  //   updateUrl: URLS.Admin_USER_ADD+'/'+id
  // }
  return axios.request({
    method: 'put',
    url: URLS.ADMIN_USER_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateAdminUserActionWatcher is triggered
export function* updateAdminUserActionEffect(updateAdminUserAction) {
  let { id, payload, resolve, reject } = updateAdminUserAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateAdminUserApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.ADMIN_USER_LIST));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_Admin_USER_WATCHER action
export function* updateAdminUserActionWatcher() {
  yield takeLatest(actions.UPDATE_ADMIN_USER_WATCHER, updateAdminUserActionEffect);
}


/**
* Delete AdminUser Operation using saga
*/
// Delete AdminUser API
function deleteAdminUserApi(id) {
  return axios.request({
    method: 'delete',
    url: URLS.ADMIN_USER_ADD+'/'+id,
  });
}

// Saga function that handles the side effect when the deleteAdminUserActionWatcher is triggered
export function* deleteAdminUserActionEffect(deleteAdminUserAction) {
  let { id, resolve, reject } = deleteAdminUserAction;
  try {
    yield put(loadUI());
    let { data } = yield call(deleteAdminUserApi, id);
    yield put(clearUIError());
    yield put(clearError());
    //yield put(push('/AdminUser/list'));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to DELETE_Admin_USER_WATCHER action
export function* deleteAdminUserActionWatcher() {
  yield takeLatest(actions.DELETE_ADMIN_USER_WATCHER, deleteAdminUserActionEffect);
}

/**
* Check duplicate resellers users email for Operation using saga
*/
function checkAdminUsersDuplicateEmailApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.ADMIN_USER_EMAIL+id+URLS.CHECK,
  });
}

export function* checkAdminUsersDuplicateEmailActionEffect(AdminsUsersEmail) {
  let { id, resolve, reject } = AdminsUsersEmail;
  try {
    yield put(loadUI());
    let { data } = yield call(checkAdminUsersDuplicateEmailApi, id);
    yield put(clearUIError());
    
    // yield put(push('reseller/user/list'));
    // showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    
    if(e.status == 'EMAIL_ALREADY_EXISTS'){
      if (reject) resolve();  
    }else{
      if (reject) reject(e);
    }
  }
}

export function* checkAdminUserEmailWatcher() {
  yield takeLatest(actions.CHECK_ADMIN_USER_EMAIL_WATCHER, checkAdminUsersDuplicateEmailActionEffect);
}

/**
* Check duplicate resellers users email for Edit Operation using saga
*/
function checkAdminUsersDuplicateEmailInEditApi(email, userId) {
  return axios.request({
    method: 'get',
    url: URLS.ADMIN_USER_EMAIL+email+URLS.CHECK_USER+userId,
  });
}

export function* checkAdminUsersDuplicateEmailInEditActionEffect(AdminsUsersEmail) {
  let { email, userId, resolve, reject } = AdminsUsersEmail;
  try {
    yield put(loadUI());
    let { data } = yield call(checkAdminUsersDuplicateEmailInEditApi, email, userId);
    yield put(clearUIError());
    
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    
    if(e.status == 'EMAIL_ALREADY_EXISTS'){
      if (reject) resolve();  
    }else{
      if (reject) reject(e);
    }
  }
}

export function* checkAdminUserEmailInEditWatcher() {
  yield takeLatest(actions.CHECK_ADMIN_USER_EMAIL_IN_EDIT_WATCHER, checkAdminUsersDuplicateEmailInEditActionEffect);
}

/**
 * Reset User Password Operation using saga
 */
// Reset User Password API
function resetAdminUserPwdApi(id, params) {
    return axios.request({
      method: 'put',
      url: URLS.API_URL_FOR_ADMIN_RESET_PASSWORD_URL+ id,
      data: params
    });
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* resetAdminUserPwdActionEffect(resetAdminUserPwdAction) {
  let { id, payload, resolve, reject } = resetAdminUserPwdAction;

  try {
    yield put(loadUI());
    let { data } = yield call(resetAdminUserPwdApi, id, payload);
    yield put(clearUIError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* resetAdminUserPwdActionWatcher() {
  yield takeLatest(actions.RESET_ADMIN_USER_PWD_WATCHER, resetAdminUserPwdActionEffect);
}