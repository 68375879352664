import * as actions from '../types';

// Worker triggering actionCreators
export function manageAccountStatementWatcher(payload, id, resolve,reject) {
  return { type: actions.MANAGE_ACCOUNT_STATEMENT_WATCHER, payload: payload, id: id, resolve, reject};
}

// Redux state changing actionCreators
export function fillAccountStatementDataTable(payload) {
  return { type: actions.FILL_ACCOUNT_STATEMENT_DATATABLE, payload: payload };
}
export function setError(error) {
  return { type: actions.SET_ACCOUNT_DEPOSIT_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_ACCOUNT_DEPOSIT_ERRORS, payload: null };
}