import React from 'react';
import ReactFormInputValidation from "react-form-input-validation";
import SupplierStockForm from "./SupplierStockForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  getSupplierStockWatcher, 
  getAddSupplierStockWatcher, 
  addSupplierStockWatcher, 
  updateSupplierStockWatcher, 
  checkSupplierStockPartNumberWatcher, 
  checkSupplierStockPartNumberInEditWatcher } from '../../redux/actionCreators/supplierStockActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings } from '../../utils/locales';

class SupplierStock extends React.Component<any, any>{
  form: any;
  partNumber: any;
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        id: "",
        make: "",
        part_number: "",
        description: "",
        weight: "",
        available_qty: "",
        unit_price: "",
        profit_percentage:"",
        supplier: "",
        supplierList: [],
        selectedSupplier: [],
        attrName: {
          supplier: translationStrings.add_edit_supplier_stock_field_supplier,
          make: translationStrings.common_lbl_brand,
          part_number: translationStrings.common_lbl_part_number,
          description: translationStrings.common_lbl_description,
          weight: translationStrings.common_lbl_weight,
          available_qty: translationStrings.common_lbl_available_qty,
          unit_price: translationStrings.common_lbl_unit_price,
          profit_percentage: translationStrings.common_lbl_default_profit_percentage
        },
        errors: {}
      },
      isLoaded: false,
      profitPercentage: "",
      defaultLoadingMessage: translationStrings.default_loading_msg
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
      supplier: "required",
      make: "required|max:255",
      part_number: "required|max:255",
      description: "required|max:255",
      weight: "required|numeric",
      available_qty: "required|numeric",
      unit_price: "required|numeric",
      profit_percentage: "required|numeric"
    });
    this.form.onformsubmit = (fields: any) => {
      if (!this.state.fields.id) {
        const userData = {
          user_id: fields.supplier,
          brand: fields.make,
          part_number: fields.part_number,
          description: fields.description,
          weight: fields.weight,
          available_qty: fields.available_qty,
          unit_price: fields.unit_price,
          profit_percentage: fields.profit_percentage
        };
        props.addSupplierStockWatcher(userData);
      } else {
        const userData = {
          user_id: fields.supplier,
          brand: fields.make,
          part_number: fields.part_number,
          description: fields.description,
          weight: fields.weight,
          available_qty: fields.available_qty,
          unit_price: fields.unit_price,
          profit_percentage: fields.profit_percentage
        };
        props.updateSupplierStockWatcher(this.state.fields.id, userData);
      }
    }

    this.form.handleChangeEvent = (e) => {
      const target : any = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({ fields: { ...this.state.fields, [name]: value} });

      if(name=='profit_percentage'){
        this.setState({profitPercentage: value});
      }
    }
  }

  validatePartNumber=(e)=>{
    if(!e.target.value){
        this.setState(prevState => {
          let fields = Object.assign({}, prevState.errors);
          if(this.state.errors){
            this.state.errors.part_number = translationStrings.required_part_number_validation_msg;
          }
          return this.state ;
        });
    }
    if(e.target.name=='part_number' && !this.props.match.params.id && e.target.value){
        var response = new Promise((resolve, reject) => {
           this.props.checkSupplierStockPartNumberWatcher(e.target.value, this.state.fields.supplier, this.state.fields.make, resolve, reject);
        }).catch(e => {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        });
        response.then(() => {
          if (this.props.supplierStock.error && this.props.supplierStock.error.status==constants.PART_NUMBER_ALREADY_EXISTS) {
            this.setState(prevState => {
              let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
              fields.part_number = '';        // update the name property, assign a new value                 
              return { fields };        // return new object fields object
            });
          }
        });
    }else if(e.target.name=='part_number' && this.props.match.params.id && e.target.value){
        var response = new Promise((resolve, reject) => {
           this.props.checkSupplierStockPartNumberInEditWatcher(e.target.value, this.props.match.params.id, this.state.fields.supplier, this.state.fields.make, resolve, reject);
        }).catch(e => {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        });
        response.then(() => {
          if (this.props.supplierStock.error && this.props.supplierStock.error.status==constants.PART_NUMBER_ALREADY_EXISTS) {
            this.setState(prevState => {
              let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
              fields.part_number = '';        // update the name property, assign a new value                 
              return { fields };        // return new object fields object
            });
          }
        });
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      document.title = translationStrings.edit_supplier_stock_page_title;
      var response = new Promise((resolve, reject) => {
        this.props.getSupplierStockWatcher(this.props.match.params.id, resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        if (this.props.supplierStock.record) {
          let { data } = this.props.supplierStock.record;
          this.state.fields.id = data.id;
          this.state.fields.make = checkStringNullValue(data.brand);
          this.state.fields.part_number = checkStringNullValue(data.part_number);
          this.state.fields.description = checkStringNullValue(data.description);
          this.state.fields.weight = checkStringNullValue(data.weight);
          this.state.fields.available_qty = checkStringNullValue(data.available_qty);
          this.state.fields.unit_price = checkStringNullValue(data.unit_price);
          this.state.fields.profit_percentage = checkStringNullValue(data.profit_percentage);
          this.setState({ profitPercentage: checkStringNullValue(data.profit_percentage) });
          let selectedSupplierArr = [];
          let selectedIds = [];
          if(chkForUndefinedNullBlank(data.supplierList)){
            this.state.fields.supplierList = data.supplierList;
            let supplierObj={};
            supplierObj['label'] = data.name;
            supplierObj['value'] = data.user_id;
            selectedIds.push(data.user_id);
            selectedSupplierArr.push(supplierObj);
          }
          this.state.fields.selectedSupplier = selectedSupplierArr;
          this.state.fields.supplier = selectedIds;
          this.setState({ isLoaded: true });
        } else {
          this.setState({ isLoaded: false });
          this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
        }
      });
    } else {
      document.title = translationStrings.add_supplier_stock_page_title;
      //this.setState({isLoaded: true});
      var response = new Promise((resolve, reject) => {
        this.props.getAddSupplierStockWatcher(resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        this.setState({ isLoaded: true });
        if(this.props.supplierStock.addData){
          let {data} = this.props.supplierStock.addData;
          
          if(chkForUndefinedNullBlank(data.supplierStock.supplierList)){
            this.state.fields.supplierList = data.supplierStock.supplierList;
          }
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
    }
  }

  handleMakeBlur = (e) => {
      if(this.state.fields.part_number===''){
          this.setState(prevState => {
            let fields = Object.assign({}, prevState.errors);
            if(this.state.errors){
              this.state.errors.part_number = translationStrings.required_part_number_validation_msg;
            }
            return this.state ;
          });
      }
      if(!this.props.match.params.id && this.state.fields.part_number!==''){
          var response = new Promise((resolve, reject) => {
             this.props.checkSupplierStockPartNumberWatcher(this.state.fields.part_number, this.state.fields.supplier, this.state.fields.make, resolve, reject);
          }).catch(e => {
            this.setState({isLoaded: false});
            this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
          });
          response.then(() => {
            if (this.props.supplierStock.error && this.props.supplierStock.error.status==constants.PART_NUMBER_ALREADY_EXISTS) {
              this.setState(prevState => {
                let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
                fields.part_number = '';        // update the name property, assign a new value                 
                return { fields };        // return new object fields object
              });
            }
          });
      }else if(this.props.match.params.id && this.state.fields.part_number!==''){
          var response = new Promise((resolve, reject) => {
             this.props.checkSupplierStockPartNumberInEditWatcher(this.state.fields.part_number, this.props.match.params.id, this.state.fields.supplier, this.state.fields.make, resolve, reject);
          }).catch(e => {
            this.setState({isLoaded: false});
            this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
          });
          response.then(() => {
            if (this.props.supplierStock.error && this.props.supplierStock.error.status==constants.PART_NUMBER_ALREADY_EXISTS) {
              this.setState(prevState => {
                let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
                fields.part_number = '';        // update the name property, assign a new value                 
                return { fields };        // return new object fields object
              });
            }
          });
      }
  }

  handleSelectChange = (values) => {
      this.state.fields.selectedSupplier = values;
      let selectedIds = [];
      let profitPercentage = [];
      if(values && values.length>0){
          values.forEach(function(v){
              selectedIds.push(v.value);
              profitPercentage.push(v.profit_percentage);
          });
      }
      this.state.fields.supplier = selectedIds;
      this.state.fields.profit_percentage = profitPercentage[0];

      if(this.state.fields.part_number===''){
          this.setState(prevState => {
            let fields = Object.assign({}, prevState.errors);
            if(this.state.errors){
              this.state.errors.part_number = translationStrings.required_part_number_validation_msg;
            }
            return this.state ;
          });
      }
      if(!this.props.match.params.id && this.state.fields.part_number!==''){
          var response = new Promise((resolve, reject) => {
             this.props.checkSupplierStockPartNumberWatcher(this.state.fields.part_number, this.state.fields.supplier, this.state.fields.make, resolve, reject);
          }).catch(e => {
            this.setState({isLoaded: false});
            this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
          });
          response.then(() => {
            if (this.props.supplierStock.error && this.props.supplierStock.error.status==constants.PART_NUMBER_ALREADY_EXISTS) {
              this.setState(prevState => {
                let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
                fields.part_number = '';        // update the name property, assign a new value                 
                return { fields };        // return new object fields object
              });
            }
          });
      }else if(this.props.match.params.id && this.state.fields.part_number!==''){
          var response = new Promise((resolve, reject) => {
             this.props.checkSupplierStockPartNumberInEditWatcher(this.state.fields.part_number, this.props.match.params.id, this.state.fields.supplier, this.state.fields.make, resolve, reject);
          }).catch(e => {
            this.setState({isLoaded: false});
            this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
          });
          response.then(() => {
            if (this.props.supplierStock.error && this.props.supplierStock.error.status==constants.PART_NUMBER_ALREADY_EXISTS) {
              this.setState(prevState => {
                let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
                fields.part_number = '';        // update the name property, assign a new value                 
                return { fields };        // return new object fields object
              });
            }
          });
      }

      this.setState({profitPercentage: profitPercentage[0]});
  }

  handleCancelClick = () => {
    history.goBack();
  }

  render() {
    return (
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
                {this.state && this.state.isLoaded &&
                  <SupplierStockForm {...this.state}
                    formHandler={this.form}
                    profitPercentage={this.state.profitPercentage}
                    handleCancelClick={this.handleCancelClick}
                    handleSelectChange={this.handleSelectChange}
                    validatePartNumber={this.validatePartNumber}
                    handleMakeBlur={this.handleMakeBlur}
                  />
                }
                {this.state && !this.state.isLoaded &&
                  <div className="text-center">{this.state.defaultLoadingMessage}</div>
                }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  supplierStock: state.supplierStock,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getSupplierStockWatcher,
    getAddSupplierStockWatcher,
    addSupplierStockWatcher,
    updateSupplierStockWatcher,
    checkSupplierStockPartNumberWatcher,
    checkSupplierStockPartNumberInEditWatcher
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SupplierStock)