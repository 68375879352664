import React from 'react';
import ReactFormInputValidation from "react-form-input-validation";
import OrderForm from "./OrderForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  getOrderWatcher, 
  getAddOrderWatcher, 
  addOrderWatcher, 
  updateOrderWatcher} from '../../redux/actionCreators/orderActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings } from '../../utils/locales';

class Order extends React.Component<any, any>{
  form: any;
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        id: "",
        invoice_no: "",
        status: "",
        total_amount: "",
        tax_amount: "",
        final_amount: "",
        orderDetails: "",
        customerDtls: "",
        date_time: "",
        attrName: {
          invoice_no: translationStrings.add_edit_supplier_stock_field_supplier,
          status: translationStrings.common_lbl_order_status,
          total_amount: translationStrings.common_lbl_total_amount,
          tax_amount: translationStrings.common_lbl_tax_amount,
          final_amount: translationStrings.common_lbl_final_amount
        },
        errors: {}
      },
      orderList: [],
      isLoaded: false,
      profitPercentage: "",
      defaultLoadingMessage: translationStrings.default_loading_msg
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
      status: "required",
      total_amount: "required|max:255"
    });
    this.form.onformsubmit = (fields: any) => {
      if (!this.state.fields.id) {
        const userData = {
          invoice_no: fields.invoice_no,
          status: fields.status,
          total_amount: fields.total_amount,
          tax_amount: fields.tax_amount,
          final_amount: fields.final_amount,
          orderDetails: this.state.orderList
        };
        props.addOrderWatcher(userData);
      } else {
        const userData = {
          invoice_no: fields.invoice_no,
          status: fields.status,
          total_amount: fields.total_amount,
          tax_amount: fields.tax_amount,
          final_amount: fields.final_amount,
          orderDetails: this.state.orderList
        };
        props.updateOrderWatcher(this.state.fields.id, userData);
      }
    }

    this.form.handleChangeEvent = (e) => {
      const target : any = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({ fields: { ...this.state.fields, [name]: value} });

      if(name=='profit_percentage'){
        this.setState({profitPercentage: value});
      }
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      document.title = translationStrings.edit_order_page_title;
      var response = new Promise((resolve, reject) => {
        this.props.getOrderWatcher(this.props.match.params.id, resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        if (this.props.order.record) {
          let { data } = this.props.order.record;
          this.state.fields.id = data.id;
          this.state.fields.invoice_no = checkStringNullValue(data.invoice_no);
          this.state.fields.status = checkStringNullValue(data.status);
          this.state.fields.total_amount = checkStringNullValue(data.total_amount.toFixed(2));
          this.state.fields.tax_amount = checkStringNullValue(data.tax_amount.toFixed(2));
          this.state.fields.final_amount = checkStringNullValue(data.final_amount.toFixed(2));
          this.state.fields.date_time = checkStringNullValue(data.order_date);
          this.state.fields.customerDtls = data.customer_dtls;
          if(chkForUndefinedNullBlank(data.order_dtls)){
            let orderDetails = [];
            data.order_dtls.map(function(value,key){
              let orderObj = {};
              orderObj['id'] = value.id;
              orderObj['supplier_id'] = value.supplier_id;
              orderObj['part_number'] = value.part_number;
              orderObj['make'] = value.make;
              orderObj['description'] = value.description;
              orderObj['weight'] = value.weight.toFixed(3);
              orderObj['final_unit_price'] = value.final_unit_price.toFixed(2);
              orderObj['order_qty'] = value.order_qty;
              orderObj['cancelled_qty'] = value.cancelled_qty ? value.cancelled_qty: 0;
              let available_qty = 0;
              if(value.available_qty){
                available_qty = value.available_qty;
              } else {
                available_qty = orderObj['order_qty'] - orderObj['cancelled_qty'];
              }
              orderObj['available_qty'] = available_qty;
              orderObj['total_price'] = value.total_price.toFixed(2);
              orderObj['supplier_code_name'] = value.supplier_dtls.supplier_code_name;
              orderDetails.push(orderObj);
            });
            this.setState({ orderList: orderDetails});
          }
          this.setState({ isLoaded: true });
        } else {
          this.setState({ isLoaded: false });
          this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
        }
      });
    } else {
      document.title = translationStrings.add_order_page_title;
      //this.setState({isLoaded: true});
      var response = new Promise((resolve, reject) => {
        this.props.getAddOrderWatcher(resolve, reject);
      }).catch(e => {
        this.setState({ isLoaded: false });
        this.setState({ defaultLoadingMessage: translationStrings.common_no_data_found_msg });
      });
      response.then(() => {
        this.setState({ isLoaded: true });
        /*if(this.props.order.addData){
          let {data} = this.props.order.addData;
          
          if(chkForUndefinedNullBlank(data.order.supplierList)){
            this.state.fields.supplierList = data.order.supplierList;
          }
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }*/
      });
    }
  }

  handleSelectChange = (values) => {
      this.state.fields.selectedSupplier = values;
      let selectedIds = [];
      let profitPercentage = [];
      if(values && values.length>0){
          values.forEach(function(v){
              selectedIds.push(v.value);
              profitPercentage.push(v.profit_percentage);
          });
      }
      this.state.fields.supplier = selectedIds;
      this.state.fields.profit_percentage = profitPercentage[0];
      this.setState({profitPercentage: profitPercentage[0]});
  }

  handleChange = (e, index, order_qty) => {
    const { name, value } = e.target;
    const list = [...this.state.orderList];
    if(name=='cancelled_qty'){
      if(value > order_qty){
        list[index]['cancelled_qty'] = order_qty;
        list[index]['available_qty'] = order_qty - list[index]['cancelled_qty'];
      } else {
        list[index]['cancelled_qty'] = value;
        list[index]['available_qty'] = order_qty - value;
      }  
    } else {
      list[index][name] = value;
    }
    let countSinglePrice = parseFloat(list[index]['final_unit_price'])* parseInt(list[index]['available_qty']);
    list[index]['total_price'] = countSinglePrice.toFixed(2);
    this.setState({ orderList: list});
    let total_amount = 0;
    let tax_amount = 0;
    let final_amount = 0;
    list.map(function(value,key){
      total_amount += parseFloat(value.total_price);
    });
    tax_amount = total_amount * (constants.TAX_PERCENTAGE/100);
    final_amount = total_amount + tax_amount;
    this.state.fields.total_amount = total_amount.toFixed(2);
    this.state.fields.tax_amount = tax_amount.toFixed(2);
    this.state.fields.final_amount = final_amount.toFixed(2);
  }

  handleCancelClick = () => {
    history.goBack();
  }

  render() {
    return (
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
                {this.state && this.state.isLoaded &&
                  <OrderForm {...this.state}
                    formHandler={this.form}
                    orderList={this.state.orderList}
                    profitPercentage={this.state.profitPercentage}
                    handleCancelClick={this.handleCancelClick}
                    handleSelectChange={this.handleSelectChange}
                    handleChange={this.handleChange}
                  />
                }
                {this.state && !this.state.isLoaded &&
                  <div className="text-center">{this.state.defaultLoadingMessage}</div>
                }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  order: state.order,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getOrderWatcher,
    getAddOrderWatcher,
    addOrderWatcher,
    updateOrderWatcher
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Order)