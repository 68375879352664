import * as actions from '../types';

// Worker triggering actionCreators
export function manageAdminUsersWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_ADMIN_USERS_WATCHER, payload: payload, resolve, reject};
}
export function changeAdminUserStatusWatcher(payload, status, resolve, reject) {
  return { type: actions.CHANGE_ADMIN_USER_STATUS_WATCHER, payload: payload, status: status, resolve, reject};
}
export function getAdminUserWatcher(id, resolve, reject) {
  return { type: actions.GET_ADMIN_USER_WATCHER, id: id, resolve, reject};
}
export function getAddAdminUserWatcher(resolve, reject) {
  return { type: actions.GET_ADD_ADMIN_USER_WATCHER, resolve, reject}; 
}
export function addAdminUserWatcher(payload, resolve, reject) {
  return { type: actions.ADD_ADMIN_USER_WATCHER, payload: payload, resolve, reject };   
}
export function updateAdminUserWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_ADMIN_USER_WATCHER, id: id, payload: payload, resolve, reject };    
}
export function deleteAdminUserWatcher(id, resolve, reject) {
  return { type: actions.DELETE_ADMIN_USER_WATCHER, id: id, resolve, reject };  
}

export function checkAdminUserEmailWatcher(id, resolve,reject){
  return { type: actions.CHECK_ADMIN_USER_EMAIL_WATCHER, id: id, resolve, reject};
}

export function checkAdminUserEmailInEditWatcher(email, userId, resolve,reject){
  return { type: actions.CHECK_ADMIN_USER_EMAIL_IN_EDIT_WATCHER, email: email, userId: userId,  resolve, reject};
}

export function resetAdminUserPwdWatcher(id, payload, resolve, reject) {
  return { type: actions.RESET_ADMIN_USER_PWD_WATCHER, id: id, payload: payload, resolve, reject };
}

// Redux state changing actionCreators
export function fillAdminUsersDataTable(payload) {
  return { type: actions.FILL_ADMIN_USERS_DATATABLE, payload: payload };
}
export function fillAddAdminUser(payload) {
  return { type: actions.FILL_ADD_ADMIN_USER, payload: payload };
}
export function fillAdminUser(payload) {
  return { type: actions.FILL_ADMIN_USER, payload: payload};
}
export function setError(error) {
  return { type: actions.SET_ADMIN_USERS_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_ADMIN_USERS_ERRORS, payload: null };
}