import * as actions from '../types';

// Worker triggering actionCreators
export function manageSuppliersWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_SUPPLIERS_WATCHER, payload: payload, resolve, reject};
}
export function changeSupplierStatusWatcher(payload, status, resolve, reject) {
  return { type: actions.CHANGE_SUPPLIER_STATUS_WATCHER, payload: payload, status: status, resolve, reject};
}
export function getSupplierWatcher(id, resolve, reject) {
  return { type: actions.GET_SUPPLIER_WATCHER, id: id, resolve, reject};
}
export function getAddSupplierWatcher(resolve, reject) {
  return { type: actions.GET_ADD_SUPPLIER_WATCHER, resolve, reject}; 
}
export function addSupplierWatcher(payload, resolve, reject) {
  return { type: actions.ADD_SUPPLIER_WATCHER, payload: payload, resolve, reject };   
}
export function updateSupplierWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_SUPPLIER_WATCHER, id: id, payload: payload, resolve, reject };    
}
export function deleteSupplierWatcher(id, resolve, reject) {
  return { type: actions.DELETE_SUPPLIER_WATCHER, id: id, resolve, reject };  
}

export function checkSupplierEmailWatcher(id, resolve,reject){
  return { type: actions.CHECK_SUPPLIER_EMAIL_WATCHER, id: id, resolve, reject};
}

export function checkSupplierEmailInEditWatcher(email, userId, resolve,reject){
  return { type: actions.CHECK_SUPPLIER_EMAIL_IN_EDIT_WATCHER, email: email, userId: userId,  resolve, reject};
}

export function resetSupplierPwdWatcher(id, payload, resolve, reject) {
  return { type: actions.RESET_SUPPLIER_PWD_WATCHER, id: id, payload: payload, resolve, reject };
}

// Redux state changing actionCreators
export function fillSuppliersDataTable(payload) {
  return { type: actions.FILL_SUPPLIERS_DATATABLE, payload: payload };
}
export function fillAddSupplier(payload) {
  return { type: actions.FILL_ADD_SUPPLIER, payload: payload };
}
export function fillSupplier(payload) {
  return { type: actions.FILL_SUPPLIER, payload: payload};
}
export function setError(error) {
  return { type: actions.SET_SUPPLIERS_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_SUPPLIERS_ERRORS, payload: null };
}