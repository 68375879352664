import * as actions from '../types';

// UI state changing actionCreators
export function loadUI() {
	return {type: actions.LOADING_UI, payload: null};
}

export function setUIError(error) {
	return {type: actions.SET_ERRORS, payload: error };
}

export function clearUIError() {
	return {type: actions.CLEAR_ERRORS, payload: null};
}