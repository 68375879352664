import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from "react-router-dom"

import { history } from '../../redux/store';
import ChangePasswordForm from "./ChangePasswordForm";
//redux stuff
import { connect } from 'react-redux';
//import { loginUser } from "../../redux/actions/userActions"
import { bindActionCreators } from 'redux';
import { changePwdWatcher } from '../../redux/actionCreators/authActions';
import ReactFormInputValidation from "react-form-input-validation";
import { translationStrings} from '../../utils/locales';

import { printVal} from '../../utils/utils';

import * as constants from '../../constants/constants';

class ChangePassword extends React.Component<any,any>{
    form: any;
    constructor(props) {
        super(props);
        this.state = {
            fields:{
                oldPwd: "",
                password: "",
                password_confirmation: "",
                attrName:{
                    oldPwd: translationStrings.common_lbl_old_password,
                    password: translationStrings.common_lbl_new_password,
                    password_confirmation: translationStrings.common_lbl_confirm_new_password
                },
                errors: {}
            },
            oldPasswordType: 'password',
            newPasswordType: 'password',
            confirmPasswordType: 'password',

        };
        this.form = new ReactFormInputValidation(this);
        ReactFormInputValidation.useLang(localStorage.localeI18nCode);
        this.form.useRules({
            oldPwd: "required|min:6|max:20",
            password: "required|min:6|max:20",
            password_confirmation: "required|min:6|max:20|matchPassword:password"
        });

        let formFields: any;

        ReactFormInputValidation.register('matchPassword', function(value, requirement, attribute){
            return value === formFields[requirement];
        }, translationStrings.common_lbl_password_match_error_msg);

        this.form.handleChangeEvent = (e) => {
            const target : any = e.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            this.setState({ fields: { ...this.state.fields, [name]: value} });
            formFields = this.state.fields;
            formFields[name] = value;
        }


        this.form.onformsubmit = (fields) => {
            const userData = {
                oldPwd: fields.oldPwd,
                password: fields.password,
                confirm_password: fields.password_confirmation
            };
            var response = new Promise((resolve, reject) => {
                let id = localStorage.id;
                props.changePwdWatcher(userData, id, resolve, reject);
                this.setState({
                    fields:{
                        oldPwd: "",
                        password: "",
                        password_confirmation: "",
                        attrName:{
                            oldPwd: "Old Password",
                            password: "New Password",
                            password_confirmation: "Confirm New Password"
                        },
                        errors: {}
                    }
                });
            }).catch(e => {
                this.setState({
                    fields:{
                        oldPwd: "",
                        password: "",
                        password_confirmation: "",
                        attrName:{
                            oldPwd: "Old Password",
                            password: "New Password",
                            password_confirmation: "Confirm New Password"
                        },
                        errors: {}
                    }
                });
            });
        }

        this.form.handleOldPasswordIconClick = (e) => {
            e.preventDefault();
            if(this.state.oldPasswordType===constants.PASSWORD){
                this.setState({oldPasswordType : constants.TEXT});
            }else if(this.state.oldPasswordType===constants.TEXT){
                this.setState({oldPasswordType : constants.PASSWORD});
            }
        }
        this.form.handleNewPasswordIconClick = (e) => {
            e.preventDefault();
            
            if(this.state.newPasswordType===constants.PASSWORD){
                this.setState({newPasswordType : constants.TEXT});
            }else if(this.state.newPasswordType===constants.TEXT){
                this.setState({newPasswordType : constants.PASSWORD});
            }
        }
        this.form.handleConfirmPasswordIconClick = (e) => {
            e.preventDefault();
            
            if(this.state.confirmPasswordType===constants.PASSWORD){
                this.setState({confirmPasswordType : constants.TEXT});
            }else if(this.state.confirmPasswordType===constants.TEXT){
                this.setState({confirmPasswordType : constants.PASSWORD});
            }
        }

        document.title = translationStrings.change_password_page_title;
    }

    handleClick = (e: any) => {
        e.preventDefault();
        history.push(constants.URLS.LOGIN);
    }

    
    render(){
        return(
            <main className="sectionWrapper">
                <section className="container">
                    <div className="sectionHeader">
                        <div className="row">
                          <div className="col-sm-6">
                            <h3>{document.title}</h3>
                          </div>
                          <div className="col-sm-6 text-right">
                          </div>
                        </div>
                    </div>
                    <section className="detailsContainer changePasswordContainer">
                        <div className="row">
                            <div className="col-sm-12">
                                <ChangePasswordForm {...this.state} formHandler={this.form} handleClick={this.handleClick} handleOldPasswordIconClick={this.form.handleOldPasswordIconClick} handleNewPasswordIconClick={this.form.handleNewPasswordIconClick} handleConfirmPasswordIconClick={this.form.handleConfirmPasswordIconClick}/>
                            </div>
                        </div>
                    </section>
                </section>
            </main>
        )
    }
}

//this map the states to our props in this functional component
const mapStateToProps = (state: any) => ({
    user: state.user,
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators({ changePwdWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)