import React, {Component} from 'react';
import {Button} from 'primereact/button';

export interface FormButtonSchema {
	type?: string;
	handleClick?: any;
	class?: string;
	label?: string;
}

export const FormButton = (props: FormButtonSchema) =>  {
  return (
  	<Button type={props.type} label={props.label} className={props.class} onClick={props.handleClick}/>
  );
}