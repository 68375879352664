import React from 'react';
import { Link } from "react-router-dom"
import ReactFormInputValidation from "react-form-input-validation";
import AboutUsForm from "./AboutUsForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAboutUsWatcher, updateAboutUsWatcher } from '../../redux/actionCreators/aboutUsActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings} from '../../utils/locales';

class AboutUs extends React.Component<any,any>{
  form: any;
  constructor(props) {
    super(props);
    this.state = {
        fields:{
            id: "",
            about_content: "",
            our_history: "",
            our_milestones: "",
            our_customer_service: "",
            attrName:{
              about_content: translationStrings.common_lbl_about_content,
              our_history: translationStrings.common_lbl_our_history,
              our_milestones: translationStrings.common_lbl_our_milestones,
              our_customer_service: translationStrings.common_lbl_our_customer_service
            },
            errors: {}
        },
        isLoaded: false,
        defaultLoadingMessage: translationStrings.default_loading_msg
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
        about_content: "required",
        our_history: "required",
        our_milestones: "required",
        our_customer_service: "required"
    });
    this.form.onformsubmit = (fields: any) => {
        const userData = {
          about_content: fields.about_content,
          our_history: fields.our_history,
          our_milestones: fields.our_milestones,
          our_customer_service: fields.our_customer_service
        };
        props.updateAboutUsWatcher(this.state.fields.id,userData);
    }
  }

  componentDidMount() {
      document.title = translationStrings.edit_about_us_page_title;
      var response = new Promise((resolve, reject) => {
         this.props.getAboutUsWatcher(1, resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        if(this.props.aboutUs.record){
          let {data} = this.props.aboutUs.record;
          this.state.fields.id = data.id;
          this.state.fields.about_content = checkStringNullValue(data.about_content);
          this.state.fields.our_history = checkStringNullValue(data.our_history);
          this.state.fields.our_milestones = checkStringNullValue(data.our_milestones);
          this.state.fields.our_customer_service = checkStringNullValue(data.our_customer_service);
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
  }

  handleCancelClick = () => {
    history.goBack();
  }

  handleEditorChange = (e, name) => {
    this.setState({ fields: { ...this.state.fields, [name]: e } });
  }

  render(){
    return(
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
              { this.state && this.state.isLoaded &&
                <AboutUsForm {...this.state} formHandler={this.form} handleCancelClick={this.handleCancelClick} handleEditorChange={this.handleEditorChange}/>
              }
              { this.state  && !this.state.isLoaded &&
                <div className="text-center">{this.state.defaultLoadingMessage}</div>
              }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  aboutUs: state.aboutUs,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators({ getAboutUsWatcher, updateAboutUsWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)