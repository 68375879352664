import * as React from 'react';
import { TextInput, TextArea, PasswordInput, DropDown, FormButton, Label, Anchor, MultipleSelect } from "../common";
import { NavLink } from "react-router-dom"
import { translationStrings } from '../../utils/locales';

export default class CustomerForm extends React.Component<any, any>{
  /*buildSuppliers() {
    let supplierList = [];
    this.props.fields.supplierList.forEach(function(v){
      let supplierObj={};
      supplierObj['label'] = v.name.trim();
      supplierObj['value'] = v.id;
      supplierObj['profit_percentage'] = v.default_profit_percentage;
      supplierList.push(supplierObj);
    });
    return supplierList; 
  }*/

  // buildRoles() {
  //     var arr = [];
  //     var i = 0;
  //     this.props.fields.roles.forEach(function(v){
  //         arr.push(<option key={i} value={v.id}>{v.name}</option>)
  //         i++;
  //     });

  //     return arr; 
  // }

  // buildCountries() {
  //     var arr = [];
  //     var i = 0;
  //     this.props.fields.countries.forEach(function(v){
  //         arr.push(<option key={i} value={v.id}>{v.name}</option>)
  //         i++;
  //     });

  //     return arr; 
  // }

  // buildLocales() {
  //     var arr = [];
  //     var i = 0;
  //     this.props.fields.locales.forEach(function(v){
  //         arr.push(<option key={i} value={v.id}>{v.name}</option>)
  //         i++;
  //     });

  //     return arr; 
  // }

  render() {
    const {
      fields,
      formHandler,
      handleCancelClick,
      validateEmail,
      handleNewPasswordIconClick,
      handleConfirmPasswordIconClick, handleSelectChange, handleChange, handleAddClick, handleRemoveClick, arrayElem } = this.props;
    return (
      <form onSubmit={formHandler.handleSubmit}>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="first_name" label={translationStrings.common_lbl_first_name} require="inline" />
            <TextInput
              class="form-control"
              name="first_name"
              type="text"
              placeholder={translationStrings.common_lbl_first_name}
              handleBlur={formHandler.handleBlurEvent}
              handleChange={formHandler.handleChangeEvent}
              value={fields.first_name}
              attrName={fields.attrName.first_name}
            />
            <label className="error">
              {formHandler.errors.first_name ? formHandler.errors.first_name : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="last_name" label={translationStrings.common_lbl_last_name} require="inline" />
            <TextInput 
            class="form-control" 
            name="last_name" 
            type="text" 
            placeholder={translationStrings.common_lbl_last_name} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.last_name} 
            attrName={fields.attrName.last_name} 
            />
            <label className="error">
              {formHandler.errors.last_name ? formHandler.errors.last_name : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="email" label={translationStrings.common_lbl_email} require="inline" />
            <TextInput 
            class="form-control" 
            name="email" 
            type="text" 
            placeholder={translationStrings.common_lbl_email} 
            handleBlur={validateEmail} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.email} 
            attrName={fields.attrName.email} 
            />
            {!fields.email &&
              <label className="error">
                {formHandler.errors.email ? formHandler.errors.email : ""}
              </label>
            }
          </div>
          <div className="form-group col-sm-6">
            <Label forName="mobile_number" label={translationStrings.common_lbl_contact_number} require="inline" />
            <TextInput 
            class="form-control" 
            name="mobile_number" 
            type="text" 
            placeholder={translationStrings.common_lbl_contact_number} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.mobile_number} 
            attrName={fields.attrName.mobile_number} />
            <label className="error">
              {formHandler.errors.mobile_number ? formHandler.errors.mobile_number : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="company_name" label={translationStrings.common_lbl_company_name} />
            <TextInput 
            class="form-control" 
            name="company_name" 
            type="text" 
            placeholder={translationStrings.common_lbl_company_name} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.company_name} 
            attrName={fields.attrName.company_name} />
            <label className="error">
              {formHandler.errors.company_name ? formHandler.errors.company_name : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="company_tax_registration_number" label={translationStrings.common_lbl_company_tax_reg_number} />
            <TextInput 
            class="form-control" 
            name="company_tax_registration_number" 
            type="text" 
            placeholder={translationStrings.common_lbl_company_tax_reg_number} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.company_tax_registration_number} 
            attrName={fields.attrName.company_tax_registration_number} />
            <label className="error">
              {formHandler.errors.company_tax_registration_number ? formHandler.errors.company_tax_registration_number : ""}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <Label forName="office_phone_number" label={translationStrings.common_lbl_office_contact_number} />
            <TextInput 
            class="form-control" 
            name="office_phone_number" 
            type="text" 
            placeholder={translationStrings.common_lbl_office_contact_number} 
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.office_phone_number} 
            attrName={fields.attrName.office_phone_number} 
            />
            <label className="error">
              {formHandler.errors.office_phone_number ? formHandler.errors.office_phone_number : ""}
            </label>
          </div>
          <div className="form-group col-sm-6">
            <Label forName="address" label={translationStrings.common_lbl_address} />
            <TextArea 
            class="form-control" 
            name="address" 
            placeholder={translationStrings.common_lbl_address}
            handleBlur={formHandler.handleBlurEvent} 
            handleChange={formHandler.handleChangeEvent} 
            value={fields.address} 
            attrName={fields.attrName.address}
            />
            <label className="error">
              {formHandler.errors.address ? formHandler.errors.address : ""}
            </label>
          </div>
        </div>
        {!fields.id &&
          <div className="row">
            <div className="form-group col-sm-6">
              <Label forName="password" label={translationStrings.common_lbl_password} require="inline" />
              <TextInput 
              class="form-control" 
              name="password" 
              type={this.props.newPasswordType} 
              placeholder={translationStrings.common_lbl_password} 
              handleChange={formHandler.handleChangeEvent} 
              handleBlur={formHandler.handleBlurEvent} 
              value={fields.password} 
              attrName={fields.attrName.password} 
              />
              <Anchor 
              class="eye-icon" 
              label={(this.props.newPasswordType == 'password') ? <i className="fa fa-eye icon-theme" /> : <i className="fa fa-eye-slash icon-theme" />} 
              handleClick={(e) => formHandler.handleNewPasswordIconClick(e)} 
              title={(this.props.newPasswordType == 'password') ? translationStrings.common_lbl_show : translationStrings.common_lbl_hide} />
              <label className="error">
                {formHandler.errors.password ? formHandler.errors.password : ""}
              </label>
            </div>
            <div className="form-group col-sm-6">
              <Label forName="password_confirmation" label={translationStrings.common_lbl_confirm_password} require="inline" />
              <TextInput 
              class="form-control" 
              name="password_confirmation" 
              type={this.props.confirmPasswordType} 
              placeholder={translationStrings.common_lbl_confirm_password} 
              handleChange={formHandler.handleChangeEvent} 
              handleBlur={formHandler.handleBlurEvent} 
              value={fields.password_confirmation} 
              attrName={fields.attrName.password_confirmation} 
              />
              <Anchor 
              class="eye-icon" 
              label={(this.props.confirmPasswordType == 'password') ? <i className="fa fa-eye icon-theme" /> : <i className="fa fa-eye-slash icon-theme" />} 
              handleClick={(e) => formHandler.handleConfirmPasswordIconClick(e)} 
              title={(this.props.confirmPasswordType == 'password') ? translationStrings.common_lbl_show : translationStrings.common_lbl_hide} 
              />
              <label className="error">
                {formHandler.errors.password_confirmation ? formHandler.errors.password_confirmation : ""}
              </label>
            </div>
          </div>
        }
        <div className="row">
          <div className="form-group col-sm-12">
            <h4>Discount Setup:</h4>
          </div>
        </div>
        <div className="multiple-division">
        {arrayElem.map((x, i) => {
          return(
            <div className="row" key={i}>
              <div className="form-group col-sm-6">
                <Label forName="supplier" label={translationStrings.add_edit_supplier_stock_field_supplier} require="inline"/>
                {/*<MultipleSelect isMultiple={false} name="supplier[]" options={this.buildSuppliers()} handleChange={handleSelectChange} placeHolder={translationStrings.lbl_select} attrName={fields.attrName.supplier} value={fields.selectedSupplier} />*/}
                <select name="supplier" onChange={e => handleChange(e,i)} data-attribute-name={fields.attrName.supplier} defaultValue={x.supplier} style={{'width':'100%'}}>
                  <option value="">{translationStrings.lbl_select}</option>
                  {
                    this.props.fields.supplierList.map(function(value,key){
                        return <option key={key} value={value.id}>{value.name.trim()}</option>;
                    })
                  }
                </select>
                <label className="error">
                  {formHandler.errors.supplier ? formHandler.errors.supplier : ""}
                </label>
              </div>
              <div className="form-group col-sm-4">
                <Label forName="discount_percentage" label={translationStrings.common_lbl_default_discount_percentage} />
                <TextInput 
                class="form-control" 
                name="discount_percentage" 
                type="text" 
                placeholder={translationStrings.common_lbl_default_discount_percentage} 
                handleBlur={formHandler.handleBlurEvent} 
                handleChange={e => handleChange(e,i)} 
                value={x.discount_percentage} 
                attrName={fields.attrName.discount_percentage} 
                />
                <label className="error">
                  {formHandler.errors.discount_percentage ? formHandler.errors.discount_percentage : ""}
                </label>
              </div>
              <div className="form-group col-sm-2">
                <button type="button" onClick={() => handleRemoveClick(i)} style={{'marginTop':'30px'}}>{'Remove'}</button>
              </div>
            </div>
          );
        })}
        </div>
        <div className="row">
          <div className="form-group col-sm-12">
            <button type="button" onClick={handleAddClick}>{'Add New'}</button>
          </div>
        </div>
        <div className="form-group text-right">
          <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme" />
          <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick} />
        </div>
      </form>
    )
  }
}