import * as actions from '../types';

// Worker triggering actionCreators
export function manageLatestNewsWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_LATEST_NEWS_WATCHER, payload: payload, resolve, reject};
}
export function changeLatestNewsStatusWatcher(payload, status, resolve, reject) {
  return { type: actions.CHANGE_LATEST_NEWS_STATUS_WATCHER, payload: payload, status: status, resolve, reject};
}
export function getLatestNewsWatcher(id, resolve, reject) {
  return { type: actions.GET_LATEST_NEWS_WATCHER, id: id, resolve, reject};
}
export function getAddLatestNewsWatcher(resolve, reject) {
  return { type: actions.GET_ADD_LATEST_NEWS_WATCHER, resolve, reject}; 
}
export function addLatestNewsWatcher(payload, resolve, reject) {
  return { type: actions.ADD_LATEST_NEWS_WATCHER, payload: payload, resolve, reject };   
}
export function updateLatestNewsWatcher(id, payload, resolve, reject) {
  return { type: actions.UPDATE_LATEST_NEWS_WATCHER, id: id, payload: payload, resolve, reject };    
}
export function deleteLatestNewsWatcher(id, resolve, reject) {
  return { type: actions.DELETE_LATEST_NEWS_WATCHER, id: id, resolve, reject };  
}

// Redux state changing actionCreators
export function fillLatestNewsDataTable(payload) {
  return { type: actions.FILL_LATEST_NEWS_DATATABLE, payload: payload };
}
export function fillAddLatestNews(payload) {
  return { type: actions.FILL_ADD_LATEST_NEWS, payload: payload };
}
export function fillLatestNews(payload) {
  return { type: actions.FILL_LATEST_NEWS, payload: payload};
}
export function setError(error) {
  return { type: actions.SET_LATEST_NEWS_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_LATEST_NEWS_ERRORS, payload: null };
}