import React,{ useEffect } from "react";
import { Provider } from "react-redux";

import Spinner from "./components/common/layout/Spinner";

import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component'

import store from './redux/store';
import AppRouter from "./router/Approuter";

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <ReactNotification />
            <Spinner />
			<AppRouter />
        </Provider>
    )
}

export default App;