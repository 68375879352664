import React from 'react';
import { Link } from "react-router-dom"
import ReactFormInputValidation from "react-form-input-validation";
import AdminUserForm from "./AdminUserForm";
import * as constants from "../../constants/constants";
//redux stuff
import { history } from '../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAdminUserWatcher, getAddAdminUserWatcher, addAdminUserWatcher, updateAdminUserWatcher, checkAdminUserEmailWatcher, checkAdminUserEmailInEditWatcher } from '../../redux/actionCreators/adminUsersActions';
// utils
import { checkStringNullValue, chkForUndefinedNullBlank, printVal } from '../../utils/utils';
import { translationStrings} from '../../utils/locales';

class AdminUser extends React.Component<any,any>{
  form: any;
  constructor(props) {
    super(props);
    this.state = {
        fields:{
            id: "",
            first_name: "",
            last_name: "",
            email: "",
            mobile_number: "",
            company_name: "",
            company_tax_registration_number: "",
            office_phone_number: "",
            address: "",
            password: "",
            password_confirmation: "",
            permissions: {
              "admin_users":
              {
                "label":"Admin Users",
                "checked":{
                  "CREATE":false,
                  "READ":false,
                  "UPDATE":false,
                  "DELETE":false,
                  "CHANGE_STATUS":false,
                  "RESET_PASSWORD":false
                }
              },
              "suppliers":
              {
                "label":"Suppliers",
                "checked":{
                  "CREATE":false,
                  "READ":false,
                  "UPDATE":false,
                  "DELETE":false,
                  "CHANGE_STATUS":false,
                  "RESET_PASSWORD":false
                }
              },
              "customers":
              {
                "label":"Customers",
                "checked":{
                  "CREATE":false,
                  "READ":false,
                  "UPDATE":false,
                  "DELETE":false,
                  "CHANGE_STATUS":false,
                  "RESET_PASSWORD":false,
                  "ACCOUNT_STATEMENT": false
                }
              },
              "supplier_stock":
              {
                "label":"Supplier Stock",
                "checked":{
                  "CREATE":false,
                  "READ":false,
                  "UPDATE":false,
                  "DELETE":false,
                  "CHANGE_STATUS":false,
                  "UPLOAD_FILE":false
                }
              },
              "orders":
              {
                "label":"Orders",
                "checked":{
                  "READ":false,
                  "UPDATE":false,
                  "DELETE":false,
                  "CHANGE_STATUS":false
                }
              },
              "account_deposit":
              {
                "label":"Account Deposit",
                "checked":{
                  "CREATE":false,
                  "READ":false,
                  "UPDATE":false,
                  "DELETE":false,
                  "CHANGE_STATUS":false
                }
              },
              "latest_news":
              {
                "label":"Latest News",
                "checked":{
                  "CREATE":false,
                  "READ":false,
                  "UPDATE":false,
                  "DELETE":false,
                  "CHANGE_STATUS":false
                }
              },
              "about_us":
              {
                "label":"About Us",
                "checked":{
                  "UPDATE":false
                }
              },
              "testimonials":
              {
                "label":"Testimonials",
                "checked":{
                  "CREATE":false,
                  "READ":false,
                  "UPDATE":false,
                  "DELETE":false,
                  "CHANGE_STATUS":false
                }
              },
              "features":
              {
                "label":"Features",
                "checked":{
                  "CREATE":false,
                  "READ":false,
                  "UPDATE":false,
                  "DELETE":false,
                  "CHANGE_STATUS":false
                }
              },
              "home":
              {
                "label":"Home",
                "checked":{
                  "UPDATE":false
                }
              },
            },
            attrName:{
              first_name: translationStrings.common_lbl_first_name,
              last_name: translationStrings.common_lbl_last_name,
              email: translationStrings.common_lbl_email,
              mobile_number: translationStrings.common_lbl_contact_number,
              company_name: translationStrings.common_lbl_company_name,
              company_tax_registration_number: translationStrings.common_lbl_company_tax_reg_number,
              office_phone_number: translationStrings.common_lbl_office_contact_number,
              address: translationStrings.common_lbl_address,
              password: translationStrings.common_lbl_password,
              password_confirmation: translationStrings.common_lbl_confirm_password
            },
            errors: {}
        },
        isLoaded: false,
        defaultLoadingMessage: translationStrings.default_loading_msg,
        newPasswordType: 'password',
        confirmPasswordType: 'password'
    };

    this.form = new ReactFormInputValidation(this);
    ReactFormInputValidation.useLang(localStorage.localeI18nCode);
    this.form.useRules({
        first_name: "required|max:255",
        last_name: "required|max:255",
        email: "required|max:255",
        mobile_number: "required|max:20",
        password: "required|min:6|max:20",
        password_confirmation: "required|min:6|max:20"
    });
    this.form.onformsubmit = (fields: any) => {
      if(!this.state.fields.id){
          const userData = {
            first_name: fields.first_name,
            last_name: fields.last_name,
            email: fields.email,
            mobile_number: fields.mobile_number,
            company_name: fields.company_name,
            company_tax_registration_number: fields.company_tax_registration_number,
            office_phone_number: fields.office_phone_number,
            address: fields.address,
            password: fields.password,
            permissions: fields.permissions
          };
          props.addAdminUserWatcher(userData);
      } else {
          const userData = {
            first_name: fields.first_name,
            last_name: fields.last_name,
            email: fields.email,
            mobile_number: fields.mobile_number,
            company_name: fields.company_name,
            company_tax_registration_number: fields.company_tax_registration_number,
            office_phone_number: fields.office_phone_number,
            address: fields.address,
            permissions: fields.permissions
          };
          props.updateAdminUserWatcher(this.state.fields.id,userData);
      }
    }

    this.form.handleNewPasswordIconClick = (e) => {
        e.preventDefault();
      
        if(this.state.newPasswordType===constants.PASSWORD){
            this.setState({newPasswordType : constants.TEXT});
        }else if(this.state.newPasswordType===constants.TEXT){
            this.setState({newPasswordType : constants.PASSWORD});
        }
    }
    this.form.handleConfirmPasswordIconClick = (e) => {
        e.preventDefault();
        
        if(this.state.confirmPasswordType===constants.PASSWORD){
            this.setState({confirmPasswordType : constants.TEXT});
        }else if(this.state.confirmPasswordType===constants.TEXT){
            this.setState({confirmPasswordType : constants.PASSWORD});
        }
    }

    this.form.handleChangeEvent = (e, key, option) => {
      const target: any = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      if(target.type === 'checkbox'){
        const singlePermission = this.state.fields.permissions;
        singlePermission[key]['checked'][option] = e.target.checked;
        this.setState({ fields: { ...this.state.fields, ['permissions']: singlePermission } });
      } else {
        this.setState({ fields: { ...this.state.fields, [name]: value } });
      }

    }
  }

    validateEmail=(e)=>{
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!e.target.value){
            this.setState(prevState => {
              let fields = Object.assign({}, prevState.errors);
              if(this.state.errors){
                this.state.errors.email = translationStrings.required_email_validation_msg;
              }
              return  this.state ;
            });
        }
        if(e.target.name=='email' && !this.props.match.params.id && e.target.value && re.test(String(e.target.value).toLowerCase())){
            var response = new Promise((resolve, reject) => {
               this.props.checkAdminUserEmailWatcher(e.target.value,resolve,reject);
            }).catch(e => {
              this.setState({isLoaded: false});
              this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
            });
            response.then(() => {
              if (this.props.adminUsers.error && this.props.adminUsers.error.status==constants.EMAIL_ALREADY_EXISTS) {
                this.setState(prevState => {
                  let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
                  fields.email = '';        // update the name property, assign a new value                 
                  return { fields };        // return new object fields object
                });
              }
            });
        }else if(e.target.name=='email' && this.props.match.params.id && e.target.value && re.test(String(e.target.value).toLowerCase())){
            var response = new Promise((resolve, reject) => {
               this.props.checkAdminUserEmailInEditWatcher(e.target.value, this.props.match.params.id,resolve, reject);
            }).catch(e => {
              this.setState({isLoaded: false});
              this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
            });
            response.then(() => {
              if (this.props.adminUsers.error && this.props.adminUsers.error.status==constants.EMAIL_ALREADY_EXISTS) {
                this.setState(prevState => {
                  let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
                  fields.email = '';        // update the name property, assign a new value                 
                  return { fields };        // return new object fields object
                });
              }
            });
        }

    }

  componentDidMount() {
    if(this.props.match.params.id){
      document.title = translationStrings.edit_user_page_title;
      var response = new Promise((resolve, reject) => {
         this.props.getAdminUserWatcher(this.props.match.params.id, resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        if(this.props.adminUsers.record){
          let {data} = this.props.adminUsers.record;
          this.state.fields.id = data.id;
          this.state.fields.first_name = checkStringNullValue(data.admin_dtls.first_name);
          this.state.fields.last_name = checkStringNullValue(data.admin_dtls.last_name);
          this.state.fields.email = checkStringNullValue(data.email);
          this.state.fields.mobile_number = checkStringNullValue(data.admin_dtls.mobile_number);
          this.state.fields.company_name = checkStringNullValue(data.admin_dtls.company_name);
          this.state.fields.company_tax_registration_number = checkStringNullValue(data.admin_dtls.company_tax_registration_number);
          this.state.fields.office_phone_number = checkStringNullValue(data.admin_dtls.office_phone_number);
          this.state.fields.address = checkStringNullValue(data.admin_dtls.address);
          if(checkStringNullValue(data.permissions)){
            this.state.fields.permissions = data.permissions;
          }
          this.setState({isLoaded: true});
        } else {
          this.setState({isLoaded: false});
          this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
        }
      });
    } else {
      document.title = translationStrings.add_user_page_title;
      //this.setState({isLoaded: true});
      var response = new Promise((resolve, reject) => {
        this.props.getAddAdminUserWatcher(resolve,reject);
      }).catch(e => {
        this.setState({isLoaded: false});
        this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
      });
      response.then(() => {
        this.setState({isLoaded: true});
      });
    }
  }

  handleCancelClick = () => {
    history.goBack();
  }

  render(){
    return(
      <main className="sectionWrapper">
        <section className="container">
          <div className="sectionHeader">
            <div className="row">
              <div className="col-sm-6">
                <h3>{document.title}</h3>
              </div>
              <div className="col-sm-6 text-right">
              </div>
            </div>
          </div>
          <section className="detailsContainer">
            <div className="row">
              <div className="col-sm-12">
              { this.state && this.state.isLoaded &&
                <AdminUserForm {...this.state} formHandler={this.form} handleCancelClick={this.handleCancelClick} validateEmail={this.validateEmail} handleNewPasswordIconClick={this.form.handleNewPasswordIconClick} handleConfirmPasswordIconClick={this.form.handleConfirmPasswordIconClick}/>
              }
              { this.state  && !this.state.isLoaded &&
                <div className="text-center">{this.state.defaultLoadingMessage}</div>
              }
              </div>
            </div>
          </section>
        </section>
      </main>
    )
  }
}

// this map the states to our props in this component
const mapStateToProps = (state: any) => ({
  adminUsers: state.adminUsers,
  requestUIHandler: state.requestUIHandler
});
// this dispatch the actions to our props in this component
const mapDispatchToProps = dispatch => bindActionCreators({ getAdminUserWatcher, getAddAdminUserWatcher, addAdminUserWatcher, updateAdminUserWatcher, checkAdminUserEmailWatcher,checkAdminUserEmailInEditWatcher }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminUser)