import * as React from 'react';
import { TextInput, TextArea, PasswordInput, FormButton, Label, DropDown, Anchor, MultipleSelect } from "../common";
import { translationStrings} from '../../utils/locales';

export default class AccountDepositForm extends React.Component<any, any>{
  
  buildCustomers() {
    let customerList = [];
    this.props.fields.customerList.forEach(function(v){
      let customerObj={};
      customerObj['label'] = v.name;
      customerObj['value'] = v.id;
      customerList.push(customerObj);
    });
    return customerList; 
  }

  render(){
    const {fields, formHandler, handleCancelClick, handleSelectChange} = this.props;
    return (
      <form onSubmit={formHandler.handleSubmit}>
          <div className="row">
            <div className="form-group col-sm-6">
              <Label forName="user_id" label={translationStrings.common_lbl_select_customer} require="inline"/>
              <MultipleSelect isMultiple={false} name="user_id" options={this.buildCustomers()} handleChange={handleSelectChange} placeHolder={translationStrings.lbl_select} attrName={fields.attrName.user_id} value={fields.selectedCustomer} />
              <label className="error">
                {formHandler.errors.user_id ? formHandler.errors.user_id : ""}
              </label>
            </div>
            <div className="form-group col-sm-6">
              <Label forName="payment_method" label={translationStrings.common_lbl_payment_method} require="inline"/>
              <select className="form-control" name="payment_method" onBlur={formHandler.handleBlurEvent} onChange={formHandler.handleChangeEvent} data-attribute-name={fields.attrName.payment_method} defaultValue={fields.payment_method}>
                <option value="">Select Payment Method</option>
                <option value="CASH">Cash</option>
                <option value="BANK_TRANSFER">Bank Transfer</option>
                <option value="OTHER">Other</option>
              </select>
              <label className="error">
                {formHandler.errors.payment_method ? formHandler.errors.payment_method : ""}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-6">
              <Label forName="payment_date" label={translationStrings.common_lbl_payment_date} require="inline"/>
              <TextInput class="form-control" name="payment_date" type="date" min="new Date()" placeholder={translationStrings.common_lbl_payment_date} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.payment_date} attrName={fields.attrName.payment_date}/>
              <label className="error">
                {formHandler.errors.payment_date ? formHandler.errors.payment_date : ""}
              </label>
            </div>
            <div className="form-group col-sm-6">
              <Label forName="payment_amount" label={translationStrings.common_lbl_payment_amount} require="inline"/>
              <TextInput class="form-control" name="payment_amount" type="number" placeholder={translationStrings.common_lbl_payment_amount} handleBlur={formHandler.handleBlurEvent} handleChange={formHandler.handleChangeEvent} value={fields.payment_amount} attrName={fields.attrName.payment_amount}/>
              <label className="error">
                {formHandler.errors.payment_amount ? formHandler.errors.payment_amount : ""}
              </label>
            </div>
          </div>
          <div className="form-group text-right">
              <FormButton type="submit" label={translationStrings.btn_submit} class="btn btn-theme"/>
              <FormButton type="button" label={translationStrings.btn_cancle} class="btn btn-theme cancelButton" handleClick={handleCancelClick} />
          </div>
      </form>
    )
  }
}