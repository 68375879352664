import { push,goBack } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../types';
import axios from '../../utils/axios';

import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { fillSuppliersDataTable, fillAddSupplier, fillSupplier, setError, clearError } from '../actionCreators/suppliersActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { translationStrings} from '../../utils/locales';
import { URLS } from '../../constants/constants';
/**
* Manage Suppliers Operation using saga
*/

// Manage Suppliers API
function manageSuppliersApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.API_URL_FOR_SUPPLIER_LIST,
    data: params
  });
}

// Saga function that handles the side effect when the manageSuppliersActionEffect is triggered
export function* manageSuppliersActionEffect(manageSuppliersAction) {
  let { payload, resolve, reject } = manageSuppliersAction;
  try {
    yield put(loadUI());
    let data = yield call(manageSuppliersApi, payload);
    yield put(fillSuppliersDataTable(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to MANAGE_SUPPLIERS_WATCHER action
export function* manageSuppliersActionWatcher() {
  yield takeLatest(actions.MANAGE_SUPPLIERS_WATCHER, manageSuppliersActionEffect);
}

/**
* Change Supplier Status Operation using saga
*/
// Change Supplier Status API
function changeSupplierStatusApi(id, status) {
  return axios.request({
    method: 'put',
    url: '/change-status/supplier/'+id
  });
}

// Saga function that handles the side effect when the changeSupplierStatusActionEffect is triggered
export function* changeSupplierStatusActionEffect(changeSupplierStatusAction) {
  let { payload, status, resolve, reject } = changeSupplierStatusAction;
  try {
    yield put(loadUI());
    let { data } = yield call(changeSupplierStatusApi, payload, status);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setError(e));
    yield put(setUIError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to CHANGE_SUPPLIER_STATUS_WATCHER action
export function* changeSupplierStatusActionWatcher() {
  yield takeLatest(actions.CHANGE_SUPPLIER_STATUS_WATCHER, changeSupplierStatusActionEffect);
}

/**
* Fetch Supplier Operation using saga
*/
// Fetch Supplier Basic API
function fetchSupplierApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.SUPPLIER_ADD+'/'+id
  });
}

// Saga function that handles the side effect when the fetchSupplierActionWatcher is triggered
export function* fetchSupplierActionEffect(fetchSupplierAction) {
  let { id, resolve, reject } = fetchSupplierAction;
  try {
    yield put(loadUI());
    let data: any;
    data = yield call(fetchSupplierApi, id);
    yield put(fillSupplier(data.data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_SUPPLIER_WATCHER action
export function* fetchSupplierActionWatcher() {
  yield takeLatest(actions.GET_SUPPLIER_WATCHER, fetchSupplierActionEffect);
}


/**
* Fetch Add Supplier Operation using saga
*/
// Fetch Add Supplier API
function fetchAddSupplierApi() {
  return axios.request({
    method: 'get',
    url: URLS.GET_SUPPLIER_ADD
  });
}

// Saga function that handles the side effect when the fetchAddSupplierActionWatcher is triggered
export function* fetchAddSupplierActionEffect(fetchAddSupplierAction) {
  let { resolve, reject } = fetchAddSupplierAction;
  try {
    yield put(loadUI());
    //let data = yield call(fetchAddSupplierApi);
    //yield put(fillAddSupplier(data));
    yield put(clearUIError());
    yield put(clearError());
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject();
  }
}

// Saga function that is initiated in the beginning to be able to listen to GET_ADD_SUPPLIER_WATCHER action
export function* fetchAddSupplierActionWatcher() {
  yield takeLatest(actions.GET_ADD_SUPPLIER_WATCHER, fetchAddSupplierActionEffect);
}

/**
* Add Supplier Operation using saga
*/
// Add Supplier API
function addSupplierApi(params) {
  return axios.request({
    method: 'post',
    url: URLS.SUPPLIER_ADD,
    data: params
  });
}

// Saga function that handles the side effect when the addSupplierActionWatcher is triggered
export function* addSupplierActionEffect(addSupplierAction) {
  let { payload, resolve, reject } = addSupplierAction;
  try {
    yield put(loadUI());
    let { data } = yield call(addSupplierApi, payload);
    yield put(clearUIError());
    yield put(clearError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve(data);
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to ADD_SUPPLIER_WATCHER action
export function* addSupplierActionWatcher() {
  yield takeLatest(actions.ADD_SUPPLIER_WATCHER, addSupplierActionEffect);
}


/**
* Update Supplier Operation using saga
*/
// Update Supplier API
function updateSupplierApi(id, params) {
  
  // let updateUrl: any;
  // if(editType=='basic'){
    // updateUrl: URLS.Admin_USER_ADD+'/'+id
  // } else {
  //   updateUrl: URLS.Admin_USER_ADD+'/'+id
  // }
  return axios.request({
    method: 'put',
    url: URLS.SUPPLIER_ADD+'/'+id,
    data: params
  });
}

// Saga function that handles the side effect when the updateSupplierActionWatcher is triggered
export function* updateSupplierActionEffect(updateSupplierAction) {
  let { id, payload, resolve, reject } = updateSupplierAction;
  try {
    yield put(loadUI());
    let { data } = yield call(updateSupplierApi, id, payload);
    yield put(clearUIError());
    yield put(clearError());
    yield put(push(URLS.SUPPLIER_LIST));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to UPDATE_SUPPLIER_WATCHER action
export function* updateSupplierActionWatcher() {
  yield takeLatest(actions.UPDATE_SUPPLIER_WATCHER, updateSupplierActionEffect);
}


/**
* Delete Supplier Operation using saga
*/
// Delete Supplier API
function deleteSupplierApi(id) {
  return axios.request({
    method: 'delete',
    url: URLS.SUPPLIER_ADD+'/'+id,
  });
}

// Saga function that handles the side effect when the deleteSupplierActionWatcher is triggered
export function* deleteSupplierActionEffect(deleteSupplierAction) {
  let { id, resolve, reject } = deleteSupplierAction;
  try {
    yield put(loadUI());
    let { data } = yield call(deleteSupplierApi, id);
    yield put(clearUIError());
    yield put(clearError());
    //yield put(push('/Supplier/list'));
    showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to DELETE_SUPPLIER_WATCHER action
export function* deleteSupplierActionWatcher() {
  yield takeLatest(actions.DELETE_SUPPLIER_WATCHER, deleteSupplierActionEffect);
}

/**
* Check duplicate Suppliers users email for Operation using saga
*/
function checkSuppliersDuplicateEmailApi(id) {
  return axios.request({
    method: 'get',
    url: URLS.ADMIN_USER_EMAIL+id+URLS.CHECK,
  });
}

export function* checkSuppliersDuplicateEmailActionEffect(SuppliersEmail) {
  let { id, resolve, reject } = SuppliersEmail;
  try {
    yield put(loadUI());
    let { data } = yield call(checkSuppliersDuplicateEmailApi, id);
    yield put(clearUIError());
    
    // yield put(push('reseller/user/list'));
    // showNotification('success', 'Success', data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    
    if(e.status == 'EMAIL_ALREADY_EXISTS'){
      if (reject) resolve();  
    }else{
      if (reject) reject(e);
    }
  }
}

export function* checkSupplierEmailWatcher() {
  yield takeLatest(actions.CHECK_SUPPLIER_EMAIL_WATCHER, checkSuppliersDuplicateEmailActionEffect);
}

/**
* Check duplicate suppliers users email for Edit Operation using saga
*/
function checkSuppliersDuplicateEmailInEditApi(email, userId) {
  return axios.request({
    method: 'get',
    url: URLS.ADMIN_USER_EMAIL+email+URLS.CHECK_USER+userId,
  });
}

export function* checkSuppliersDuplicateEmailInEditActionEffect(SuppliersEmail) {
  let { email, userId, resolve, reject } = SuppliersEmail;
  try {
    yield put(loadUI());
    let { data } = yield call(checkSuppliersDuplicateEmailInEditApi, email, userId);
    yield put(clearUIError());
    
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    
    if(e.status == 'EMAIL_ALREADY_EXISTS'){
      if (reject) resolve();  
    }else{
      if (reject) reject(e);
    }
  }
}

export function* checkSupplierEmailInEditWatcher() {
  yield takeLatest(actions.CHECK_SUPPLIER_EMAIL_IN_EDIT_WATCHER, checkSuppliersDuplicateEmailInEditActionEffect);
}

/**
 * Reset User Password Operation using saga
 */
// Reset User Password API
function resetSupplierPwdApi(id, params) {
    return axios.request({
      method: 'put',
      url: URLS.API_URL_FOR_ADMIN_RESET_PASSWORD_URL+ id,
      data: params
    });
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* resetSupplierPwdActionEffect(resetSupplierPwdAction) {
  let { id, payload, resolve, reject } = resetSupplierPwdAction;

  try {
    yield put(loadUI());
    let { data } = yield call(resetSupplierPwdApi, id, payload);
    yield put(clearUIError());
    showNotification('success', translationStrings.common_lbl_success, data.message);
    if (resolve) resolve();
  } catch (e) {
    yield put(setUIError(e));
    yield put(setError(e));
    if (reject) reject(e);
  }
}
// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* resetSupplierPwdActionWatcher() {
  yield takeLatest(actions.RESET_SUPPLIER_PWD_WATCHER, resetSupplierPwdActionEffect);
}
